import { Card, CardHeader, Container, Grid, IconButton, Menu, MenuItem, Paper, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Iconify from '../../../components/common/Iconify'
import CustomLabel from '../../../components/common/label/CustomLabel'
import OrderTimeline from '../../../components/common/OrderTimeline'
import OrderPrice from './OrderPrice'
import OrderItemCard from './OrderItemCard'
import RightSide from './RightSide'
import { useParams, useSearchParams } from 'react-router-dom'
import { useRouter } from '../../../routes/hooks/useRouter'
import { horecaApi, useChangeScheduledOrderStatusMutation, useGetOrderDetailsByIdQuery, useGetScheduledOrderDetailsQuery, useUpdateOrderByVendorMutation, useUpdateOrderStatusMutation, useUpdatePaymentModeMutation, useUpdatePaymentStatusMutation } from '../../../redux/api/api'
import { convertDateFormat, formatDate } from '../../../utils/formatNumbers'
import PlaceOrderDialogue from '../place-order/PlaceOrderDialogue'
import { getOrgType } from '../../../utils/org'
import { useDispatch, useSelector } from 'react-redux'
import { getItemsWithQuantity } from '../place-order/PlaceOrder'
import { updateAnItemToOrder } from '../../../redux/slices/orderSlice'
import OrderListTable from '../place-order/OrderListTable'
import { openMessage } from '../../../redux/slices/showMessageSlice'
import OrderStatusDialogue, { FullFilled, ShippingInfo } from './OrderStatusDialogue'
import { getStatusColor } from '../../settings/sections/organization/OrganizationProfile'
import { useFormik } from 'formik'
import { shippedValidation } from '../../../schema/organization/organizationSchema'
import PaymentPage from './PaymentPage'
import PageHeader from '../../../components/common/PageHeader'
import CustomButton from '../../../components/common/CustomButton'
import { base64ToArrayBuffer } from '../../../utils/base64toArray'
import OTPComponent from '../common/OTPComponent'
import OrderDeliveredDialogue from '../../sales-order/OrderDeliveredDialogue'


export const RenderHeader = ({ heading, showEdit = false, onEdit }) => {
    return (
        <Stack padding={'24px 24px 0px'} direction={'row'} justifyContent={'space-between'}>
            <Typography variant='h6'>{heading}</Typography>
            {
                showEdit && <IconButton size='small' onClick={() => onEdit()}>
                    <Iconify icon="fluent:edit-28-filled" />
                </IconButton>
            }
        </Stack>
    )
}


function getStatusMessage(status) {
    const statusMessages = {
        "drafted": "Order time",
        "confirmed": "Your order is confirmed by vendor.",
        "shipped": "Your order has been completed successfully.",
        "delivered": "Your order delivered.",
        "fulfilled": "Your order has been completed successfully.",
        "packed": "Your order is packed.",
        "void": "Your order has been cancelled"
        // Add more status messages as needed
    };

    return statusMessages[status] || "Invalid status";
}

function getMessage(status) {
    const statusMessages = {
        "confirmed": "order confirmed",
        "shipped": "Order shipped",
        "delivered": "Order delivered",
        "fulfilled": "Order fullfilled",
        "packed": "Order packed",
        "void": "Order caneled"
    };

    return statusMessages[status] || "Invalid status";
}



const SheduleButton = ({ selectedOption, anchorEl, handleOptionClick, handleClose, handleClick, status }) => {

    const renderMenuOption = (currentStatus) => {
        switch (currentStatus) {
            case 'confirmed':
                return (
                    <>
                        <MenuItem onClick={() => handleOptionClick('packed')} sx={{ borderRadius: 1, '&:hover': { color: (theme) => theme.palette.primary.main, bgcolor: (theme) => theme.palette.primary.lighter } }}>Packed</MenuItem>
                        <MenuItem onClick={() => handleOptionClick('shipped')} sx={{ borderRadius: 1, '&:hover': { color: (theme) => theme.palette.primary.main, bgcolor: (theme) => theme.palette.primary.lighter } }} >Shipped</MenuItem>
                        <MenuItem onClick={() => handleOptionClick('delivered')} sx={{ borderRadius: 1, '&:hover': { color: (theme) => theme.palette.primary.main, bgcolor: (theme) => theme.palette.primary.lighter } }} >Delivered</MenuItem>
                        <MenuItem onClick={() => handleOptionClick('fulfilled')} sx={{ borderRadius: 1, '&:hover': { color: (theme) => theme.palette.primary.main, bgcolor: (theme) => theme.palette.primary.lighter } }}>Fulfilled</MenuItem>
                    </>
                );
            case 'drafted':
                return (
                    <>
                        <MenuItem onClick={() => handleOptionClick('confirmed')} sx={{ borderRadius: 1, '&:hover': { color: (theme) => theme.palette.primary.main, bgcolor: (theme) => theme.palette.primary.lighter } }}>Confirmed</MenuItem>
                    </>
                );
            case 'packed':
                return (
                    <>
                        <MenuItem onClick={() => handleOptionClick('shipped')} sx={{ borderRadius: 1, '&:hover': { color: (theme) => theme.palette.primary.main, bgcolor: (theme) => theme.palette.primary.lighter } }}>Shipped</MenuItem>
                        <MenuItem onClick={() => handleOptionClick('delivered')} sx={{ borderRadius: 1, '&:hover': { color: (theme) => theme.palette.primary.main, bgcolor: (theme) => theme.palette.primary.lighter } }}>Delivered</MenuItem>
                        <MenuItem onClick={() => handleOptionClick('fulfilled')} sx={{ borderRadius: 1, '&:hover': { color: (theme) => theme.palette.primary.main, bgcolor: (theme) => theme.palette.primary.lighter } }}>Fulfilled</MenuItem>
                    </>
                );
            case 'shipped':
                return (
                    <>
                        <MenuItem onClick={() => handleOptionClick('delivered')} sx={{ borderRadius: 1, '&:hover': { color: (theme) => theme.palette.primary.main, bgcolor: (theme) => theme.palette.primary.lighter } }}>Delivered</MenuItem>
                        <MenuItem onClick={() => handleOptionClick('fulfilled')} sx={{ borderRadius: 1, '&:hover': { color: (theme) => theme.palette.primary.main, bgcolor: (theme) => theme.palette.primary.lighter } }}>Fulfilled</MenuItem>
                    </>
                );
            case 'delivered':
                return (
                    <>
                        <MenuItem onClick={() => handleOptionClick('fulfilled')} sx={{ borderRadius: 1, '&:hover': { color: (theme) => theme.palette.primary.main, bgcolor: (theme) => theme.palette.primary.lighter } }}>Fulfilled</MenuItem>
                    </>
                );
            case 'fulfilled':
                return (
                    <MenuItem onClick={() => handleOptionClick('fulfilled')} sx={{ borderRadius: 1, '&:hover': { color: (theme) => theme.palette.primary.main, bgcolor: (theme) => theme.palette.primary.lighter } }}>Fulfilled</MenuItem>
                );
            default:
                return null;
        }
    };

    return (
        <>
            <CustomButton
                onClick={handleClick}
                endIcon={<Iconify icon="iconamoon:arrow-down-2-duotone" />}
                variant={"outlined"}
                label={selectedOption ? selectedOption : 'Status'}
                width={'120px'}
                sx={{
                    '& .MuiButton-endIcon': {
                        transform: Boolean(anchorEl) && 'rotate(-180deg)',
                        transition: 'transform 0.3s ease-in-out',
                    }
                }}
            />
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                sx={{
                    '& .MuiList-root': {
                        width: "120px",
                        padding: "0.5rem",
                    },
                }}
            >
                {renderMenuOption(status)}
            </Menu>
        </>
    )
}




const OrderDetail = () => {

    const router = useRouter();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    const { orgId, orderId } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const statusType = searchParams.get("type");
    const paymentResponse = searchParams.get("paymentResponse");

    const { data: rawResult } = useGetOrderDetailsByIdQuery({ orderId, scheduled: statusType })


    const [getOrderListById, { data: orderListData }] = horecaApi.endpoints.getOrderListById.useLazyQuery();
    const [generateInvoice] = horecaApi.endpoints.generateInvoice.useLazyQuery();
    const [downloadInvoice] = horecaApi.endpoints.downloadInvoice.useLazyQuery();
    const [updateOrderByVendor] = useUpdateOrderByVendorMutation();
    const [updateOrderStatus] = useUpdateOrderStatusMutation();
    const [updatePaymentMode] = useUpdatePaymentModeMutation();
    const [getPaymentLink, { data: paymentData }] = horecaApi.endpoints.getPaymentLink.useLazyQuery();
    //const [updatePaymentStatus] = horecaApi.endpoints.updatePaymentStatus.useLazyQuery();
    const [updatePaymentStatus] = useUpdatePaymentStatusMutation();

    const orgType = getOrgType(user, orgId)
    const updateOrder = useSelector((state) => state.order?.updateOrder);
    const notificationMsg = useSelector((state) => state.notifications?.latestMsg);
    const [isReview, setIsReview] = useState(false);
    const [open, setOpen] = useState(false);
    const [openDelivery, setOpenDelivery] = useState(false);
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState(null);

    const [orderData, setOrderData] = useState();


    const [fullFilledMessage, setFullFilledMessag] = useState(null);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const [openPayment, setOpenPayment] = useState(false);

    const [thankYou, setThanYou] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const [changeScheduledOrderStatus] = useChangeScheduledOrderStatusMutation();

    useEffect(() => {
        if(notificationMsg) {
            const content = JSON.parse(notificationMsg?.content);
            if(content.type.includes('order') && content?.action?.orderId == orderId) {
                dispatch(horecaApi.util.invalidateTags(["order-updated"]));
            }
        }
    }, [notificationMsg])

    useEffect(() => {
        (
            async () => {
                if (rawResult) {
                    setOrderData(rawResult);
                    const transformedData = rawResult?.items.map(item => ({
                        itemId: item.itemId,
                        quantity: item.quantity,
                        unit: item.unit
                    }));
                    dispatch(updateAnItemToOrder(transformedData))
                }
            }
        )()
    }, [rawResult])


    useEffect(() => {
        (
            async () => {
                isReview && await getOrderListById({ orgId: orderData?.customerId, orderListId: orderData?.orderListId });
            }
        )()
    }, [isReview])

    const goBack = () => {
        orgType === 'vendor' ? router.push(`/org/${orgId}/sales-order`) : router.push(`/org/${orgId}/order-history`)
    }


    const handleOnOrderEdit = () => {
        if (orgType == 'vendor') {
            setIsReview(true);
        } else {
            router.push(`/org/${orgId}/orders/${orderData?.vendorId}/order-list/${orderData?.orderListId}?orderId=${orderData?.orderNo}&status=${orderData?.status}`);
        }
    }

    const handleReviewClose = () => {
        setIsReview(false);
    }


    const formik = useFormik({
        initialValues: {
            type: orderData?.delivery?.type ? orderData?.delivery?.type : '',
            shipBy: orderData?.delivery?.shipBy ? orderData?.delivery?.shipBy : '',
            trackingNo: orderData?.delivery?.trackingNumber ? orderData?.delivery?.trackingNumber : '',
            trackingUrl: orderData?.delivery?.trackingUrl ? orderData?.delivery?.trackingUrl : '',
        },
        validationSchema: shippedValidation,
        isInitialValid: true,
        enableReinitialize: true,
        onSubmit: async (values) => {
            const result = await updateOrderStatus({ orderId, status, payload: { message: values } });
            if (result?.data) {
                dispatch(openMessage({ message: result?.data, messageSeverity: 'success' }));
                onClose();
                setAnchorEl(null);
            } else if (result?.error) {
                dispatch(openMessage({ message: result?.error?.data?.message, messageSeverity: 'error' }));
            } else {
                dispatch(openMessage({ message: 'Not able to update an order status at this moment!', messageSeverity: 'error' }));
            }
        }
    })


    const handleUpdateOrder = async () => {
        const payload = {
            orderListId: orderData?.orderListId,
            items: updateOrder?.items.filter(item => item.quantity > 0),
        };
        const result = await updateOrderByVendor({ orderId, payload });
        if (result?.data) {
            dispatch(openMessage({ message: result?.data?.message ? result?.data?.message : 'Order updated and informed to customer!', messageSeverity: 'success' }));
            setIsReview(false);
        } else if (result?.error) {
            dispatch(openMessage({ message: result?.error?.data?.message, messageSeverity: 'error' }));
        } else {
            dispatch(openMessage({ message: 'Not abe to update an order at this moment!', messageSeverity: 'error' }));
        }
    }

    const handleQuantityChange = (itemId, itemName, itemUnit, newQuantity) => {
        let updatedItems = [];
        if (true) {
            // Quantity is greater than zero, update or add the item to the newOrder state
            updatedItems = updateOrder.items.map(item => {
                if (item.itemId === itemId) {
                    // Update quantity of existing item
                    return { ...item, quantity: newQuantity };
                } else {
                    return item;
                }
            });

            // Check if the item already existed
            const existingItemIndex = updatedItems.findIndex(item => item.itemId === itemId);
            if (existingItemIndex === -1) {
                // Item does not exist, add it to the items array
                updatedItems.push({
                    itemId: itemId,
                    // name: itemName,
                    quantity: newQuantity,
                    unit: itemUnit
                });
            }
        } else {
            // Quantity is zero or negative, remove the item from the newOrder state if it exists
            updatedItems = updateOrder.items.filter(item => item.itemId !== itemId);
        }

        // Dispatch an action to update the newOrder state
        dispatch(updateAnItemToOrder(updatedItems ? updatedItems : []));
    };

    const handleIncrement = (itemId, itemName, itemUnit, newQuantity) => {

        if (!isNaN(newQuantity)) {
            handleQuantityChange(itemId, itemName, itemUnit, newQuantity)
        }
    };

    const handleDecrement = (itemId, itemName, itemUnit, newQuantity) => {

        if (!isNaN(newQuantity)) {
            handleQuantityChange(itemId, itemName, itemUnit, newQuantity)
        }
    };

    const onDelete = (itemId) => {
        const filteredItems = updateOrder?.items.filter((item) => item?.itemId !== itemId);
        dispatch(updateAnItemToOrder(filteredItems))
    }

    const onClose = () => {
        setOpenDelivery(false);
        setOpen(false)
        setMessage('')
        setStatus('')
        formik.resetForm();
        setAnchorEl(null);
    }


    //saving an data basis of status
    const updateStatusOrder = async () => {
        const result = await updateOrderStatus({ orderId, status, payload: { message: status === 'shipped' ? formik?.values : status === 'fullfilled' ? { type: message } : message ? message : getMessage(status) } });
        if (result?.data) {
            dispatch(openMessage({ message: result?.data, messageSeverity: 'success' }));
            onClose();
            setAnchorEl(null);
        } else if (result?.error) {
            dispatch(openMessage({ message: result?.error?.data?.message, messageSeverity: 'error' }));
        } else {
            dispatch(openMessage({ message: 'Not able to update an order status at this moment!', messageSeverity: 'error' }));
        }
    }

    const handleChange = (value) => {
        setMessage(value)
    }

    const handleChange1 = (value) => {
        setFullFilledMessag(value);
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    const handleOptionClick = (status) => {
        setStatus(status);
        if (status == 'delivered') {
            setOpenDelivery(true)
        } else {
            setOpen(true)
        }
    }


    const handleGenerateInvoice = async () => {
        setInvoiceGenerating(true)
        const result = await generateInvoice(orderId);
        if (result?.data) {
            setInvoiceGenerating(false)
            dispatch(openMessage({ message: 'Invoice generated!', messageSeverity: 'success' }));
            setTimeout(() => {
                router.reload();
            }, 2000)
        } else if (result?.error) {
            setInvoiceGenerating(false)
            dispatch(openMessage({ message: result?.error?.data?.message, messageSeverity: 'error' }));
        } else {
            setInvoiceGenerating(false)
            dispatch(openMessage({ message: 'Not able to create and invoice', messageSeverity: 'error' }));
        }
    }

    const handleChangeSheduledOrder = async () => {
        const result = await changeScheduledOrderStatus({ orderId, active: !orderData?.active });
        if (result?.data) {
            dispatch(openMessage({ message: 'Scheduled order status changed!', messageSeverity: 'success' }));
        } else if (result?.error) {
            dispatch(openMessage({ message: result?.error?.data?.message, messageSeverity: 'error' }));
        } else {
            dispatch(openMessage({ message: 'Not able to update scheduled order status', messageSeverity: 'error' }));
        }
    }

    const downloadPDF = (pdfData) => {

        var byteArray = base64ToArrayBuffer(pdfData);
        var a = window.document.createElement("a");
        a.href = window.URL.createObjectURL(
            new Blob([byteArray], { type: "application/octet-stream" })
        );

        a.download = "invoice";

        document.body.appendChild(a);

        a.click();
    };

    const handleDownloadInvoice = async () => {
        const result = await downloadInvoice(orderId);
        if (result?.data) {
            downloadPDF(result?.data?.img);
            dispatch(openMessage({ message: 'Invoice downloaded!', messageSeverity: 'success' }));
        } else if (result?.error) {
            dispatch(openMessage({ message: result?.error?.data?.message, messageSeverity: 'error' }));
        } else {
            dispatch(openMessage({ message: 'Not able to download an invoice', messageSeverity: 'error' }));
        }
    }

    const renderContent = (currentStatus) => {
        switch (currentStatus) {
            case 'shipped':
                return (
                    <>
                        <ShippingInfo formik={formik} />
                    </>
                );
            case 'fulfilled':
                return (
                    <>
                        <FullFilled value={fullFilledMessage} handleChange={handleChange} />
                    </>
                );
            case 'delivered':
                return (
                    <>
                        <TextField
                            label="Message"
                            fullWidth
                            variant="outlined"
                            value={message}
                            onChange={(e) => handleChange(e.target.value)}
                            multiline
                            rows={4}
                        />
                        {/* <OTPComponent/> */}
                    </>
                );
            case 'void':
                return (
                    <></>
                );
            default:
                return (
                    <>
                        <TextField
                            label="Message"
                            fullWidth
                            variant="outlined"
                            value={message}
                            onChange={(e) => handleChange(e.target.value)}
                            multiline
                            rows={4}
                        />
                    </>
                );
        }
    };

    const handelViewInvoice = () => {
        orderData?.invoiceId && router.push(`/org/${orgId}/invoice/${orderData?.invoiceId}`);
    }

    const handleEdit = () => {
        setStatus('shipped');
        setOpen(true);
    }

    const handleCreatePaymentLink = async () => {
        const result = await getPaymentLink(orderId);
        if (result?.data) {
            setOpenPayment(true);
        }
    }





    //call an api and show loader once api is done recall the query an update state and show thank you
    const afterPayClick = async (paymentMode = 'payByGateway') => {
        setIsLoading(true)
        const result = await updatePaymentStatus({ orderId, status: 'success', paymentMode });
        if (result.data || result.isSuccess) {
            setOpenPayment(false);
            setTimeout(() => {
                setIsLoading(false)
                setThanYou(true);
                router.push(`/org/${orgId}/order-history/${orderId}`)
                router.reload();
            }, 1000)

        } else {
            setIsLoading(false)
        }
    }


    const handlePaymentClose = () => {
        //afterPayClick();
        setOpenPayment(false);
    }

    useEffect(() => {
        if (paymentResponse === 'success') {
            setThanYou(true);
            setOpenPayment(true);
        } else if (paymentResponse === 'failed') {

        }
    }, [paymentResponse])
    const changePaymentMode = async (paymentMode) => {
        const result = await updatePaymentMode({ orderId, paymentMode, orgType });
        if (result?.data) {
            dispatch(openMessage({ message: result?.data.message, messageSeverity: 'success' }));
        } else if (result?.error) {
            dispatch(openMessage({ message: result?.error?.data?.message, messageSeverity: 'error' }));
        } else {
            dispatch(openMessage({ message: 'Not able to update payment mode at this moment!', messageSeverity: 'error' }));
        }
    }

    const [invoiceGenerating, setInvoiceGenerating] = useState(false)

    return (
        <Container maxWidth='xl'>
            <PageHeader
                handleBackClick={goBack}
                title={`Order #${orderData?.orderNo}`}
                statusLabel={orderData?.status && <CustomLabel color={getStatusColor(orderData?.status)}>{orderData?.status}</CustomLabel>}
                titleBelowComponent={<Typography variant='body1' color={'text.disabled'} noWrap>
                    {formatDate(orderData?.createdAt)}
                </Typography>}
            />
            {/* <Stack mb={'40px'} direction={'row'} gap={'24px'} justifyContent={'space-between'}> */}
            <Stack direction={'row'} mb={2} justifyContent={'flex-end'} alignItems={'center'} gap={2}>
                {
                    (orgType === 'vendor' && orderData?.status !== 'void' && orderData?.status !== 'scheduled' && orderData?.status !== 'fulfilled') &&
                    <Stack direction={'row'} justifyContent={'flex-end'} alignItems={'center'} gap={'12px'}>
                        <SheduleButton
                            anchorEl={anchorEl}
                            handleClose={handleClose}
                            handleOptionClick={handleOptionClick}
                            handleClick={handleClick}
                            selectedOption={status}
                            status={orderData?.status}
                        />
                    </Stack>
                }
                {
                    (orgType == 'vendor' && orderData?.status !== 'void' && orderData?.status !== 'paymnet_failed' && orderData?.status !== 'paymnet_pending' && !orderData?.invoiceKey) &&
                    <Stack direction={'row'} justifyContent={'flex-end'} alignItems={'center'} gap={'12px'}>
                            <CustomButton
                                onClick={handleGenerateInvoice}
                                endIcon={<Iconify icon="mdi:invoice-text-arrow-right" />}
                                variant='contained'
                                label={'Generate Invoice'}
                                color='primary'
                                loading={invoiceGenerating}
                            />
                    </Stack>
                }
                {
                    (orderData?.invoiceKey && orgType === 'customer') &&
                    <Stack direction={'row'} justifyContent={'flex-end'} alignItems={'center'} gap={'12px'}>
                        <CustomButton
                            onClick={handelViewInvoice}
                            endIcon={<Iconify icon="mdi:eye" />}
                            variant='outlined'
                            label={'View Invoice'}
                            color='success'
                        />
                    </Stack>
                }
                {
                    (orgType === 'customer' && orderData?.status === 'payment_pending' || orderData?.status === 'payment_failed') &&
                    <Stack direction={'row'} justifyContent={'flex-end'} alignItems={'center'} gap={'12px'}>
                        <CustomButton
                            onClick={handleCreatePaymentLink}
                            variant='contained'
                            label={'Pay to Proceed'}
                            color='error'
                        />
                    </Stack>
                }
                {
                    (orderData?.invoiceKey) &&
                    <Stack direction={'row'} justifyContent={'flex-end'} alignItems={'center'} gap={'12px'}>
                        <CustomButton
                            onClick={handleDownloadInvoice}
                            endIcon={<Iconify icon="eva:cloud-download-fill" />}
                            variant='contained'
                            label={'Download Invoice'}
                            color='primary'
                        />
                    </Stack>
                }
                {
                    (orgType == 'customer' && orderData?.status === 'scheduled') &&
                    <Stack direction={'row'} justifyContent={'flex-end'} alignItems={'center'} gap={'12px'}>
                        <CustomButton
                            onClick={handleChangeSheduledOrder}
                            variant='contained'
                            label={orderData?.active ? 'Deactivate' : 'Activate'}
                            color='primary'
                        />
                    </Stack>
                }
                {
                    (['payment_pending', 'confirmed'].includes(orderData?.status)) &&
                    <Stack direction={'row'} justifyContent={'flex-end'} alignItems={'center'} gap={'12px'}>
                        <CustomButton
                            onClick={() => handleOptionClick('void')}
                            variant='contained'
                            label={'Cancel Order'}
                            color='primary'
                        />
                    </Stack>
                }
            </Stack>
            {/* </Stack> */}

            <Grid container spacing={{ xs: 2, sm: 4 }} sx={{ margin: 'calc(4 / -2) calc(4 / -2)' }}>
                <Grid item xs={12} md={8}>
                    {
                        (orderData?.vendorEdited && orgType == 'customer') &&
                        <Stack direction={'row'} justifyContent={'flex-end'} alignItems={'center'} >
                            <Typography fontWeight={600} sx={{ color: (theme) => theme.palette.error.main }} gutterBottom>Your order has been edited by vendor.</Typography>
                        </Stack>
                    }
                    <Stack direction={'column'} spacing={{ xs: 2, sm: 4 }}>
                        <Paper elevation={1}>
                            <Card>
                                <RenderHeader heading={'Details'}
                                    showEdit={
                                        orderData?.orderListId
                                            ?
                                            orgType === "vendor"
                                                ?
                                                orderData?.status === 'confirmed' || orderData?.status === 'packed' ? true : false
                                                :
                                                orderData?.status === 'payment_pending' || orderData?.status === 'confirmed' || orderData?.status === 'scheduled' ? true : false
                                            :
                                            false
                                    }

                                    onEdit={handleOnOrderEdit} />
                                <Stack direction={'column'} paddingLeft={'24px'} paddingRight={'24px'} sx={{ overflowY: "auto", maxHeight: "60vh", "-webkit-overflow-scrolling": "touch", scrollBehavior: "smooth", "&::-webkit-scrollbar": { display: "none" } }}>
                                    {
                                        orderData?.items.map((order, index) => (
                                            <OrderItemCard key={index} order={order} />
                                        ))
                                    }
                                </Stack>
                                <Stack mr={'1.5rem'}>
                                    <OrderPrice priceDetails={orderData?.prices} />
                                </Stack>
                            </Card>
                        </Paper>
                        <Paper elevation={1}>
                            <Grid item xs={12} >
                                <CardHeader title={"Order Timeline"} />
                            </Grid>
                            <Grid container
                                spacing={3}
                                p={3}
                            >
                                <Grid item xs={12} sm={6} md={7}>
                                    <OrderTimeline
                                        title="Order Timeline"
                                        orderHistoryStatus={orderData?.orderHistoryStatus}
                                    />
                                </Grid>
                                {
                                    orderData?.orderTracks?.length > 0 &&
                                    <Grid item xs={12} sm={6} md={5} alignItems={"center"}>
                                        <Stack
                                            sx={{
                                                padding: '1rem', border: '1px dashed rgba(145, 158, 171, 0.16)', borderRadius: '16px', gap: '0.5rem',
                                                // minWidth: '260px',
                                                width: "100%"
                                            }}
                                        >
                                            {
                                                orderData?.orderTracks?.map((data, index) => (
                                                    <Stack direction={'column'} gap={'4px'}>
                                                        <Typography variant='body2' sx={{ color: 'text.disabled' }}>
                                                            {getStatusMessage(data?.status)}
                                                        </Typography>
                                                        {formatDate(data?.createdAt)}
                                                    </Stack>
                                                ))
                                            }
                                        </Stack>
                                    </Grid>
                                }
                            </Grid>

                        </Paper>
                    </Stack>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Paper elevation={1}>
                        <Card>
                            <RightSide customerInfo={orderData?.customerInfo} changePaymentMode={changePaymentMode} delivery={orderData?.delivery} shipping={orderData?.shipping} paymentInfo={orderData?.paymentInfo} orgType={orgType} status={orderData?.status} vendorInfo={orderData?.vendorInfo} onEdit={handleEdit} />
                            {/* {
                                (orderData?.status === 'confirmed') &&
                                <Stack direction={'row'} px={3} pb={3}>
                                    <CustomButton
                                        onClick={() => { setOpen(true); setStatus('void') }}
                                        variant={"outlined"}
                                        label={"Cancel"}
                                        width={'100%'}
                                        color='error'
                                    />
                                </Stack>
                            } */}
                        </Card>
                    </Paper>
                </Grid>
            </Grid>
            <PlaceOrderDialogue
                handelCreateOrder={handleUpdateOrder}
                open={isReview}
                content={<OrderListTable items={getItemsWithQuantity(orderListData?.items, updateOrder?.items)}
                    handleQuantityChange={handleQuantityChange}
                    action={isReview}
                    onDelete={onDelete}
                    title={"Items"}
                    prevOrders={updateOrder?.items}
                    handleIncrement={handleIncrement}
                    handleDecrement={handleDecrement}
                    maxHeight="40vh"
                />}
                onClose={handleReviewClose}
                title='Update and Inform'
            />
            <OrderStatusDialogue
                open={open}
                handelClose={onClose}
                content={renderContent(status)}
                title={status == 'void' ? 'Are you sure you want to cancel this order' : `Are you sure you want to ${status}?`}
                message={message}
                handleChangeMessage={handleChange}
                handelConfirm={status === 'shipped' ? formik.handleSubmit : updateStatusOrder}
            />
            {openDelivery && <OrderDeliveredDialogue
                open={openDelivery}
                handelClose={onClose}
                orderId={orderId}
            />}
            <PaymentPage
                open={openPayment}
                handleClose={handlePaymentClose}
                isThankYou={thankYou}
                afterPayClick={afterPayClick}
                isLoading={isLoading}
                price={orderData?.prices?.total}
                paymentData={paymentData}
            />
        </Container>
    )
}

export default OrderDetail