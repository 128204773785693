import React, { useEffect, useRef } from "react";
import { Avatar, Box, Grid, Paper, Stack, Typography } from "@mui/material";
import { styled } from "@mui/system";
import ProductCard from "./ProductCard";
import { horecaApi, useGetCartItemsQuery, useGetSuitableVendorListMutation, useRemoveAllItemFromCartMutation } from "../../redux/api/api";
import { SelecteCategoriesComp } from "../../layouts/common/Searchbar";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GetImageComponent from "../../components/common/GetImageComponent";
import CustomRating from "../../components/common/CustomRating";
import CustomSearchbar from "../../components/common/CustomSearchbar";
import { stringAvatar } from "../../utils/defaultIcon";
import ItemsLoading from "../../components/common/ItemsLoading";
import NoVendorMessage from "../../components/common/NoVendorMessage";
import NoData from "../order-list/NoData";
import useResponsiveStyles from "../../hooks/useResponsiveStyles";
import { openMessage } from "../../redux/slices/showMessageSlice";
import ClearCartDialgoue from "./ClearCartDialgoue";
import useQueryParams from "../../routes/hooks/useQueryParams";
import PageHeader from "../../components/common/PageHeader";
import VendorLogoImageComponent from "../../components/common/VendorLogoImageComponent";
import { useLocation } from "react-router-dom";
import ExploreProductMobile from "./ExploreProductMobile";
import ExploreProductNew from "./ExploreProductNew";

const CategoryItem = styled(Box)(({ theme, isMobile }) => ({
  display: isMobile ? "" : "flex",
  alignItems: "center",
  padding: "12px 0",
  paddingRight: 0,
  marginRight: 0,
  paddingLeft: "16px",
  color: "#707070",
  fontSize: "16px",
  cursor: "pointer",
  "&:hover": {
    color: theme.background,
  },
  "&.active": {
    color: theme.background,
    borderRight: "3px solid #e23744",
    height: "60px",
  },
}));

const CategoryImageContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexShrink: 0,
  backgroundColor: "#e0e0e0",
  borderRadius: "50%",
  width: "50px",
  height: "50px",
}));

const ActiveFilterBlock = styled(CategoryImageContainer)({
  backgroundColor: "#f4f4f4",
});

const InactiveFilterBlock = styled(CategoryImageContainer)({
  backgroundColor: "transparent",
});

const newCategory = {
  id: null,
  category_name: "All",
  category_image: "https://assets.hyperpure.com/data/images/assets/sub_category_image_all_v2.png",
};

const ExplorePorducts = () => {
  const params = useQueryParams();
  const location = useLocation();
  const { isMobile } = useResponsiveStyles();
  const dispatch = useDispatch();
  const { item } = location.state || {};
  const orgId = useSelector((state) => state.app.preference.selectedOrgId);
  const [getVendorItems, { data: vendorItems, isLoading: itemLoading }] = horecaApi.endpoints.getVendorItems.useLazyQuery();
  const [vendorItemData, setVendorItemData] = useState([]);
  const [getSuitableVendorList, { isLoading: vendorLoading }] = useGetSuitableVendorListMutation();
  const pincode = useSelector((state) => state.app.preference.selectedPinCode?.pincode);
  const [vendors, setVendors] = useState([]);

  const { data: allCartItemsData, isLoading: categoryLoading } = useGetCartItemsQuery(orgId);

  const [cartData, setCartData] = useState([]);
  const rowRefs = useRef([]);
  useEffect(() => {
    setCartData(allCartItemsData);
  }, [allCartItemsData]);

  const [searchText, setSearchText] = useState("");
  const selectedValue = useSelector((state) => state.app.preference.selectedPinCode);
  const [selectedVendorId, setSelectedVendorId] = useState(vendors[0]?.vendorDetails?.id ? vendors[0]?.vendorDetails?.id : null);
  const [activeParentCategory, setActiveParentCategory] = useState(null);
  const [activeCategory, setActiveCategory] = React.useState(null);
  const [removeAllItemFromCart, { isLoading: removeAllItemFromCartLoading }] = useRemoveAllItemFromCartMutation();
  useEffect(() => {
    if (item && vendorItems) {
      let tempArr = [...vendorItems];
      const _ind = tempArr.findIndex((_obj) => _obj.id == item.id);
      const [item1] = tempArr.splice(_ind, 1);
      setVendorItemData([item1, ...tempArr]);
    } else if (vendorItems) {
      setVendorItemData(vendorItems);
    }
  }, [item, vendorItems])

  const fetchVendors = async () => {
    const result = await getSuitableVendorList({
      orgId,
      payload: {
        vendorOnly: "true",
        categories: [],
        subCategories: [],
        vendorOrgId: params?.vendorId ?? null,
        itemId: null,
        singleVendor: params?.vendorId ? true : false,
        pincode,
      },
    }, true);
    if (result?.data) {
      if (allCartItemsData?.length > 0) {
        setVendors(result?.data);
        setSelectedVendorId(params.vendorId ? params.vendorId : allCartItemsData[0]?.vendorId);
        setActiveParentCategory(null);
        setCate([newCategory]);
        setActiveCategory(null);
      } else {
        setVendors(result?.data);
        setSelectedVendorId(result?.data[0]?.vendorDetails?.id);
        setActiveParentCategory(null);
        setCate([newCategory]);
        setActiveCategory(null);
      }
    }
  };

  useEffect(() => {
    fetchVendors();
  }, [selectedValue, params.vendorId]);

  useEffect(() => {
    (async () => {
      if (selectedVendorId && !isMobile) {
        // await getVendorItems({
        //   customerOrgId: orgId,
        //   vendorOrgId: selectedVendorId,
        //   categoryId: activeParentCategory,
        //   subCategoryId: activeCategory,
        //   searchText,
        // }, true);
      }
    })();
  }, [orgId, selectedVendorId, activeCategory, activeParentCategory, searchText, selectedValue]);

  const handelSelectCategory = (category) => {
    setActiveParentCategory(category?.id);
    setActiveCategory(null);
  };

  const [cate, setCate] = useState([]);

  const handelRemoveCategory = (catId) => {
    setActiveParentCategory(null);
    const updatedCategories = [newCategory];
    setCate(updatedCategories);
  };

  const categories1 = vendors
    ?.find((vendor) => vendor?.vendorDetails?.id == selectedVendorId)
    ?.categoryDetails?.find((cat) => cat.id == activeParentCategory)?.subCategories;

  const [openConfirm, setOpenConfirm] = useState(false);

  const onConfirm = async () => {
    const result = await removeAllItemFromCart(orgId);
    if (result?.data) {
      setOpenConfirm(false);
      dispatch(openMessage({ message: "Cart cleared!", messageSeverity: "success" }));
    } else if (result?.error) {
      dispatch(openMessage({ message: result?.error?.data?.message, messageSeverity: "error" }));
    } else {
      dispatch(openMessage({ message: "Not able to clear the cart", messageSeverity: "error" }));
    }
  };

  const onCancel = () => {
    setOpenConfirm(false);
  };

  useEffect(() => {
    if (categories1) {
      // Create a new array with the new category at the start
      const updatedCategories = [newCategory, ...categories1];
      // Update the state with the new array
      setCate(updatedCategories);
    }
  }, [categories1, selectedValue]);

  const handleSelecteVendor = (vendorId) => {
    if (allCartItemsData?.length > 0) {
      setOpenConfirm(true);
    } else {
      const vendor = vendors?.find((vendor) => vendor?.vendorDetails?.id == vendorId);
      setActiveParentCategory(vendor?.categoryDetails[0]?.id);
      setActiveCategory(null);
      setSelectedVendorId(vendorId);
    }
  };

  useEffect(() => {
    if (searchText) {
      setActiveCategory(null);
      setActiveParentCategory(null);
    }
  }, [searchText]);

  if(isMobile) {
    return (
      vendors?.length > 0 && <ExploreProductMobile cartData={cartData} avgRating={vendors[0]?.avgRating} vendorDetails={vendors[0]?.vendorDetails} categoryDetails={vendors[0]?.categoryDetails}/>
    )
  } else {
    return (
      params.vendorId && vendors?.length > 0 && <ExploreProductNew cartData={cartData} avgRating={vendors[0]?.avgRating} vendorDetails={vendors[0]?.vendorDetails} categoryDetails={vendors[0]?.categoryDetails}/>
    )
  }

  return (
    // <SectionContainer sx={{ marginTop: 0, paddingLeft: '0px', paddingRight: '0px'}}>
    <Box sx={{ paddingX: 2 }}>
      {vendors?.length > 0 ? (
        <>
          <Stack
            direction="row"
            spacing={3}
            rowGap={2}
            mb={2}
            mt={"1rem"}
            sx={{
              overflowX: "auto",
              display: "flex",
              "-webkit-overflow-scrolling": "touch",
              scrollBehavior: "smooth",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            {params.vendorId ? (
              <Box width={"100%"}>
                <PageHeader title={"Explore Products"} linkArray={[{ title: "Dashboard", path: `/` }, { title: "Settings" }]} variant1="text" />
                <Paper sx={{ padding: { xs: "1rem", sm: "2.5rem" } }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <VendorLogoImageComponent name={vendors[0]?.vendorDetails?.orgName} />
                      <Typography variant="h6" sx={{ mt: "1rem" }}>
                        Vendor Details
                      </Typography>
                      <Grid container>
                        {[
                          { label: "Name", value: vendors[0]?.vendorDetails?.org_name },
                          { label: "Rating", value: vendors[0]?.vendorDetails?.vendorRatings?.orgRatings },
                        ].map((vendorDet, index) => (
                          <>
                            <Grid item xs={6} container>
                              <Typography sx={{ color: "text.disabled" }} variant="subtitle2" gutterBottom>
                                {vendorDet.label}
                              </Typography>
                            </Grid>
                            <Grid item xs={6} padding={"auto"}>
                              {vendorDet?.label === "Rating" ? (
                                <CustomRating value={parseFloat(vendors[0]?.vendorDetails?.vendorRating?.ratings)} fontSize={"small"} />
                              ) : (
                                <Typography variant="body2" gutterBottom>
                                  {vendorDet.value}
                                </Typography>
                              )}
                            </Grid>
                          </>
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Box>
            ) : (
              vendors?.map((vendor, index) => {
                let nameColor = stringAvatar({ name: vendor?.vendorDetails?.org_name, color: "rgb" })?.item;

                return (
                  <Stack
                    key={vendor?.vendorDetails?.id}
                    direction={"row"}
                    alignItems={"center"}
                    gap={0.3}
                    mb={1}
                    onClick={() => handleSelecteVendor(vendor?.vendorDetails?.id)}
                    sx={{
                      // minWidth: "200px",
                      backgroundColor: (theme) =>
                        selectedVendorId == vendor?.vendorDetails?.id &&
                        (theme.palette.mode == "light" ? "primary.lighter" : theme.palette.background.paper),

                      borderBottom: (theme) => vendor?.vendorDetails?.id === selectedVendorId && `2px solid ${theme.palette.primary.main}`,
                      borderRigth: (theme) => `2px dashed rgba(244, 246, 248, 0.2)`,
                      // ":hover": {
                      //   transition: "0.3s all",
                      //   // boxShadow: (theme) => theme.shadows[1],
                      //   transform: "scale(1.05) all",
                      // },
                      // borderBottom: (theme) =>
                      //   selectedVendorId === vendor?.vendorDetails?.id
                      //     ? `2px solid ${theme.palette.mode === "light" ? theme.palette.primary.lighter : theme.palette.background.paper}`
                      //     : "3px solid transparent",
                      borderRadius: "8px",
                      "&:hover": {
                        transition: "0.3s all",
                        cursor: "pointer",
                      },
                    }}
                  >
                    <Stack
                      sx={{
                        // height: "4rem",
                        // width: "4rem",
                        padding: "0.5rem",
                        borderRadius: "50%",
                        justifyContent: "center",
                        alignItems: "center",
                        // mb: "2rem",
                        mt: "1rem",
                        gap: 1,
                      }}
                    >
                      {vendor?.vendorDetails?.org_logo_key ? (
                        <Avatar alt={vendor?.vendorDetails?.org_name} sx={{ backgroundColor: "#fff" }}>
                          <GetImageComponent s3Key={vendor?.vendorDetails?.org_logo_key} />
                        </Avatar>
                      ) : (
                        <Avatar
                          sx={{
                            bgcolor: (theme) =>
                              theme.palette.mode === "light" ? `rgba(${nameColor?.sx?.bgcolor},0.5)` : `rgba(${nameColor?.sx?.bgcolor},0.7)`,
                            color: (theme) => (theme.palette.mode === "light" ? `rgba(${nameColor?.sx?.bgcolor},1)` : `rgba(255,255,255,0.9)`),
                          }}
                          children={nameColor?.children}
                        />
                      )}
                    </Stack>
                    {selectedVendorId === vendor?.vendorDetails?.id && (
                      <Stack direction={"column"} gap={0.2} paddingRight={1.5}>
                        <Typography
                          variant="h6"
                          sx={{
                            cursor: "pointer",
                            textTransform: "capitalize",
                            transition: "color 0.3s ease-in-out", // Define the transition property
                            color: selectedVendorId == vendor?.vendorDetails?.id && "primary.main",
                            "&:hover": {
                              color: "primary.main",
                              transform: "scale(1.05)",
                            },
                          }}
                        >
                          {vendor?.vendorDetails?.org_name}
                        </Typography>
                        <CustomRating fontSize={15} value={vendor?.vendorDetails?.vendorRating?.ratings} />
                      </Stack>
                    )}
                  </Stack>
                );
              })
            )}
          </Stack>
          <CustomSearchbar
            placeholder={"Search items..."}
            text={searchText}
            handleTextChange={(e) => setSearchText(e.currentTarget.value)}
            handleTextClear={(e) => setSearchText("")}
            sx={{ width: { xs: "100%", sm: "70%" }, mb: "1rem" }}
          />
          <Stack
            direction="row"
            gap={2}
            mb={4}
            sx={{
              // flexWrap: "wrap",
              overflowX: isMobile && "auto",
              flexWrap: !isMobile && "wrap",
              display: "flex",
              "-webkit-overflow-scrolling": isMobile && "touch", // Optional: Smooth scrolling for iOS devices
              scrollBehavior: isMobile && "smooth", // Optional: Smooth scrolling behavior
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            {!searchText &&
              vendors
                ?.find((vendor) => vendor?.vendorDetails?.id == selectedVendorId)
                ?.categoryDetails?.map((category, index) => (
                  <SelecteCategoriesComp
                    cat={category}
                    handleClick1={handelSelectCategory}
                    handleDelete={handelRemoveCategory}
                    index={`${index}category`}
                    key={index}
                    selectedCategories={[activeParentCategory]}
                  />
                ))}
          </Stack>
          <Stack direction={"row"} gap={"1rem"} width={"100%"}>
            {!searchText && (
              <Box
                width={"23%"}
                maxHeight={"90vh"}
                overflow={"auto"}
                height={"max-content"}
                boxShadow={"0 1px 4px rgba(28,28,28,.06)"}
                borderRadius={"24px"}
                position={"relative"}
                sx={{
                  overflowY: "scroll",
                  scrollbarWidth: "none",
                  border: (theme) => `1px solid ${theme.palette.mode === "dark" ? "rgba(145, 158, 171, 0.16)" : "1px solid #ebebeb"}`,
                }}
              >
                {categoryLoading ? (
                  <ItemsLoading />
                ) : (
                  cate?.map((category) => (
                    <CategoryItem
                      key={category.id}
                      className={activeCategory === category.id ? "active" : ""}
                      onClick={() => setActiveCategory(category.id)}
                      isMobile={isMobile}
                    >
                      <Box className={activeCategory === category.id ? ActiveFilterBlock : InactiveFilterBlock}>
                        {category?.category_name == "All" ? (
                          <Avatar
                            src={category?.category_image}
                            sx={{ bgcolor: (theme) => theme.palette.primary.lighter, color: (theme) => theme.palette.primary.main }}
                          ></Avatar>
                        ) : (
                          <Avatar sx={{ bgcolor: (theme) => theme.palette.primary.lighter, color: (theme) => theme.palette.primary.main }}>
                            <GetImageComponent noSticker={category?.category_image} style={{ width: "50px", height: "50px" }} />
                          </Avatar>
                        )}
                      </Box>
                      <Typography
                        variant="body1"
                        fontSize={isMobile ? "8px" : "12px"}
                        sx={{ marginLeft: !isMobile && 2, fontWeight: activeCategory === category.id ? 600 : 400, textAlign: "center" }}
                      >
                        {category.category_name}
                      </Typography>
                    </CategoryItem>
                  ))
                )}
              </Box>
            )}
            <Stack
              width={searchText ? "100%" : "77%"}
              overflow={"hidden"}
              sx={{
                overflowY: "auto",
                maxHeight: "200vh",
                minHeight: "30vh",
                "-webkit-overflow-scrolling": "touch",
                scrollBehavior: "smooth",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
              }}
            >
              {!itemLoading ? (
                <Grid container={12} rowSpacing={2} >
                  {vendorItemData?.length > 0 ? (
                    vendorItemData?.map((item, index) => (
                      <Grid item xs={searchText ? 12 : 12} sm={6} md={4} lg={searchText ? 3 : 4} sx={{ paddingX: 1.5 }}>
                        <Box ref={el => (rowRefs.current[index] = el)}>
                          <ProductCard data={item} key={item.id} customerId={orgId} vendorId={selectedVendorId} allCartItemsData={cartData} />
                        </Box>
                      </Grid>
                    ))
                  ) : (
                    <Grid item xs={12}>
                      <NoData query={searchText ? searchText : "Inventory Items"} />
                    </Grid>
                  )}
                </Grid>
              ) : (
                <ItemsLoading />
              )}
            </Stack>
          </Stack>
        </>
      ) : vendorLoading ? (
        <ItemsLoading />
      ) : (
        <NoVendorMessage />
      )}
      <ClearCartDialgoue
        open={openConfirm}
        handleOnCancel={onCancel}
        handleOnConfirmed={onConfirm}
        handleOnConfirmedLoading={removeAllItemFromCartLoading}
      />
    </Box>
  );
};

export default ExplorePorducts;
