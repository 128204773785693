import React, { useEffect, useRef, useState } from 'react';
import { Grid, Box, Typography, Stepper, StepConnector, Step, StepButton, StepLabel, Divider } from '@mui/material';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { step1, step2, step3, step4, step5 } from '../../assets/steps';
import { SectionContainer } from './Section1';
//import { stepActive, stepPending } from '../../assets/images';
import stepActive from '../../assets/images/step-active.svg'
import stepPending from '../../assets/images/step-pending.svg'
import useResponsiveStyles from '../../hooks/useResponsiveStyles';

const steps = [
  {
    number: 1,
    title: "Create a Business Account - Add Outlets, Add Team, assign roles & outlets",
    image: step1
  },
  {
    number: 2,
    title: "Browse Vendors in your Pincode or Add your Own Vendors",
    image: step2
  },
  {
    number: 3,
    title: "Create Order Lists for easy ordering or Order directly",
    image: step3
  },
  {
    number: 4,
    title: "Pay by Discco - & get upto 90 days credit",
    image: step4
  }
];

const HowItWorks = () => {
  const controls = useAnimation();
  const responsive = useResponsiveStyles();
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true
  });
  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentStep((prevStep) => (prevStep === steps.length - 1 ? 0 : prevStep + 1));
    }, 3000); // Change image every 5 seconds
    return () => clearInterval(interval);
  }, []);
  if (responsive.isMobile) {
    return (
      <Box sx={{ padding: 1, paddingX: 1, display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ backgroundColor: '#fffbe6', padding: 1, borderRadius: 2 }}>
          <Typography variant="h3" align="center" sx={{ mb: 2, fontWeight: 600 }}>
            # How Do I Use It?
          </Typography>
          <Divider />
          <Box sx={{ padding: 1, paddingTop: 2, px: 0.5,gap: 2, display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ py:1 }}>
              <Typography variant='subtitle1' sx={{ textAlign:'center' }}>It’s simple.</Typography>
              <Typography variant='body2' sx={{ color: '#596378', textAlign:'center' }}>Horeca1 allows you to explore, compare & purchase from a wide range of verified F&B Suppliers in your area. It even allows you to add your own vendors. And get a Credit of up to 90 days on every invoice. Yes, you heard it right!</Typography>
            </Box>
            <Typography
              sx={{
                fontSize: 16,
                minHeight: 70,
                fontWeight: 600,
                color: '#007a8f',
                textAlign: 'center'
              }}>
              {currentStep + 1}. {steps[currentStep].title}
            </Typography>
            <Box
              sx={{
                height: 200,
                backgroundImage: `url(${steps[currentStep].image})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                //backgroundPosition: 'center',
                borderRadius: 1,
              }}
            />

          </Box>
        </Box>
      </Box>
    );
  }
  return (
    <Box sx={{ padding: 2, paddingX: 8, display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ backgroundColor: '#fffbe6', padding: 2, borderRadius: 2 }}>
        <Typography variant="h3" align="center" sx={{ mb: 2, fontWeight: 600 }}>
          # How Do I Use It?
        </Typography>
        <Divider />
        <Box>
          <Box sx={{ pt: 2, px: 25 }}>
            <Typography variant='subtitle1' textAlign={'center'}>It’s simple.</Typography>
            <Typography variant='body1' textAlign={'center'} sx={{ color: '#596378' }}>Horeca1 allows you to explore, compare & purchase from a wide range of verified F&B Suppliers in your area. It even allows you to add your own vendors. And get a Credit of up to 90 days on every invoice. Yes, you heard it right!</Typography>
          </Box>
          <Box ref={ref} sx={{ padding: 5, pb: 3, borderRadius: 2, display: 'flex', gap: 3, justifyContent: 'space-between', alignItems: 'center' }}>
            <Box>
              <Stepper activeStep={currentStep} nonLinear orientation="vertical" connector={<StepConnector />}
                sx={{
                  "& .MuiStepConnector-line": {
                    minHeight: 30
                  },
                  "& .MuiStepConnector-vertical": {
                    ml: '14px'
                  },
                  "& .MuiStepLabel-vertical": {
                    py: 0
                  },
                  "& .MuiStepLabel-iconContainer": {
                    height: 36,
                    width: 36,
                  },
                  "& .MuiStepLabel-labelContainer": {
                    paddingLeft: 1,
                  },
                }}>
                {steps.map((step, ind) => (
                  <Step key={step.title}>
                    <StepButton
                      icon={
                        <>
                          {currentStep == ind && <img src={stepActive} />}
                          {currentStep != ind && <img src={stepPending} />}
                        </>
                      }
                    >
                      <StepLabel sx={{ paddingx: 3 }}>
                        <Box>
                          <Typography
                            variant='subtitle1'
                            sx={{
                              //fontSize: 16,
                              //fontWeight: currentStep == ind ? 600 : 500,
                              color: currentStep == ind ? '#000' : '#007a8f'
                            }}>
                            {step.title}
                          </Typography>
                        </Box>
                      </StepLabel>
                    </StepButton>
                  </Step>
                ))
                }
              </Stepper>
            </Box>
            <Box sx={{ width: '40%' }}>
              {steps.map((step, index) => (
                <motion.div
                  key={index}
                  initial="hidden"
                  animate={currentStep === index ? 'visible' : 'hidden'}
                  variants={{
                    visible: { opacity: 1 },
                    hidden: { opacity: 0 }
                  }}
                  transition={{ duration: 0.5 }}
                >
                  <Box
                    sx={{
                      height: 300,
                      // backgroundImage: `url(${step.image})`,
                      // backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      backgroundImage: `url(${steps[currentStep].image})`,
                      backgroundSize: 'contain',
                      backgroundRepeat: 'no-repeat',
                      borderRadius: 1,
                      display: currentStep === index ? 'block' : 'none'
                    }}
                  />
                </motion.div>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default HowItWorks;
