import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Button, Typography, IconButton, Box, Paper, TableContainer, TableCell, TableRow, TableHead, Table, TableBody, TextField, FormControl, Radio, FormControlLabel, RadioGroup, Select, InputLabel, MenuItem } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import useResponsiveStyles from '../../../hooks/useResponsiveStyles';
import Iconify from '../../../components/common/Iconify';
import ItemsImageComponent from '../../../components/common/ItemsImageComponent';
import { horecaApi, useCreateOrderListMutation, useUpdateOrderListItemsMutation } from '../../../redux/api/api';
import { useDispatch, useSelector } from 'react-redux';
import useQueryParams from '../../../routes/hooks/useQueryParams';
import { openMessage } from '../../../redux/slices/showMessageSlice';

const ReviewWishListDialogue = ({ wishList, open, onClose, addToWishlist, onListCreated }) => {
  const params = useQueryParams()
  const dispatch = useDispatch();
  const isMobile = useResponsiveStyles();
  const [listVal, setListVal] = useState('new');
  const [listName, setListName] = useState('');
  const pincode = useSelector((state) => state.app.preference.selectedPinCode?.pincode);
  const [existingListName, setExistingListName] = useState('');
  const [vendorOrderList, setvendorOrderList] = useState([]);
  const orderList = useSelector((state) => state.orderList.orderList)
  const orgId = useSelector((state) => state.app.preference.selectedOrgId);
  const [getOrderList] = horecaApi.endpoints.getOrderList.useLazyQuery();
  const [updateOrderListItems] = useUpdateOrderListItemsMutation();
  const [createOrderList] = useCreateOrderListMutation();
  const [getOrderListById, { data: orderListData }] = horecaApi.endpoints.getOrderListById.useLazyQuery();

  useEffect(() => {
    (
      async () => {
        await getOrderList({ orgId, pincode})
      }
    )()
  }, [])
  useEffect(() => {
    if (orderList && orderList?.Total?.totalOrderLists?.length > 0) {
      setvendorOrderList(orderList?.Total?.totalOrderLists.filter((_obj) => _obj.vendorOrgId == params?.vendorId))
    }
  }, [orderList])

  const createList = async () => {
    let data1 = {
      vendorOrgId: params?.vendorId,
      name: listName,
      items: wishList.map((item) => ({ item_id: item.item_id, inventry_item_id: item.id })),
    };
    let result;
    if (listVal == 'new') {
      result = await createOrderList({ orgId, payload: [data1] });
    } else {
      let itemListToSave = orderListData.items.map((item) => ({ item_id: item.item_id, inventry_item_id: item.id }))
      data1 = {
        vendorOrgId: params?.vendorId,
        customerOrgId: orgId,
        items: wishList.map((item) => ({ item_id: item.item_id, inventry_item_id: item.id })).concat(itemListToSave),
      };
      result = await updateOrderListItems({ customerId: orgId, orderListId: existingListName, payload: data1 });
    }
    if (result.data) {
      onListCreated();
      dispatch(openMessage({ message: listVal == 'new' ? 'Order list created' : 'Order list updated', messageSeverity: 'success' }));
      onClose();
    }
  }
  const handleChange = (event) => {
    setListVal(event.target.value);
  };
  const handleListNameChange = async (event) => {
    await getOrderListById({ orgId, orderListId: event.target.value });
    setExistingListName(event.target.value);
  }
  return (
    <Dialog
      maxWidth={"lg"}
      scroll={"paper"}
      open={open}
      onClose={onClose} // Call onClose function when dialog is closed
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            padding: 2
          },
        },
      }}
    >
      <DialogTitle sx={{ padding: 0, paddingBottom: 2 }}>
        <Typography variant='h5'>Review</Typography>
        <IconButton
          aria-label="close"
          onClick={onClose} // Call onClose function when close icon is clicked
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            // color: 'inherit',
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ padding: 0 }}>
        <Box>
          <FormControl>
            <RadioGroup
              row
              value={listVal}
              onChange={handleChange}
            >
              <FormControlLabel value="new" control={<Radio size="small" />} label={<Typography variant="body2" sx={{ fontWeight: 'normal' }}>Create New Order List</Typography>} />
              <FormControlLabel value="existing" control={<Radio size="small" />} label={<Typography variant="body2" sx={{ fontWeight: 'normal' }}>Add into Existing Order List</Typography>} />
            </RadioGroup>
          </FormControl>
        </Box>
        <Box sx={{ paddingTop: 1 }}>
          {listVal == 'new' && <TextField
            value={listName}
            sx={{ width: 500 }}
            label="Order List Name"
            onChange={(event) => {
              setListName(event.target.value);
            }}
          />}
          {listVal == 'existing' && <FormControl>
            <InputLabel id="demo-simple-select-label">Select Order List</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              sx={{ width: 500 }}
              value={existingListName}
              label="Select Order List"
              onChange={handleListNameChange}
            >
              {
                vendorOrderList.map((_list) => {
                  return <MenuItem value={_list.id}>{_list.name}</MenuItem>
                })
              }
            </Select>
          </FormControl>}
        </Box>
        <Box>
          <TableContainer component={Paper} sx={{ padding: 0 }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Image</TableCell>
                  <TableCell>Item</TableCell>
                  <TableCell>Rate</TableCell>
                  <TableCell>Unit</TableCell>
                  <TableCell>Bulk Rate</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {wishList.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell>{<ItemsImageComponent s3key={row?.item_image_key} name={row?.name} />}</TableCell>
                    <TableCell>{<Typography variant="subtitle2">{row.name}</Typography>}</TableCell>
                    <TableCell><Typography variant="subtitle2" fontWeight={500}>₹{row.rate}</Typography></TableCell>
                    <TableCell><Typography variant="subtitle2" fontWeight={500}>{row.unit}</Typography></TableCell>
                    <TableCell><Typography variant="body2" >
                      {row?.itemQuantityPricesMappingDetails?.length > 0 && (
                        <span>
                          {'₹' + row?.itemQuantityPricesMappingDetails[0]?.itemPrice}
                          [<span>{row?.itemQuantityPricesMappingDetails[0]?.itemQuantity + row?.unit}</span>]
                        </span>
                      )}
                      {
                        row?.itemQuantityPricesMappingDetails?.length > 0 && <IconButton >
                          <Iconify icon="material-symbols-light:keyboard-double-arrow-right" />
                        </IconButton>
                      }
                    </Typography></TableCell>
                    <TableCell><IconButton onClick={() => addToWishlist(row)}><Iconify icon="bi:trash" sx={{ color: 'red' }} /></IconButton></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ display: 'flex', justifyContent: 'end', paddingTop: 2 }}>
            <Button variant='contained' disabled={listVal == 'new' ? !listName : !existingListName} onClick={createList}>{listVal == 'new' ? 'Create Order List' : 'Update Order List'}</Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default ReviewWishListDialogue;
