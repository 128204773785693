import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Container,Stack,styled } from '@mui/material';

const SectionContainer = styled(Container)(({ theme }) => ({
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
  }));

const FAQs = () => {
  const faqs = [
    {
      question: "Where Can I see all My Orders?",
      answer: "Once you login to your account, your Dashboard provides details of all Previously Fulfilled Orders, Cancelled & Pending Orders."
    },
    {
      question: "How can I Edit or cancel orders?",
      answer: "As a B2B platform, different products have different order modification & cancellation policy. In general most orders can be modified or cancelled (except daily provisions & perishables) till two hours of the order being placed. See order cancellation policy for each product category before placing your order."
    },
    {
      question: "How Can I track my Order?",
      answer: "Once an order has been shipped, you will get a shipment confirmation email. The email will include the name of the logistic service provider company along with the consignment number which can then be tracked on the website of the logistic provider in most of the cases."
    },
    // {
    //   question: "How can I claim Returns, Refunds or Replacements?",
    //   answer: ```As a B2B platform Horeca1 allows easy Returns in qualified cases. An order once delivered, can be returned if:
    //   - The product delivered is damaged
    //   - Is different than the one ordered
    //   - To claim a Return, please login to your account and go to My Orders section. Once the returns claim is processed, as per the above guidelines, you’ll be eligible for a Replacement or Refund.```
    // },
    {
      question: "Purchase Protection",
      answer: "As a B2B platform, we are committed to ensuring Purchase Protection for customers by offering genuine products, secure payments and easy grievance redressal for items purchased through Horeca1. However, please note that Horeca1 is a retailer/wholeseller platform ONLY, and claims no responsibilities for Manufacturer’s warranties, post-sale services, etc which are exclusively the domain of the Manufacturer / Company Authorised Seller."
    },
    {
      question: "Grievance Redressal",
      answer: "For any issues related to purchases on HCX Global, Customers can contact our Customer Service via email - sales@horeca1.com or call 7710920002. Our Customer Service Representatives will escalate the matter to respective parties to get the issue resolved at the earliest."
    }
  ];

  const StyledAccordion = styled(Accordion)(({ theme }) => ({
    '&::before': {
      backgroundColor: 'transparent', // Set background color to transparent
    },
  }));

  return (
    <SectionContainer>
      <Typography variant="h3" component="h3" className="text-align-left w-400 fw-normal fs-mweb-32-resp mb-5" sx={{textAlign:'center'}}>
        Frequently asked questions
      </Typography>
      <Stack direction={'column'} spacing={1} mt={'1rem'}>
      {faqs.map((faq, index) => (
        <StyledAccordion key={index} sx={{borderRadius:'1rem', borderTop:'none'}}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}-content`}
            id={`panel${index}-header`}
          >
            <Typography variant="body1" component="h5">
              {faq.question}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2">
              {faq.answer}
            </Typography>
          </AccordionDetails>
        </StyledAccordion>
      ))}
      </Stack>
    </SectionContainer>
  );
};

export default FAQs;
