import React, { useEffect, useState } from "react";
import { Box, Button, Divider, Drawer, Grid, IconButton, Link, MenuItem, Stack, Tooltip, Typography } from "@mui/material";
import { styled } from "@mui/system";
import EnterQuantity from "./../EnterQuantity";
import { useAddItemToCartMutation, useRemoveItemFromCartMutation, useUpdateCartItemQuantityMutation } from "../../../redux/api/api";
import { openMessage } from "../../../redux/slices/showMessageSlice";
import { useDispatch } from "react-redux";
import { noSticker } from "../../../assets/images/landing";
import useResponsiveStyles from "../../../hooks/useResponsiveStyles";
import AddBoxIcon from '@mui/icons-material/AddBox';
import GetImageComponent from "../../../components/common/GetImageComponent";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import PlaylistAddCircleIcon from '@mui/icons-material/PlaylistAddCircle';
import PlaylistAddCheckCircleIcon from '@mui/icons-material/PlaylistAddCheckCircle';
import CartWarningDialog from "../CartWarningDialog";
import ViewBulkQuantityPrices from "../../../components/common/ViewBulkQuantityPrices";

const CatalogCardContainer = styled(Box)(({ theme }) => ({
    // marginBottom: '24px',
    padding: "8px 0",
    position: "relative",
    overflow: "hidden",
    display: "flex",
    boxShadow: "0 1px 4px rgba(28,28,28,.06)",
    borderRadius: "24px",
    flexDirection: "column",
    justifyContent: "flex-end",
    "& .CatalogCard_catalogCard__mGd27": {
        display: "flex",
        flexDirection: "column",
    },
    border: `1px solid ${theme.palette.mode === "dark" ? "rgba(145, 158, 171, 0.16)" : "rgba(145, 158, 171, 0.16)"}`,
    transition: "box-shadow 0.3s",
    "&:hover": {
        boxShadow: theme.palette.mode !== "dark" && "rgba(145, 158, 171, 0.16) 0px 20px 40px -4px",
        backgroundColor: theme.palette.mode === "dark" && theme.palette.background.paper,
    },
}));

const ProductDetails = ({ data, customerId, vendorId, allCartItemsData, addToWishlist, wishList }) => {
    const dispatch = useDispatch();
    const [quantity, setQuantity] = useState(1);
    const [selectedBulkIndex, setSelectedBulkIndex] = useState(0);
    const { isMobile } = useResponsiveStyles();
    const [openWarning, setOpenWarning] = useState(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [addItemToCart, { isLoading: addItemToCartLoading }] = useAddItemToCartMutation();
    const [updateCartItemQuantity] = useUpdateCartItemQuantityMutation();

    const [cartItemId, setCartItemId] = useState(allCartItemsData?.find((allCartData) => allCartData?.itemId == data?.id)?.id);

    const [quantity1, setQuantity1] = useState(
        allCartItemsData?.find((allCartData) => allCartData?.itemId == data?.id)
            ? allCartItemsData?.find((allCartData) => allCartData?.itemId == data?.id)?.quantity
            : 0
    );

    useEffect(() => {
        if (allCartItemsData && allCartItemsData.length > 0) {
            const _itemRec = allCartItemsData?.find((allCartData) => allCartData?.itemId == data?.id);
            setQuantity1(
                _itemRec
                    ? _itemRec?.quantity
                    : 0
            );
            if(_itemRec && _itemRec.bulkQtyId && data?.itemQuantityPricesMappingDetails.length > 0) {
                const _bulkIndex = data.itemQuantityPricesMappingDetails.findIndex(_obj => Number(_obj.id) == Number(_itemRec.bulkQtyId));
                setSelectedBulkIndex(_bulkIndex)
            }
        }
    }, [allCartItemsData]);
    const toggleDrawer = (open) => (event) => {
        if (
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        setIsDrawerOpen(open);
    }

    const handleAddToCart1 = async () => {
        const vendorItem = allCartItemsData?.find((allCartData) => allCartData.vendorId != vendorId);
        if (vendorItem) {
            setOpenWarning(true);
            return;
        }
        const payload = {
            items: {
                itemId: data?.id,
                quantity: quantity,
                unit: data?.inventoryDataDump?.unit,
            },
            customerId: customerId,
            vendorId: vendorId,
        };

        const result = await addItemToCart({ payload });
        if (result?.data) {
            setQuantity(1);
            dispatch(openMessage({ message: "Items added", messageSeverity: "success" }));
        } else if (result?.error) {
            dispatch(openMessage({ message: result?.error?.data?.message, messageSeverity: "error" }));
        } else {
            dispatch(openMessage({ message: "Not able to add an itme to cart at this moment", messageSeverity: "error" }));
        }
    };

    const handleAddToCart = async (itemId, quantity, bulkId) => {
        const payload = {
            items: {
                itemId: data?.id,
                quantity: quantity,
                ...(bulkId ? { bulkQtyId: bulkId } : {}),
            },
            customerId: customerId,
            vendorId: vendorId,
        };

        await addItemToCart({ payload });
    };

    const handleAddToCart2 = async (itemId, quantity) => {
        await updateCartItemQuantity({ cartItemId: itemId, qty: quantity });
    };

    const addItem = async () => { };
    const addItemQuantity = (itemId, quantity, bulkId) => {
        if(quantity1 + parseInt(quantity) > 1000) {
            dispatch(openMessage({ message: "Quantity cannot exceed 1000.", messageSeverity: "error" }));
        } else {
            setQuantity1(quantity1 + parseInt(quantity));
            handleAddToCart(itemId, quantity, bulkId);
        }
    }

    const [removeItemFromCart] = useRemoveItemFromCartMutation();

    const removeItem = async () => {
        await removeItemFromCart(allCartItemsData?.find((allCartData) => allCartData?.itemId == data?.id)?.id);
        setQuantity1(0);
    };
    return (
        <Box sx={{ flexGrow: 1, width: '100%', border: '.5px solid #d3d3d3', borderRadius: 2, backgroundColor: '#FFF', position: 'relative', padding: 1.5, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {/* <IconButton size="small" sx={{ border: '1px solid #B3B3B3', position:'absolute', right: 10, top: 10 }} onClick={() => { addToWishlist(data) }}>
                        {
                            wishList.find((_obj) => _obj.id == data.id) ?
                                <FavoriteIcon fontSize="small" color="error"/> :
                                <FavoriteBorderIcon fontSize="small" />
                        }
                    </IconButton> */}
            <GetImageComponent s3Key={data?.item_image_key} nocardSticker={noSticker} style={{ height: 100, width: 100 }} />
            <Box sx={{ width: '100%', flexGrow: 1, padding: 0.5, paddingBottom: 0, paddingTop: 2, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                    <Tooltip title={wishList.find((_obj) => _obj.id == data.id) ? 'Click to remove from order list' : 'Click to add in the order list'}>
                        <IconButton onClick={() => { addToWishlist(data) }}>
                            {
                                wishList.find((_obj) => _obj.id == data.id) ?
                                    <PlaylistAddCheckCircleIcon sx={{ color: '#2066D1' }} /> :
                                    <PlaylistAddCircleIcon />
                            }
                        </IconButton>
                    </Tooltip>
                </Box>
                <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="subtitle2" color="textSecondary" sx={{ fontSize: 13 }}>{data.name}</Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', pt: data?.itemQuantityPricesMappingDetails?.length > 0 ? 0.5 : 0 }}>
                        <Typography variant="body2" color="textSecondary" sx={{ fontSize: 13 }}>1 {data?.unit}</Typography>

                    </Box>
                    {data?.itemQuantityPricesMappingDetails?.length > 0 &&
                        // <Link
                        //     component="button"
                        //     sx={{ paddingBottom: 1 }}
                        //     variant="body2"
                        //     onClick={() => {
                        //         setIsDrawerOpen(true)
                        //     }}
                        // >
                        //     <Typography sx={{ fontWeight: 'bold' }}>Bulk Offers1</Typography>
                        // </Link>
                        <Box sx={{ py: 1 }}>
                            <ViewBulkQuantityPrices
                                row={data}
                                style={{ position: "absolute", right: "-60%", bottom: 0 }}
                                handleAddToCart={addItemQuantity}
                                quantity1={quantity1}
                                setQuantity1={setQuantity1}
                                selectedBulkIndex={selectedBulkIndex}
                            />
                        </Box>
                    }
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, paddingTop: 1, borderTop: '0.5px dashed rgb(181, 181, 181)' }}>
                    {/* {data?.itemQuantityPricesMappingDetails?.length > 0 && 
            <Link
              component="button"
              sx={{ alignSelf:'end' }}
              variant="body2"
              onClick={() => {
                setIsDrawerOpen(true)
              }}
            >
              Bulk Offers
            </Link>} */}
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="subtitle2" sx={{ fontSize: 13 }}>₹ {data.rate}</Typography>
                        <Box sx={{ display: 'flex', gap: 1 }}>
                            <EnterQuantity
                                addItem={addItem}
                                removeItem={removeItem}
                                quantity={quantity}
                                setQuantity={setQuantity}
                                quantity1={quantity1}
                                data={data}
                                setQuantity1={setQuantity1}
                                handleAddToCart1={handleAddToCart1}
                                handleAddToCart={handleAddToCart}
                            />
                        </Box>
                    </Box>

                </Box>
            </Box>
            <CartWarningDialog
                open={openWarning}
                onClose={() => setOpenWarning(false)}
                message={'Your cart already contains items from a different vendor. Please clear the cart before continuing.'}
            />
        </Box>
    )

};

export default ProductDetails;
