import React, { useState, useEffect, useCallback } from "react";
import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";
import { DotButton, PrevButton, NextButton } from "./EmblaCarasousalDotButton";
// import imageByIndex from './ImageByIndex'
import { imageByIndex, mImageByIndex } from "./ImageByIndex";
import { useResponsive } from "../../../hooks/useResponsive";

const EmblaCarousel = (props) => {
  const { slides, options, isHome } = props;
  const [emblaRef, emblaApi] = useEmblaCarousel(options, [Autoplay()]);
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState([]);

  const mdDown = useResponsive("down", "md");

  const scrollPrev = useCallback(() => emblaApi && emblaApi.scrollPrev(), [emblaApi]);
  const scrollNext = useCallback(() => emblaApi && emblaApi.scrollNext(), [emblaApi]);
  const scrollTo = useCallback((index) => emblaApi && emblaApi.scrollTo(index), [emblaApi]);

  const onInit = useCallback((emblaApi) => {
    setScrollSnaps(emblaApi.scrollSnapList());
  }, []);

  const onSelect = useCallback((emblaApi) => {
    setSelectedIndex(emblaApi.selectedScrollSnap());
    setPrevBtnDisabled(!emblaApi.canScrollPrev());
    setNextBtnDisabled(!emblaApi.canScrollNext());
  }, []);

  useEffect(() => {
    if (!emblaApi) return;

    onInit(emblaApi);
    onSelect(emblaApi);
    emblaApi.on("reInit", onInit);
    emblaApi.on("reInit", onSelect);
    emblaApi.on("select", onSelect);
  }, [emblaApi, onInit, onSelect]);

  return (
    <>
      <div className="embla">
        <div className="embla__viewport" ref={emblaRef}>
          <div className="embla__container">
            {mdDown && slides.map((index) => (
              <div className="embla__slide" key={index} style={{ display: "flex", height: 200 }}>
                <img className={isHome ? "embla__slide__img_home_mobile" : "embla__slide__img"} src={mdDown ? mImageByIndex(index) : imageByIndex(index)} alt="Your alt text" />
              </div>
            ))}
            {!mdDown && slides.map((index) => (
              <div className="embla__slide" key={index} style={{ display: "flex", gap: '32px' }}>
                <img className={isHome ? "embla__slide__img_home2" : "embla__slide__img"} src={mdDown ? mImageByIndex(index)[0] : imageByIndex(index)[0]} />
                <img className={isHome ? "embla__slide__img_home2" : "embla__slide__img"} src={mdDown ? mImageByIndex(index)[1] : imageByIndex(index)[1]} />
              </div>
            ))}
          </div>
        </div>

        {!mdDown && <div className="embla__buttons" style={{ justifyContent: "space-between", width: "100%" }}>
          <PrevButton onClick={scrollPrev} disabled={prevBtnDisabled} />
          <NextButton onClick={scrollNext} disabled={nextBtnDisabled} />
        </div>}
      </div>

      {<div className="embla__dots_mobile">
        {scrollSnaps.map((_, index) => (
          <DotButton
            key={index}
            onClick={() => scrollTo(index)}
            className={"embla__dot".concat(index === selectedIndex ? " embla__dot--selected" : "")}
          />
        ))}
      </div>}
    </>
  );
};

export default EmblaCarousel;
