import { useEffect, useMemo, useState } from "react";
import SvgColor from "../../components/common/SvgColor";
import { getOrgData, getOrgType } from "../../utils/org";
import { useSelector } from "react-redux";
import { horecaApi } from "../../redux/api/api";

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 24, height: 24 }}
  />
);

export const SidebarConfig1 = (cofigName) => {

  const user = useSelector((state) => state.auth.user);

  const currOrgId = useSelector((state) => state.app.preference?.selectedOrgId);

  const orgType = getOrgType(user, currOrgId);

  const [myRoles, setMyRoles] = useState([]);
  
  const loadRoles = async () => {
    try {
      const result = getOrgData(user, currOrgId)      
      if (result?.roleDetail) {
        setMyRoles(result?.roleDetail);
      }
    } catch (error) {
      // Handle error
    }
  };


  useEffect(() => {
    (async () => {
      await loadRoles();
    })();
  }, [currOrgId]);

  const sidebar = useMemo(() => {

    const checkPermission = (routeId) => {
      const result = getOrgData(user, currOrgId)
        if(result?.roleDetail[0]?.roleName === 'customer' || result?.roleDetail[0]?.roleName === 'system_admin' || result?.roleDetail[0]?.roleName === 'vendor'){
          return true;
        }else{
          return myRoles?.some((role) =>
            role?.permissions?.some((permission) => permission?.permissionName === routeId)
          ); 
        }
    };

    switch (orgType) {
      case "vendor":
        return [
          {
            id: "home",
            title: "home",
            path: "/",
            icon: icon("ic_home"),
          },
          {
            id: "salesOrder",
            title: "Sales Order",
            path: `/org/${currOrgId}/sales-order`,
            icon: icon("ic_orders"),
          },
          {
            id: "inventory",
            title: "inventory",
            path: `/org/${currOrgId}/inventory-items`,
            icon: icon("ic_inventory"),
          },
          {
            id: "myCustomers",
            title: "my customers",
            path: `/org/${currOrgId}/my-customers`,
            icon: icon("ic_hotels"),
          },
          {
            id: "outlets",
            title: "my outlets",
            path: `/org/${currOrgId}/my-outlets`,
            icon: icon("ic_outlets"),
          },
          {
            id: "myTeam",
            title: "my team",
            path: `/org/${currOrgId}/my-team`,
            icon: icon("ic_team"),
          },
          {
            id: "dashboard",
            title: "Dashboard",
            path: `/org/${currOrgId}/dashboard`,
            icon: icon("ic_dashboard"),
          },
          {
            title: "settings",
            path: `/org/${currOrgId}/settings`,
            icon: icon("ic_settings"),
          },
        ].filter((route) => checkPermission(route.id));
      case "customer":
        return [
          {
            id: "home",
            title: "home",
            path: "/",
            icon: icon("ic_home"),
            children: [
              { id: "explore", title: "explore", path: "/explore" },
              { id: "items", title: "items", path: "/order-list/create" },
            ],
          },
          {
            id: "orderList",
            title: "order list",
            path: `/org/${currOrgId}/orders`,
            icon: icon("ic_order"),
          },
          {
            id: "orderHistory",
            title: "order History",
            path: `/org/${currOrgId}/order-history`,
            icon: icon("ic_orderhistory"),
          },
          {
            id: "invoices",
            title: "invoices",
            path: `/org/${currOrgId}/invoice`,
            icon: icon("ic_invoices"),
          },
          {
            id: "myVendors",
            title: "my vendors",
            path: `/org/${currOrgId}/my-vendors`,
            icon: icon("ic_privatestore"),
          },
          {
            id: "outlets",
            title: "my outlets",
            path: `/org/${currOrgId}/my-outlets`,
            icon: icon("ic_outlets"),
          },
          {
            id: "myTeam",
            title: "my team",
            path: `/org/${currOrgId}/my-team`,
            icon: icon("ic_team"),
          },
          {
            id: "dashboard",
            title: "Dashboard",
            path: `/org/${currOrgId}/dashboard`,
            icon: icon("ic_dashboard"),
          },
          {
            id:'settings',
            title: "settings",
            path: `/org/${currOrgId}/settings`,
            icon: icon("ic_settings"),
          },
        ].filter((route) => checkPermission(route.id));
      case "system_admin":
        return [
          {
            id: "home",
            title: "home",
            path: "/",
            icon: icon("ic_home"),
          },
          {
            id: "vendors",
            title: "vendors",
            path: `/org/${currOrgId}/vendors`,
            icon: icon("ic_vendor"),
          },
          {
            id: "customers",
            title: "customers",
            path: `/org/${currOrgId}/customers`,
            icon: icon("ic_customer"),
          },
          {
            id: "category",
            title: "category",
            path: `/org/${currOrgId}/category`,
            icon: icon("ic_categroy"),
          },
          {
            id: 'settings',
            title: "settings",
            path: `/org/${currOrgId}/settings`,
            icon: icon("ic_settings"),
          },
        ];
      default:
        return [];
    }
  }, [orgType, myRoles]);

  return sidebar;
};