import { Box, Button, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CategoryCard } from "../../../../../landing/CategoryCard";
import { horecaApi } from "../../../../../../redux/api/api";
import useResponsiveStyles from "../../../../../../hooks/useResponsiveStyles";
import CategoryDisplay from "./CategoryDisplay";

const CategorySection = ({ selectedCategory, onSelectCategory }) => {
    const responsive = useResponsiveStyles();
    const [showAll, setShowAll] = useState(false);
    const pincode = useSelector((state) => state.app.preference.selectedPinCode?.pincode);
    const [getAllCategoriesByPinCode, { data: allCategories }] = horecaApi.endpoints.getAllCategoriesByPinCode.useLazyQuery();
    useEffect(() => {
        if(pincode) {
            getAllCategoriesByPinCode({ pinCode: pincode })
        }
    }, [pincode])
    if(responsive?.isMobile) {
        return (
            <Box sx={{ padding: 1, paddingTop: 3, display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Typography variant="h4">Shop By Category</Typography>
                <Grid container sx={{ paddingTop: 2 }} spacing={0.5} columns={10}>
                    {allCategories && allCategories.length > 0 &&
                        allCategories.map((cat, index) => (
                            ((!showAll && index < 14) || showAll) && 
                            <CategoryDisplay key={`${index}cat`} category={cat} onSelectCategory={onSelectCategory} selectedCategory={selectedCategory}/>
                        ))
                    }
                </Grid>
                {(!showAll && allCategories && allCategories.length > 14) && <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button size="large" onClick={() => setShowAll(true)}>Show All</Button>
                </Box>}
            </Box>
        )
    }
    return (
        <Box sx={{ padding: 3, display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography variant="h4">Shop By Category</Typography>
            <Grid container sx={{ paddingTop: 2 }} spacing={1} columns={14}>
                {allCategories && allCategories.length > 0 &&
                    allCategories.map((cat, index) => (
                        ((!showAll && index < 14) || showAll) && 
                       
                        <CategoryDisplay key={`${index}cat`} category={cat} onSelectCategory={onSelectCategory} selectedCategory={selectedCategory}/>
                    ))
                }
            </Grid>
            {(!showAll && allCategories && allCategories.length > 14) && <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button size="large" onClick={() => setShowAll(true)}>Show All</Button>
            </Box>}
        </Box>
    )
}

export default CategorySection;