import { styled, alpha } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Button, Menu, MenuItem, Typography } from '@mui/material';

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 10,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        backdropFilter: 'blur(20px)',
        backgroundImage: 'url("https://minimals.cc/assets/cyan-blur.png"), url("https://minimals.cc/assets/red-blur.png")',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right top, left bottom',
        borderRadius: "10px",
        boxShadow: '0px 0px 2px 0px rgba(145, 158, 171, 0.24), -20px 20px 40px -4px rgba(145, 158, 171, 0.24)',
        transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        '& .MuiMenu-list': { padding: '0px' },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

export function CustomizedMenu({ anchorEl, handleClick, handleClose, label, noDataMsg, options, selectedOption, setSelectedOption, submenuAnchorEl, submenuHandleClick, submenuHandleClose, submenuNoDataMsg, submenuOptions, submenuSelectedOption, submenuSetSelectedOption, variant = 'text' }) {
    const open = Boolean(anchorEl);
    const submenuOpen = Boolean(submenuAnchorEl);

    return (
        <>
            <Button
                id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant={variant}
                disableElevation
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
                fullWidth
                sx={{
                    color: (theme) => theme.palette.text.primary,
                    '& .MuiButton-endIcon': {
                        display: open ? 'flex' : 'flex',
                        transform: open && 'rotate(-180deg)',
                        transition: 'transform 0.3s ease-in-out',
                    },
                    '&:hover .MuiButton-endIcon': { display: 'flex' },
                    '&:hover': {
                        bgcolor: "transparent",
                    }
                }}
            >
                <Typography variant={"subtitle2"} sx={{ textAlign: "start" }} width={"100%"} noWrap>
                    {label}
                </Typography>
            </Button>
            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button'
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                // anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                // transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                sx={{
                    '& .MuiMenu-list': {
                        width: 200
                    },
                }}
            >
                {options?.length ? (
                    options?.map((option, index) => (
                        <MenuItem
                            key={option.id}
                            value={option.id}
                            disableRipple
                            disableTouchRipple
                            sx={{ px: 1, borderBottom: index !== options?.length - 1 && '1px dashed rgba(145, 158, 171, 0.2)' }}
                            onClick={setSelectedOption}
                            width={"100%"}
                        >
                            <Button
                                id="demo-customized-button"
                                aria-controls={submenuOpen ? 'demo-customized-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={submenuOpen ? 'true' : undefined}
                                variant="text"
                                disableElevation
                                onClick={submenuHandleClick}
                                endIcon={submenuOptions?.length > 0 ? (submenuOpen && <KeyboardArrowDownIcon />) : (<></>)}
                                fullWidth
                                value={option.id}
                                sx={{
                                    color: (theme) => theme.palette.text.primary,
                                    '& .MuiButton-endIcon': {
                                        display: selectedOption == option?.id && submenuOpen ? 'flex' : 'none',
                                        transform: submenuOpen && 'rotate(-180deg)',
                                        transition: 'transform 0.3s ease-in-out',
                                    },
                                    '&:hover .MuiButton-endIcon': { display: 'flex' },
                                    '&:hover': {
                                        bgcolor: "transparent",
                                    }
                                }}
                            >
                                <Typography variant={selectedOption == option?.id ? "subtitle2" : "body2"} sx={{ textAlign: "start" }} width={"100%"} noWrap>
                                    {option?.label}
                                </Typography>
                            </Button>
                            <StyledMenu
                                id="demo-customized-menu"
                                MenuListProps={{
                                    'aria-labelledby': 'demo-customized-button',
                                }}
                                anchorEl={submenuAnchorEl}
                                open={submenuOpen}
                                onClose={submenuHandleClose}
                                // anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                                // transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                sx={{
                                    '& .MuiMenu-list': {
                                        width: 200
                                    },
                                }}
                            >
                                {submenuOptions?.length ? (
                                    submenuOptions?.map((option, index) => (
                                        <MenuItem
                                            key={option.id}
                                            value={option.id}
                                            disableRipple
                                            disableTouchRipple
                                            sx={{ px: 1, borderBottom: index !== submenuOptions?.length - 1 && '1px dashed rgba(145, 158, 171, 0.2)' }}
                                            onClick={submenuHandleClick}
                                            width={"100%"}
                                        >
                                            <Typography variant={submenuSelectedOption == option?.id ? "subtitle2" : "body2"} padding={"6px 10px 6px"} sx={{ textAlign: "start" }} width={"100%"} noWrap>
                                                {option?.label}
                                            </Typography>
                                        </MenuItem>
                                    ))
                                ) : (
                                    <MenuItem>
                                        <Typography variant={"inherit"} padding={"6px 10px 6px"} sx={{ color: (theme) => theme.palette.error.main, textAlign: "center", width: "100%" }} noWrap>
                                            {submenuNoDataMsg ?? "No Records"}
                                        </Typography>
                                    </MenuItem>
                                )}
                            </StyledMenu>
                        </MenuItem>
                    ))
                ) : (
                    <MenuItem>
                        <Typography variant={"inherit"} padding={"6px 10px 6px"} sx={{ color: (theme) => theme.palette.error.main, textAlign: "center", width: "100%" }} noWrap>
                            {noDataMsg ?? "No Records"}
                        </Typography>
                    </MenuItem>
                )}
            </StyledMenu>
        </>
    );
}