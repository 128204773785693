import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux'
import { Box, Stack, AppBar, Toolbar, useTheme, IconButton, alpha, Badge } from '@mui/material';

import { useResponsive } from '../hooks/useResponsive';

import { bgBlur, bgGradient } from '../theme/css';

import Iconify from '../components/common/Iconify';

import { NAV, HEADER } from './common/configLayout';
import Searchbar from './common/Searchbar'
import AccountPopover from './common/AccountPopover';
import NotificationsPopover from './common/NotificationPopover';
import ContactListPopover from './common/ContactListPopover';
import Logo from '../components/common/Logo';
import { getOrgData } from '../utils/org';
import SelectAddress from './common/SelectAddress';
import { Link } from 'react-router-dom';
import { useGetCartItemsQuery } from '../redux/api/api';
import useResponsiveStyles from '../hooks/useResponsiveStyles';
import { horecaLogo } from '../assets/images';


// ----------------------------------------------------------------------

const Header = ({ onOpenNav }) => {
  const theme = useTheme();

  // const {orgId} = useParams();
  const orgId = useSelector((state) => state.app.preference.selectedOrgId)
  const user = useSelector((state) => state.auth.user)
  const { data: allCartItemsData } = useGetCartItemsQuery(orgId);


  const isSideOpen = useSelector((state) => state.common.isSideOpen);

  const lgUp = useResponsive('up', 'lg');

  const orgData = getOrgData(user, orgId);
  const { isMobile } = useResponsiveStyles();

  const renderContent = (
    <>
      {
        !lgUp &&
        <>
          <IconButton onClick={onOpenNav} sx={{ mr: 1 }}>
            <Iconify icon="eva:menu-2-fill" />
          </IconButton>
          {
            // (!isSideOpen || !isMobile)  && <Logo />
          }
          {
            orgData?.type === 'customer' && <Searchbar />
          }
          <Box sx={{ flexGrow: 1 }} />
          <Stack direction="row" alignItems="center" spacing={!lgUp?0.5:1}>
            {/* {
              orgData?.type != 'system_admin' && <SelectAddress />
            } */}
            {/* {
              orgData?.type === 'customer' &&
              <IconButton component={Link} to={`org/${orgId}/cart`}>

              </IconButton>
            } */}
            {
              !lgUp && orgData?.type != 'system_admin' &&
              <Box><SelectAddress /></Box>
            }
            {
              orgData?.type === 'customer' &&
              <IconButton component={Link} to={`org/${orgId}/cart`}>
                <Badge badgeContent={allCartItemsData?.length} color="primary">
                  <Iconify icon="mdi:cart-outline" />
                </Badge>
              </IconButton>
            }
            <NotificationsPopover />
            {/* <AccountPopover /> */}
          </Stack>
        </>
      }
      {lgUp &&
        <>
          {
            !isSideOpen && <Box component="img" src={horecaLogo} alt="Brand" width="120px" />
          }
          {
            orgData?.type === 'customer' && <Searchbar />
          }
          <Box sx={{ flexGrow: 1 }} />
          <Stack direction="row" alignItems="center" spacing={isMobile ? 0.2 : 1}>
            {
              orgData?.type != 'system_admin' && <SelectAddress />
            }
            {
              orgData?.type === 'customer' &&
              <IconButton component={Link} to={`org/${orgId}/cart`}>
                <Badge badgeContent={allCartItemsData?.length} color="primary">
                  <Iconify icon="mdi:cart-outline" />
                </Badge>
              </IconButton>
            }
            <NotificationsPopover />
            <AccountPopover />
          </Stack>
        </>}
    </>
  );

  return (
    <AppBar
      sx={{
        boxShadow: 'none',
        height: HEADER.H_MOBILE,
        backgroundColor: '#FFF',
        //zIndex: theme.zIndex.appBar + 1,
        // ...bgBlur({
        //   color: theme.palette.background.default,
        // }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(lgUp && {
          width: `calc(100% - ${isSideOpen ? NAV.WIDTH + 0.2 : 100 + -10}px)`,
          height: HEADER.H_DESKTOP,
        }),
        ...bgGradient({
          color: alpha(theme.palette.background.default, 0.96),
        }),
        borderRadius: "0px"
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 3 },
        }}
      >
        {renderContent}
      </Toolbar>
      {/* {
        !lgUp && orgData?.type != 'system_admin' && 
        <Box sx={{ display:'flex', justifyContent:'end', paddingRight: 2 }}><SelectAddress /></Box>
      } */}
    </AppBar>
  );
}

Header.propTypes = {
  onOpenNav: PropTypes.func,
};


export default Header;