import { Grid, Card, CardMedia, CardContent, Typography, Link, Stack } from "@mui/material";
import { noSticker } from "../../../../../../assets/images/landing";
import useResponsiveStyles from "../../../../../../hooks/useResponsiveStyles";
import GetImageComponent from "../../../../../../components/common/GetImageComponent";

export const CategoryDisplay = ({ category, onSelectCategory, selectedCategory, fromLanding }) => {
  const subCategories = category?.subCategoryDetails?.map((subItem) => subItem?.category_name);
  // Join subcategories and limit to 4 items, adding "..." if there are more
  const subCategoriesText = subCategories?.length > 3 ? subCategories?.slice(0, 2)?.join(", ") + ", ..." : subCategories?.join(", ");
  const responsive = useResponsiveStyles();
  if (responsive.isMobile) {
    return (
      <Grid item xs={2.5} sm={2} sx={{ marginBottom: 2 }}>
        <Card
          onClick={() => onSelectCategory(category)}
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            boxShadow: 'none',
            border: selectedCategory && category.id == selectedCategory.id ? '2px solid #c0d5f5' : 'none',
            backgroundColor: selectedCategory && category.id == selectedCategory.id ? '#E4F3FB' : '#FFF',
            //border: selectedCategory && category.id == selectedCategory.id ? '4px solid #c0d5f5' : 'none',
            transition: 'transform 0.3s, box-shadow 0.3s',
            '&:hover': {
              transform: 'scale(1.05)',
              //boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
            },
            //boxShadow: 'rgba(0, 0, 0, 0.08) 0px 1px 5px, rgba(0, 0, 0, 0.07) 0px -1px 5px 0px'
          }}
        >
          <Stack direction={"row"} justifyContent={"center"} height={60}>
            <GetImageComponent
              s3Key={category?.parent_category_image}
              noSticker={noSticker}
              style={{
                opacity: 1,
                //maxWidth: 'none',
                //width: '80%',
                width: "60px", height: "60px",
                willChange: "transform, opacity",
                //borderRadius: "inherit",
                filter: "unset",
                transition: "opacity 0.25s ease 0s, transform 0.25s ease 0s",
              }}
            />
          </Stack>
          <CardContent sx={{ textAlign: "center", padding: 1, pb: '8px !important' }}>
          <Typography variant="body2" sx={{ fontWeight:'bold',color: '#000',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            WebkitLineClamp: 2,
            lineClamp: 2
           }}>
              {category.parent_category.replace(/and/g, "&")}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    );
  }
  return (
    <Grid item xs={12} sm={6} md={2} sx={{ marginBottom: 2 }}>
      <Card
        onClick={() => onSelectCategory(category)}
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          boxShadow: 'none',
          border: selectedCategory && category.id == selectedCategory.id ? '2px solid #c0d5f5' : 'none',
          transition: 'transform 0.3s, box-shadow 0.3s',
          backgroundColor: selectedCategory && category.id == selectedCategory.id ? '#E4F3FB' : '#FFF',
          '&:hover': {
            transform: 'scale(1.05)',
            //boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
          },
          //boxShadow: 'rgba(0, 0, 0, 0.08) 0px 1px 5px, rgba(0, 0, 0, 0.07) 0px -1px 5px 0px'
        }}
      >
        <Stack direction={"row"} justifyContent={"center"} width={"100%"} >
          <GetImageComponent
            s3Key={category?.parent_category_image}
            noSticker={noSticker}
            style={{
              opacity: 1,
              width: '70%',
              //maxWidth: 'none',
              willChange: "transform, opacity",
              //borderRadius: "inherit",
              filter: "unset",
              transition: "opacity 0.25s ease 0s, transform 0.25s ease 0s",
            }}
          />
        </Stack>
        <CardContent sx={{ textAlign: "center", pb: '0 !important' }}>
          <Typography variant="body2" sx={{ fontWeight: 'bold', color: '#000' }}>
            {category.parent_category}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default CategoryDisplay;