import { Avatar, Box, Grid, Typography } from "@mui/material";
import Carasousal from "../../../../../components/caraousal/Caraousal";
import CategorySection from "./CategorySection";
import VendorSection from "./VendorSection";
import { useState } from "react";
import CaraousalTwo from "../../../../../components/caraousal/caraousalWithTwoBanner/CaraousalTwo";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import useResponsiveStyles from "../../../../../hooks/useResponsiveStyles";

const Home = () => {
    const responsive = useResponsiveStyles();
    const navigate = useNavigate();
    const orgId = useSelector((state) => state.app.preference.selectedOrgId);
    const [selectedCategory, setSelectedCategory] = useState();
    const onSelectCategory = (cat) => {
        if (selectedCategory && cat.id == selectedCategory.id) {
            setSelectedCategory(null);
        } else {
            setSelectedCategory(cat)
        }
    }
    const clearCategorySelection = () => {
        setSelectedCategory(null);
    }
    const navigateToOutlet = () => {
        navigate(`/org/${orgId}/my-outlets`)
    }
    const navigateToApplycredit = () => {
        window.open(`https://discco.horeca1.com`, '_blank');
    }
    if (responsive?.isMobile) {
        return (
            <Box sx={{ padding: 1 }}>
                <Box>
                    <Carasousal isHome={true} />
                </Box>
                <Box sx={{ padding: 1, paddingTop: 5 }}>
                    <Grid container spacing={2} >
                        <Grid item xs={12} sm={4} >
                            <Box sx={{ display: 'flex', border: '1px solid #EBEBEB', borderRadius: 2, cursor: 'pointer', padding: 2, alignItems: 'center', gap: 2 }}>
                                <Box>
                                    <Avatar sx={{ bgcolor: '#EEF3FC', width: 48, height: 48 }} src="/assets/icons/quickTour.jpg"></Avatar>
                                </Box>
                                <Box>
                                    <Typography sx={{ fontSize: 15, fontWeight: 'bold' }}>
                                        Quick Tour
                                    </Typography>
                                    <Typography sx={{ fontSize: 13, fontWeight: 'bold', color: '#696969' }}>
                                        Discover Quickly, Explore Fully
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Box onClick={() => navigateToApplycredit()} sx={{ display: 'flex', border: '1px solid #EBEBEB', borderRadius: 2, cursor: 'pointer', padding: 2, alignItems: 'center', gap: 2 }}>
                                <Box>
                                    <Avatar sx={{ backgroundColor: '#DFE3E7', width: 48, border: '1px solid #DFE3E7', height: 48 }} src="/assets/icons/applyCredit.png"></Avatar>
                                </Box>
                                <Box>
                                    <Typography sx={{ fontSize: 15, fontWeight: 'bold' }}>
                                        Apply for Credit
                                    </Typography>
                                    <Typography sx={{ fontSize: 13, fontWeight: 'bold', color: '#696969' }}>
                                        Step into Financial Freedom
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Box onClick={() => navigateToOutlet()} sx={{ display: 'flex', border: '1px solid #EBEBEB', borderRadius: 2, cursor: 'pointer', padding: 2, alignItems: 'center', gap: 3 }}>
                                <Box>
                                    <Avatar sx={{ bgcolor: 'red', width: 48, height: 48 }} src="/assets/icons/addOutlet1.jpeg"></Avatar>
                                </Box>
                                <Box>
                                    <Typography sx={{ fontSize: 15, fontWeight: 'bold' }}>
                                        Add Outlets
                                    </Typography>
                                    <Typography sx={{ fontSize: 13, fontWeight: 'bold', color: '#696969' }}>
                                        Empowering Your Business Expansion
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <CategorySection selectedCategory={selectedCategory} onSelectCategory={onSelectCategory} />
                <VendorSection selectedCategory={selectedCategory} clearCategorySelection={clearCategorySelection} />
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Box sx={{ padding: 2 }}>
                        <Typography variant="h4">Prime Deals</Typography>
                    </Box>
                    <Carasousal isHome={true} />
                </Box>
            </Box>
        )
    }
    return (
        <Box sx={{ padding: 3 }}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Carasousal isHome={true} />
            </Box>
            <Box sx={{ padding: 4 }}>
                <Grid container sx={{ border: '1px solid #EBEBEB', borderRadius: 2, padding: 2 }}>
                    <Grid item sm={4}>
                        <Box sx={{ display: 'flex', cursor: 'pointer', padding: 2, alignItems: 'center', gap: 3, borderRight: '1px solid #EBEBEB' }}>
                            <Box>
                                <Avatar sx={{ bgcolor: '#EEF3FC', width: 72, height: 72 }} src="/assets/icons/quickTour.jpg"></Avatar>
                            </Box>
                            <Box>
                                <Typography sx={{ fontSize: 19, fontWeight: 'bold' }}>
                                    Quick Tour
                                </Typography>
                                <Typography sx={{ fontSize: 17, fontWeight: 'bold', color: '#696969' }}>
                                    Discover Quickly, Explore Fully
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item sm={4}>
                        <Box onClick={() => navigateToApplycredit()} sx={{ display: 'flex', cursor: 'pointer', padding: 2, alignItems: 'center', gap: 3, borderRight: '1px solid #EBEBEB' }}>
                            <Box>
                                <Avatar sx={{ backgroundColor: '#DFE3E7', width: 72, border: '1px solid #DFE3E7', height: 72 }} src="/assets/icons/applyCredit.png"></Avatar>
                            </Box>
                            <Box>
                                <Typography sx={{ fontSize: 19, fontWeight: 'bold' }}>
                                    Apply for Credit
                                </Typography>
                                <Typography sx={{ fontSize: 17, fontWeight: 'bold', color: '#696969' }}>
                                    Step into Financial Freedom
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item sm={4}>
                        <Box onClick={() => navigateToOutlet()} sx={{ display: 'flex', cursor: 'pointer', padding: 2, alignItems: 'center', gap: 3 }}>
                            <Box>
                                <Avatar sx={{ bgcolor: 'red', width: 72, height: 72 }} src="/assets/icons/addOutlet1.jpeg"></Avatar>
                            </Box>
                            <Box>
                                <Typography sx={{ fontSize: 19, fontWeight: 'bold' }}>
                                    Add Outlets
                                </Typography>
                                <Typography sx={{ fontSize: 17, fontWeight: 'bold', color: '#696969' }}>
                                    Empowering Your Business Expansion
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <CategorySection selectedCategory={selectedCategory} onSelectCategory={onSelectCategory} />
            <VendorSection selectedCategory={selectedCategory} clearCategorySelection={clearCategorySelection} />
            <Box sx={{ padding: 3 }}>
                <Typography variant="h4">Prime Deals</Typography>
                <Box sx={{ display:'flex', justifyContent:'center' }}>
                    <CaraousalTwo isHome={true} />
                </Box>
            </Box>
        </Box>
    )
}

export default Home;