import React from 'react'
import {TextField} from '@mui/material';
import { getIn } from 'formik';



const CustomTextField = (props) => {
    const { formik, name, type, label, placeholder, inputProps, disabled,multiline, size, required, variant='outlined'} = props;
  return (
    <>
        <TextField
        multiline = {multiline ? true : false}
        variant={variant}
        label={label}
        size={size ? size :"medium"}
        required={required}
        type={type ? type : "text"}
        disabled={disabled ? true : false}
        name={name}
        value={getIn(formik.values, name)}
        onChange={formik.handleChange}
        placeholder={placeholder}
        fullWidth
        onBlur={formik.handleBlur}
        InputLabelProps={{shrink: !!getIn(formik.values, name)}}
        onKeyDown={(e) => {
          if (props.type==="number" && (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+")) {
            e.preventDefault()
          }
        }}
        error={
          getIn(formik.touched, name) && Boolean(getIn(formik.errors, name))
        }
        helperText={getIn(formik.touched, name) && getIn(formik.errors, name)}
        sx={{
          "& input[type=number]": {
            MozAppearance: "textfield",
          },
          "& input[type=number]::-webkit-outer-spin-button": {
            WebkitAppearance: "none",
            margin: 0,
          },
          "& input[type=number]::-webkit-inner-spin-button": {
            WebkitAppearance: "none",
            margin: 0,
          },
        }}
      />
    </>
  )
}

export default CustomTextField