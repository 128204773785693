import { Box, Container, Grid, Paper, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import VendorLogoImageComponent from '../../components/common/VendorLogoImageComponent'
import PrivateVendorOrderTable from './PrivateVendorOrderTable'
import { horecaApi, useCreateOrderForPrivateVendorMutation, usePrivateVendorInventoryUploadMutation } from '../../redux/api/api'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { addMoreItemsToPrivateVendor, addNewItemToPrivateVendor } from '../../redux/slices/orderSlice'
import ReviewOrderDialogue from './ReviewOrderDialogue'
import { openMessage } from '../../redux/slices/showMessageSlice'
import { useRouter } from '../../routes/hooks/useRouter'
import CustomButton from '../../components/common/CustomButton'
import { downloadExcel } from '../inventory/list'
import UploadFileIcon from '@mui/icons-material/UploadFile';
import Iconify from '../../components/common/Iconify'
import PageHeader from '../../components/common/PageHeader'

const CreateOrderForPrivateVendor = () => {

  const router = useRouter();
  const [reviewDialogue, setReviewDialogue] = useState(false);
  const dispatch = useDispatch();
  const { orgId, privateVendorId } = useParams();
  const [searchText, setSearchText] = useState('');
  const [getPrivateVendorOrderListDetails, { data: orderListData }] = horecaApi.endpoints.getPrivateVendorOrderListDetails.useLazyQuery();
  const [createOrderForPrivateVendor] = useCreateOrderForPrivateVendorMutation();
  const [downloadSampleTemplates] = horecaApi.endpoints.downloadSampleTemplates.useLazyQuery();
  const [privateVendorInventoryUpload] = usePrivateVendorInventoryUploadMutation();
  const items = useSelector((state) => state.order.privateVendor.items)

  const handleInputChange = (name, value, index) => {
    dispatch(addMoreItemsToPrivateVendor({ name, value, index }))
  };

  const handleSubmit = () => {
    dispatch(addNewItemToPrivateVendor([...items, { name: '', quantity: '', unit: '' }]));
  };

  const fetchData = async () => {
    await getPrivateVendorOrderListDetails({ vendorId: privateVendorId, customerId: orgId, searchText });
  }

  useEffect(() => {
    fetchData();
  }, [orgId, searchText])

  const OnReviewClose = () => {
    setReviewDialogue(false);
  }

  const createNewOrder = async () => {

    const orderListId = orderListData?.orderListDetails?.id

    const payload = orderListId ? {
      orderListId: orderListId,
      items: items.filter(item => item.quantity > 0)
    } : { items: items.filter(item => item.quantity > 0) };

    const result = await createOrderForPrivateVendor({ customerId: orgId, vendorId: privateVendorId, payload });
    if (result.data) {
      setReviewDialogue(false);
      dispatch(openMessage({ message: 'Order Created!', messageSeverity: 'success' }));
      router.push(`/org/${orgId}/my-vendors`);
      dispatch(addNewItemToPrivateVendor([]));
    } else if (result.error) {
      dispatch(openMessage({ message: result?.error?.data?.message, messageSeverity: 'error' }))
    } else {
      dispatch(openMessage({ message: 'Not Able to create an order', messageSeverity: 'error' }))
    }
  }

  const handelOpenReview = () => {
    setReviewDialogue(true);
  }

  const handleSampleDownload = async () => {
    const result = await downloadSampleTemplates({ orgType: 'private_vendor', templateType: 'sample_items' });
    if (result?.data) {
      downloadExcel(result?.data?.img)
      dispatch(openMessage({ message: 'Sample downloaded successfully!', messageSeverity: 'success' }))
    }
  }

  const handleUploadInventory = async (newFile) => {
    if (newFile) {
      const formData = new FormData();
      formData.append("file", newFile.target.files[0]);
      const result = await privateVendorInventoryUpload({
        payload: formData,
        customerId: orgId,
        vendorId: privateVendorId
      });
      if (result?.data) {
        dispatch(openMessage({ message: 'File uploaded successfully!', messageSeverity: 'success' }))
        fetchData();
        setTimeout(() => {
          fetchData();
        }, 3000)
      }
    }
  }

  const goBack = () => {
    router.back()
  }

  return (
    <Container maxWidth='xl'>
      <PageHeader
        handleBackClick={goBack}
        title={orderListData?.vendorDetails?.orgName}
        label1={'Sample Download'}
        onClick1={handleSampleDownload}
        startIcon1={<Iconify icon="mingcute:file-download-line" />}
        label2={'Upload'}
        variant2={"outlined"}
        startIcon2={<UploadFileIcon />}
        props2={{ component: "label", role: undefined, tabIndex: -1 }}
        visuallyHiddenInput2={true}
        handleVisuallyHiddenInput2={handleUploadInventory}
      />
      <Paper sx={{ padding: { xs: "1rem", sm: '2.5rem' } }}>
        <Grid container spacing={2} >
          <Grid item xs={12} md={6}>
            <VendorLogoImageComponent name={orderListData?.vendorDetails?.orgName} />
            <Typography variant='h6' sx={{ mt: '1rem' }}>Vendor Details</Typography>
            <Grid container >
              {
                [
                  { label: "Name", value: orderListData?.vendorDetails?.orgName },
                  { label: "Phone Number", value: orderListData?.vendorDetails?.phoneNumber },
                  { label: "Email", value: orderListData?.vendorDetails?.orgEmail },
                  { label: 'address', value: <span>{orderListData?.vendorDetails?.addresses[0]?.line1 ? `${orderListData?.vendorDetails?.addresses[0]?.landmark ? orderListData?.vendorDetails?.addresses[0]?.landmark + ", " : ""}${orderListData?.vendorDetails?.addresses[0]?.line1 ? orderListData?.vendorDetails?.addresses[0]?.line1 + ", " : ""}${orderListData?.vendorDetails?.addresses[0]?.line2 ? orderListData?.vendorDetails?.addresses[0]?.line2 + ", " : ""}${orderListData?.vendorDetails?.addresses[0]?.city ? orderListData?.vendorDetails?.addresses[0]?.city + ", " : ""}${orderListData?.vendorDetails?.addresses[0]?.state ? orderListData?.vendorDetails?.addresses[0]?.state + ", " : ""}${orderListData?.vendorDetails?.addresses[0]?.pincode ?? ""}` : ""}</span> }
                ].map((vendorDet, index) => (
                  <>
                    <Grid item xs={6} container key={index}>
                      <Typography sx={{ color: "text.disabled" }} variant="subtitle2" gutterBottom>
                        {vendorDet.label}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} padding={'auto'} key={index}>
                      <Typography variant="body2" gutterBottom>{vendorDet.value}</Typography>
                    </Grid>
                  </>
                ))
              }
            </Grid>
          </Grid>
        </Grid>
        <Box sx={{ mt: '1rem' }}>
          <PrivateVendorOrderTable
            title='Items'
            items={items}
            handleQuantityChange={handleInputChange}
            isSearch={true}
            searchText={searchText}
            setSearchText={setSearchText}
          />
        </Box>
        <Stack direction={'row'} justifyContent={'center'} gap={'1rem'} sx={{ mt: '1rem' }}>
          <CustomButton variant='contained' onClick={handleSubmit} label={"Add More Items"} />
          <CustomButton variant='outlined' onClick={handelOpenReview} label={"Review"} disabled={items.some(item => item.quantity > 0) ? false : true} />
        </Stack>
      </Paper>
      <ReviewOrderDialogue
        open={reviewDialogue}
        handelCloseDialogue={OnReviewClose}
        createOrder={createNewOrder}
        content={
          <PrivateVendorOrderTable
            title='Items'
            items={items.filter(item => item.quantity > 0)}
            handleQuantityChange={handleInputChange}
            isRead={true}
          />
        }
      />
    </Container>
  )
}

export default CreateOrderForPrivateVendor