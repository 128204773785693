import { LoadingButton } from "@mui/lab"
import { CircularProgress, useTheme } from "@mui/material"
import { VisuallyHiddenInput } from "../../pages/inventory/list"

export default function CustomButton({ onClick = (e) => { }, startIcon, endIcon, loading, variant = "contained", label, sx, size, disabled, color, width = "fit-content", height = "fit-content", props, type, visuallyHiddenInput = false, handleVisuallyHiddenInput = (e) => { } }) {
    const theme = useTheme()

    return (
        <>
            <LoadingButton
                onClick={onClick}
                type={type}
                startIcon={startIcon}
                endIcon={endIcon}
                loading={loading}
                loadingPosition={startIcon ? "start" : endIcon ? "end" : "center"}
                // loadingIndicator={<CircularProgress color="primary" size={16} />}
                loadingIndicator={<svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24"><path fill={theme.palette.primary.main} d="M12,23a9.63,9.63,0,0,1-8-9.5,9.51,9.51,0,0,1,6.79-9.1A1.66,1.66,0,0,0,12,2.81h0a1.67,1.67,0,0,0-1.94-1.64A11,11,0,0,0,12,23Z"><animateTransform attributeName="transform" dur="0.8s" repeatCount="indefinite" type="rotate" values="0 12 12;360 12 12" /></path></svg>}
                variant={variant}
                size={size}
                disabled={disabled}
                color={color}
                {...props}
                sx={{
                    ...sx,
                    cursor: loading ? "progress" : "pointer",
                    width: width,
                    height: height,
                    whiteSpace: "nowrap",
                    textTransform: 'capitalize'
                }}
            >
                {label}
                {visuallyHiddenInput && <VisuallyHiddenInput type="file" onChange={handleVisuallyHiddenInput} />}
            </LoadingButton>
        </>
    )
}