import React, { useState } from "react";
import { Box, styled, Typography, Button, useTheme, Container } from "@mui/material";
import { hero1, hero2, homeLanding } from "../../assets/images/landing";
import { bannerD1, bannerD2, bannerD3 } from "../../assets/banners";
import FrequentlyAskedQuestions from "./FrequentlyAskedQuestions";
import TestimonialCarousel from "./TestiMonials";
import BuyJustAnything from "./BuyJustAnything";
import { Link } from "react-router-dom";
import HowItWorks from "./HowItWorks";
import PopularBrands from "./PopularBrands";
import { motion, AnimatePresence } from "framer-motion";
import PopularVendors from "./PopularVendors";
import TalkToUs from "./TalkToUs";
import Carasousal from "../../components/caraousal/Caraousal";
import CaraousalTwo from "../../components/caraousal/caraousalWithTwoBanner/CaraousalTwo";
import TrendingProducts from "./TrendingProducts";
import CategoryProducts from "./CategoryProducts";
import useResponsiveStyles from "../../hooks/useResponsiveStyles";
import LoginDialogue from "../auth/sections/LoginDialogue";
import useLocalStorage from "../../hooks/useLocalStorage";
import { useLocalStorageContext } from "../../hooks/LocalStorageProvider";
import BrandSection from "./brands/BrandSection";
import ShowMessage from "../../components/common/ShowMessage";

// Custom styled components
const LandingContent = styled(Box)(({ theme }) => ({
  position: "absolute",
  color: "#fff",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  width: "100%",
  zIndex: 2, // Ensure the content is above the image
}));

const LandingInfoHeading = styled(Typography)(({ theme }) => ({
  fontSize: "22px",
  fontWeight: 700,
  display: "flex",
  justifyContent: "center",
  marginBottom: theme.spacing(1),
}));

const LandingInfoSubheading = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  fontSize: "56px",
  fontWeight: 700,
  wordWrap: "break-word",
  wordBreak: "break-word",
  width: "700px",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  width: "400px",
  // borderRadius: '24px',
  // fontFamily: 'OurLexend-Regular, Helvetica, sans-serif',
  fontWeight: 500,
  marginTop: theme.spacing(5),
}));

const LandingImage = styled("img")({
  position: "absolute",
  height: "100%",
  width: "100%",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  color: "transparent",
  objectFit: "fill",
  zIndex: 1,
  // clipPath: 'ellipse(200rem 100% at top center)'
});

const MainLanding = () => {
  const [currentImage, setCurrentImage] = React.useState(0);
  const responsive = useResponsiveStyles();
  const [open, setOpen] = useState(false);
  const { location } = useLocalStorageContext();
  const onCloseClick = () => {
    setOpen(false);
  };
  // React.useEffect(() => {
  //   const interval = setInterval(() => {
  //     setCurrentImage((prev) => (prev === images.length - 1 ? 0 : prev + 1));
  //   }, 3000); // Change image every 5 seconds
  //   return () => clearInterval(interval);
  // }, []);

  const images = [bannerD1, bannerD2, bannerD3];

  return (
    <Box sx={{ paddingX: responsive.isMobile ? 1 : 10, paddingTop: responsive.isMobile ? 3 : 6 }}>
      {/* <Box sx={{ height: "70vh", position: "relative", overflow: "hidden", display: "flex", alignItems: "center", color: "#fff" }}> */}
      {/* <Box sx={{ width: '100%', height: '100%', boxShadow: 'inset 0 4px 4px rgba(0,0,0,.4)', position: 'absolute', top: 0, left: 0 }} /> */}
      {/* <AnimatePresence>
        {images.map((image, index) => (
          index === currentImage && (
            <motion.div
              key={index}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 1 }}
              style={{ width: '100%', height: '100%', top: 0, left: 0 }}
            >
              <LandingImage src={image} alt={`Landing image ${index + 1}`} />
            </motion.div>
          )
        ))}
      </AnimatePresence>
   */}
      {/* <LandingContent>
        <LandingInfoHeading>
          14 categories • 2000+ products
        </LandingInfoHeading>
        <LandingInfoSubheading>
          All your restaurant needs delivered next day
        </LandingInfoSubheading>
        <Box width="100%" className="landingButton">
          <StyledButton variant="contained" size='large' component={Link} to='/login'>
            Shop now
          </StyledButton>
        </Box>
      </LandingContent> */}
      <Box sx={{ display:'flex', justifyContent:'center' }}>
        <Carasousal />
      </Box>
      <BuyJustAnything setLoginOpen={setOpen}/>
      <PopularVendors location={location}/>
      {
        responsive.isMobile &&
        <Box sx={{ padding: 0, paddingBottom: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
          <Typography variant="h3">Prime Deals</Typography>
          <CaraousalTwo isHome={true} />
        </Box>
      }
      {
        !responsive.isMobile &&
        <Box sx={{ padding: 10, paddingY: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography variant="h3">Prime Deals</Typography>
          <CaraousalTwo isHome={true} />
        </Box>
      }
      <BrandSection setLoginOpen={setOpen}/>
      <HowItWorks />
      <TrendingProducts setLoginOpen={setOpen} location={location}/>
      <CategoryProducts setLoginOpen={setOpen} location={location}/>
      {/* <TestimonialCarousel/> */}
      <TalkToUs />
      <FrequentlyAskedQuestions />
      {open && <LoginDialogue open={open} handelCloseDialogue={onCloseClick} />}
      <ShowMessage/>
    </Box>
  );
};

export default MainLanding;
