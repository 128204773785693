import { Avatar, AvatarGroup, Divider, Grid, Paper, Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { CircularProgressWithIcon } from '../../common/CircularProgressWithIcon'
import { useDispatch, useSelector } from 'react-redux'
import { useResponsive } from '../../../../hooks/useResponsive'
import { getProgressValue } from '../../common/utils'
import { setSummaryFilter } from '../../../../redux/slices/orderListSlice'
import CustomLogo from '../../common/CustomLogo'

export default function OrderListSummary({ categoryLogos }) {
    const orderList = useSelector((state) => state.orderList.orderList)

    // const summaryFilter = useSelector((state) => state.orderList.summaryFilter)

    // const [summary, setSummary] = useState([])

    const dispatch = useDispatch()

    const smDown = useResponsive("down", "sm")

    const onlyMd = useResponsive('only', 'md')

    const onlyLg = useResponsive('only', 'lg')

    const onlySm = useResponsive('only', 'sm')

    // useEffect(() => {
    //     if (orderList) {
    //         setSummary([
    //             {
    //                 id: "totalOrderLists",
    //                 icon: <TotalLogo width={"2rem"} height={"2rem"} color={"#1fc1dd"} />,
    //                 progressColor: "#86d8e7",
    //                 progressValue: getProgressValue({ totalValue: orderList?.Total?.count, value: orderList?.Total?.count }),
    //                 title: "Total",
    //                 quantity: orderList?.Total?.count,
    //                 total: "13825.05",
    //                 onlyIcons: false,
    //                 srcList: [],
    //                 divider: smDown ? false : true,
    //                 setFilterButtonsStatus: summaryFilter?.totalOrderLists
    //             },
    //             {
    //                 id: "draftedOrderLists",
    //                 icon: <DraftLogo width={"2rem"} height={"2rem"} color={"#637381"} color1={"#b1b9c0"} />,
    //                 progressColor: "#c9ced3",
    //                 progressValue: getProgressValue({ totalValue: orderList?.Total?.count, value: orderList?.Drafted?.count }),
    //                 title: "Draft",
    //                 quantity: orderList?.Drafted?.count,
    //                 total: "1325.05",
    //                 onlyIcons: false,
    //                 srcList: [],
    //                 divider: smDown ? false : true,
    //                 setFilterButtonsStatus: summaryFilter?.draftedOrderLists
    //             },
    //             {
    //                 id: "scheduledOrderLists",
    //                 icon: <ScheduledLogo width={"2rem"} height={"2rem"} color={"#ffab00"} />,
    //                 progressColor: "#f6d288",
    //                 progressValue: getProgressValue({ totalValue: orderList?.Total?.count, value: orderList?.Scheduled?.count }),
    //                 title: "Scheduled",
    //                 quantity: orderList?.Scheduled?.count,
    //                 total: "13825.05",
    //                 onlyIcons: false,
    //                 srcList: [],
    //                 divider: smDown || categoryLogos?.length < 1 ? false : true,
    //                 setFilterButtonsStatus: summaryFilter?.scheduledOrderLists
    //             }
    //         ])
    //     }
    // }, [orderList])

    return (
        <>
            <Grid
                component={Paper}
                container
                columns={16}
                gap={2}
                sx={{
                    padding: "16px"
                }}
            >
                <Grid
                    item
                    sx={{
                        '&:hover': { cursor: "pointer" }
                    }}
                    id={"totalOrderLists"}
                    onClick={(e) => {
                        dispatch(setSummaryFilter(e.currentTarget.id))
                    }}
                >
                    <Stack
                        direction="row"
                        spacing={2}
                    >
                        <CircularProgressWithIcon
                            color={"#86d8e7"}
                            value={getProgressValue({ totalValue: orderList?.Total?.count, value: orderList?.Total?.count }) ?? 100}
                            icon={<TotalLogo width={"2rem"} height={"2rem"} color={"#1fc1dd"} />}
                        />
                        <Stack direction="column">
                            <Typography variant={"subtitle1"} noWrap gutterBottom>{"Total"}</Typography>
                            <Typography variant={"body2"} noWrap sx={{ color: "text.disabled" }}>{`${orderList?.Total?.count ?? 0} Order Lists`}</Typography>
                        </Stack>
                    </Stack>
                </Grid>

                <Divider
                    orientation="vertical"
                    flexItem
                    variant="middle"
                    sx={{
                        margin: "0px",
                        borderWidth: "0px thin 0px 0px",
                        borderColor: "rgba(145, 158, 171, 0.2)",
                        height: "auto",
                        alignSelf: "stretch",
                        borderStyle: "dashed",
                        display: {
                            xs: "none",
                            sm: "block"
                        }
                    }}
                />

                <Grid
                    item
                    id={"draftedOrderLists"}
                    sx={{
                        '&:hover': { cursor: "pointer" }
                    }}
                    onClick={(e) => {
                        dispatch(setSummaryFilter(e.currentTarget.id))
                    }}
                >
                    <Stack
                        direction="row"
                        spacing={2}
                    >
                        <CircularProgressWithIcon
                            color={"#c9ced3"}
                            value={getProgressValue({ totalValue: orderList?.Total?.count, value: orderList?.Drafted?.count }) ?? 100}
                            icon={<DraftLogo width={"2rem"} height={"2rem"} color={"#637381"} color1={"#b1b9c0"} />}
                        />
                        <Stack direction="column">
                            <Typography variant={"subtitle1"} noWrap gutterBottom>{"Draft"}</Typography>
                            <Typography variant={"body2"} noWrap sx={{ color: "text.disabled" }}>{`${orderList?.Drafted?.count ?? 0} Order Lists`}</Typography>
                        </Stack>
                    </Stack>
                </Grid>

                <Divider
                    orientation="vertical"
                    flexItem
                    variant="middle"
                    sx={{
                        margin: "0px",
                        borderWidth: "0px thin 0px 0px",
                        borderColor: "rgba(145, 158, 171, 0.2)",
                        height: "auto",
                        alignSelf: "stretch",
                        borderStyle: "dashed",
                        display: {
                            xs: "none",
                            sm: "block"
                        }
                    }}
                />

                {/* <Grid
                    item
                    sx={{
                        '&:hover': { cursor: "pointer" }
                    }}
                    id={"scheduledOrderLists"}
                    onClick={(e) => {
                        dispatch(setSummaryFilter(e.currentTarget.id))
                    }}
                >
                    <Stack
                        direction="row"
                        spacing={2}
                    >
                        <CircularProgressWithIcon
                            color={"#f6d288"}
                            value={getProgressValue({ totalValue: orderList?.Total?.count, value: orderList?.Scheduled?.count }) ?? 100}
                            icon={<ScheduledLogo width={"2rem"} height={"2rem"} color={"#ffab00"} />}
                        />
                        <Stack direction="column" >
                            <Typography variant={"subtitle1"} noWrap gutterBottom>{"Scheduled"}</Typography>
                            <Typography variant={"body2"} noWrap sx={{ color: "text.disabled" }}>{`${orderList?.Scheduled?.count ?? 0} Order Lists`}</Typography>
                        </Stack>
                    </Stack>
                </Grid> */}

                <Divider
                    orientation="vertical"
                    flexItem
                    variant="middle"
                    sx={{
                        margin: "0px",
                        borderWidth: "0px thin 0px 0px",
                        borderColor: "rgba(145, 158, 171, 0.2)",
                        height: "auto",
                        alignSelf: "stretch",
                        borderStyle: "dashed",
                        display: {
                            xs: "none",
                            sm: "none",
                            md: "block"
                        }
                    }}
                />

                <Grid
                    item
                    sx={{
                        flex: 1
                    }}
                >
                    <AvatarGroup max={smDown ? 4 : onlySm ? 12 : onlyMd ? 8 : onlyLg ? 10 : 20} spacing={"medium"} variant='rounded'
                        renderSurplus={(surplus) => (
                            <Avatar
                                variant="rounded"
                                sx={{
                                    bgcolor: (theme) => theme.palette.primary.lighter,
                                    color: (theme) => theme.palette.primary.main,
                                    cursor: 'pointer'
                                }}
                            >
                                +{surplus}
                            </Avatar>
                        )}
                        sx={{
                            '& .MuiAvatar-root': {
                                width: `${48 / 16}rem` ?? '3.5rem',
                                height: `${48 / 16}rem` ?? '3.5rem'
                            },
                            justifyContent: {
                                xs: "flex-end",
                                md: "flex-start"
                            }
                        }}
                    >
                        {categoryLogos?.length > 0 && categoryLogos?.map((src, index) => (
                            <CustomLogo key={index} size={48} src={src.parent_category_image ?? null} title={src.parent_category ?? ""} />
                        ))}
                    </AvatarGroup>
                </Grid>
            </Grid >
        </>
    )
}

function TotalLogo({ width, height, color }) {
    return (
        <svg aria-hidden="true" role="img" width={width ?? "2rem"} height={height ?? "2rem"} viewBox="0 0 24 24">
            <path fill={color ?? "#1fc1dd"} d="M7.245 2h9.51c1.159 0 1.738 0 2.206.163a3.045 3.045 0 0 1 1.881 1.936C21 4.581 21 5.177 21 6.37v14.004c0 .858-.985 1.314-1.608.744a.946.946 0 0 0-1.284 0l-.483.442a1.657 1.657 0 0 1-2.25 0a1.657 1.657 0 0 0-2.25 0a1.657 1.657 0 0 1-2.25 0a1.657 1.657 0 0 0-2.25 0a1.657 1.657 0 0 1-2.25 0l-.483-.442a.946.946 0 0 0-1.284 0c-.623.57-1.608.114-1.608-.744V6.37c0-1.193 0-1.79.158-2.27c.3-.913.995-1.629 1.881-1.937C5.507 2 6.086 2 7.245 2" opacity=".5"></path>
            <path fill={color ?? "#1fc1dd"} d="M7 6.75a.75.75 0 0 0 0 1.5h.5a.75.75 0 0 0 0-1.5zm3.5 0a.75.75 0 0 0 0 1.5H17a.75.75 0 0 0 0-1.5zM7 10.25a.75.75 0 0 0 0 1.5h.5a.75.75 0 0 0 0-1.5zm3.5 0a.75.75 0 0 0 0 1.5H17a.75.75 0 0 0 0-1.5zM7 13.75a.75.75 0 0 0 0 1.5h.5a.75.75 0 0 0 0-1.5zm3.5 0a.75.75 0 0 0 0 1.5H17a.75.75 0 0 0 0-1.5z"></path>
        </svg>
    )
}


function ScheduledLogo({ width, height, color }) {
    return (
        <svg aria-hidden="true" role="img" width={width ?? "2rem"} height={height ?? "2rem"} viewBox="0 0 24 24">
            <g fill="currentColor" fillRule="evenodd" clipRule="evenodd">
                <path fill={color ?? "#ffab00"} d="M17 17a5 5 0 1 0 0-10a5 5 0 0 0 0 10m.75-7a.75.75 0 0 0-1.5 0v1.846c0 .18.065.355.183.491l1 1.154a.75.75 0 0 0 1.134-.982l-.817-.943z"></path>
                <path fill={color ?? "#ffab00"} d="M1.25 7A.75.75 0 0 1 2 6.25h8a.75.75 0 0 1 0 1.5H2A.75.75 0 0 1 1.25 7m0 5a.75.75 0 0 1 .75-.75h6a.75.75 0 0 1 0 1.5H2a.75.75 0 0 1-.75-.75m0 5a.75.75 0 0 1 .75-.75h8a.75.75 0 0 1 0 1.5H2a.75.75 0 0 1-.75-.75" opacity=".5"></path>
            </g>
        </svg>
    )
}


function DraftLogo({ width, height, color, color1 }) {
    return (
        <svg aria-hidden="true" role="img" width={width ?? "2rem"} height={height ?? "2rem"} viewBox="0 0 24 24">
            <path fill={color ?? "#637381"} d="m11.51 2.26l-.01 2.835c0 1.097 0 2.066.105 2.848c.114.847.375 1.694 1.067 2.385c.69.691 1.538.953 2.385 1.067c.781.105 1.751.105 2.848.105h4.052c.013.155.022.321.028.5H22c0-.268 0-.402-.01-.56a5.322 5.322 0 0 0-.958-2.641c-.094-.128-.158-.204-.285-.357C19.954 7.494 18.91 6.312 18 5.5c-.81-.724-1.921-1.515-2.89-2.161c-.832-.556-1.248-.834-1.819-1.04a5.488 5.488 0 0 0-.506-.154c-.384-.095-.758-.128-1.285-.14z"></path>
            <path fill={color1 ?? "#b1b9c0"} d="M2 13.662V9.775C2 6.11 2 4.277 3.172 3.139C4.343 2 6.239 2 10.03 2c.591 0 1.068 0 1.47.015l.01.244l-.01 2.749v.18c0 1.059.003 1.995.105 2.755c.114.847.375 1.694 1.067 2.385c.69.691 1.538.953 2.385 1.067c.781.105 1.751.105 2.848.105h4.052c.013.155.022.321.028.5h.008c.007.357.007.765.007 1.238v1.106l-.012.006a4.704 4.704 0 0 0-.267.146a3.167 3.167 0 0 1-3.43 0a2.375 2.375 0 0 0-2.572 0a3.167 3.167 0 0 1-3.43 0a2.375 2.375 0 0 0-2.572 0a3.167 3.167 0 0 1-3.43 0a2.375 2.375 0 0 0-2.572 0c-.138.088-.206.133-.256.158c-.386.194-1.017.027-1.459-.29z" opacity=".5"></path>
            <path fill={color ?? "#637381"} d="M10 22h4c3.771 0 5.657 0 6.829-.933c1.096-.874 1.166-2.246 1.171-4.881l-.012.005a5.143 5.143 0 0 0-.267.12c-1.039.55-2.392.55-3.43 0a2.822 2.822 0 0 0-2.572 0c-1.039.55-2.392.55-3.43 0a2.822 2.822 0 0 0-2.573 0c-1.038.55-2.39.55-3.43 0a2.822 2.822 0 0 0-2.572 0a4.373 4.373 0 0 1-.255.129c-.386.159-1.017.022-1.459-.238c.005 2.625.077 3.993 1.171 4.865C4.343 22 6.23 22 10 22"></path>
        </svg>
    )
}