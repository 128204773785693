import React, { useEffect, useState } from "react";
import { Box, Button, Divider, Drawer, Grid, IconButton, Link, MenuItem, Stack, Typography } from "@mui/material";
import { styled } from "@mui/system";
import EnterQuantity from "./EnterQuantity";
import { useAddItemToCartMutation, useRemoveItemFromCartMutation, useUpdateCartItemQuantityMutation } from "../../redux/api/api";
import { openMessage } from "../../redux/slices/showMessageSlice";
import { useDispatch } from "react-redux";
import GetImageComponent from "../../components/common/GetImageComponent";
import { noSticker } from "../../assets/images/landing";
import AskForPrice from "../../components/common/AskForPrice";
import ViewBulkQuantityPrices from "../../components/common/ViewBulkQuantityPrices";
import useResponsiveStyles from "../../hooks/useResponsiveStyles";
import AddBoxIcon from '@mui/icons-material/AddBox';
import PlaylistAddCircleIcon from '@mui/icons-material/PlaylistAddCircle';
import PlaylistAddCheckCircleIcon from '@mui/icons-material/PlaylistAddCheckCircle';
import CartWarningDialog from "./CartWarningDialog";

const CatalogCardContainer = styled(Box)(({ theme }) => ({
  // marginBottom: '24px',
  padding: "8px 0",
  position: "relative",
  overflow: "hidden",
  display: "flex",
  boxShadow: "0 1px 4px rgba(28,28,28,.06)",
  borderRadius: "24px",
  flexDirection: "column",
  justifyContent: "flex-end",
  "& .CatalogCard_catalogCard__mGd27": {
    display: "flex",
    flexDirection: "column",
  },
  border: `1px solid ${theme.palette.mode === "dark" ? "rgba(145, 158, 171, 0.16)" : "rgba(145, 158, 171, 0.16)"}`,
  transition: "box-shadow 0.3s",
  "&:hover": {
    boxShadow: theme.palette.mode !== "dark" && "rgba(145, 158, 171, 0.16) 0px 20px 40px -4px",
    backgroundColor: theme.palette.mode === "dark" && theme.palette.background.paper,
  },
}));

const ProductCard = ({ data, customerId, vendorId, allCartItemsData, addToWishlist, wishList }) => {
  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState(1);
  const [openWarning, setOpenWarning] = useState(false);
  const [selectedBulkIndex, setSelectedBulkIndex] = useState(0);
  const { isMobile } = useResponsiveStyles();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [addItemToCart, { isLoading: addItemToCartLoading }] = useAddItemToCartMutation();
  const [updateCartItemQuantity] = useUpdateCartItemQuantityMutation();

  const [cartItemId, setCartItemId] = useState(allCartItemsData?.find((allCartData) => allCartData?.itemId == data?.id)?.id);

  const [quantity1, setQuantity1] = useState(
    allCartItemsData?.find((allCartData) => allCartData?.itemId == data?.id)
      ? allCartItemsData?.find((allCartData) => allCartData?.itemId == data?.id)?.quantity
      : 0
  );

  useEffect(() => {
    if (allCartItemsData && allCartItemsData.length > 0) {
      const _itemRec = allCartItemsData?.find((allCartData) => allCartData?.itemId == data?.id);
      setQuantity1(
        allCartItemsData?.find((allCartData) => allCartData?.itemId == data?.id)
          ? allCartItemsData?.find((allCartData) => allCartData?.itemId == data?.id)?.quantity
          : 0
      );
      if (_itemRec && _itemRec.bulkQtyId && data?.itemQuantityPricesMappingDetails.length > 0) {
        const _bulkIndex = data.itemQuantityPricesMappingDetails.findIndex(_obj => Number(_obj.id) == Number(_itemRec.bulkQtyId));
        setSelectedBulkIndex(_bulkIndex)
      }
    }
  }, [allCartItemsData]);
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setIsDrawerOpen(open);
  }

  const handleAddToCart1 = async () => {
    const vendorItem = allCartItemsData?.find((allCartData) => allCartData.vendorId != vendorId);
    if (vendorItem) {
      setOpenWarning(true);
      return;
    }
    const payload = {
      items: {
        itemId: data?.id,
        quantity: quantity,
        unit: data?.inventoryDataDump?.unit,
      },
      customerId: customerId,
      vendorId: vendorId,
    };

    const result = await addItemToCart({ payload });
    if (result?.data) {
      setQuantity(1);
      dispatch(openMessage({ message: "Items added", messageSeverity: "success" }));
    } else if (result?.error) {
      dispatch(openMessage({ message: result?.error?.data?.message, messageSeverity: "error" }));
    } else {
      dispatch(openMessage({ message: "Not able to add an itme to cart at this moment", messageSeverity: "error" }));
    }
  };

  const handleAddToCart = async (itemId, quantity, bulkId) => {
    const payload = {
      items: {
        itemId: data?.id,
        quantity: quantity,
        ...(bulkId ? { bulkQtyId: bulkId } : {}),
      },
      customerId: customerId,
      vendorId: vendorId,
    };

    await addItemToCart({ payload });
  };

  const handleAddToCart2 = async (itemId, quantity) => {
    await updateCartItemQuantity({ cartItemId: cartItemId, qty: quantity });
  };

  const addItem = async () => { };
  // const addItemQuantity = (row, quantity) => {
  //   setQuantity1(quantity1 + parseInt(quantity.itemQuantity));
  //   handleAddToCart(row?.itemId, +quantity?.itemQuantity + quantity1);
  // }
  const addItemQuantity = (itemId, quantity, bulkId) => {
    if (quantity1 + parseInt(quantity) > 1000) {
      dispatch(openMessage({ message: "Quantity cannot exceed 1000.", messageSeverity: "error" }));
    } else {
      setQuantity1(quantity1 + parseInt(quantity));
      handleAddToCart(itemId, quantity, bulkId);
    }
  }


  const [removeItemFromCart] = useRemoveItemFromCartMutation();

  const removeItem = async () => {
    await removeItemFromCart(allCartItemsData?.find((allCartData) => allCartData?.itemId == data?.id)?.id);
    setQuantity1(0);
  };
  if (isMobile) {
    return (
      <Box sx={{ flexGrow: 1, border: '.5px solid #d3d3d3', borderRadius: 2, backgroundColor: '#FFF', padding: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <GetImageComponent s3Key={data?.item_image_key} noSticker={noSticker} style={{ height: 80, width: 80 }} />
        <Box sx={{ width: '100%', flexGrow: 1, padding: 0.5, paddingBottom: 0, paddingTop: 2, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', justifyContent: 'end' }}>
            <IconButton onClick={() => { addToWishlist(data) }}>
              {
                wishList.find((_obj) => _obj.id == data.id) ?
                  <PlaylistAddCheckCircleIcon sx={{ color: '#2066D1' }} /> :
                  <PlaylistAddCircleIcon />
              }
            </IconButton>
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle2" color="textSecondary" sx={{ fontSize: 12 }}>{data.name}</Typography>
            <Typography variant="body2" color="textSecondary" sx={{ marginBottom: 1, fontSize: 12 }}>1 {data?.unit}</Typography>
          </Box>
          {data?.itemQuantityPricesMappingDetails?.length > 0 &&
            <ViewBulkQuantityPrices
              row={data}
              style={{ position: "absolute", right: "-60%", bottom: 0 }}
              handleAddToCart={addItemQuantity}
              quantity1={quantity1}
              setQuantity1={setQuantity1}
              selectedBulkIndex={selectedBulkIndex}
            />
            // <Link
            //   component="button"
            //   sx={{ paddingBottom: 1 }}
            //   variant="body2"
            //   onClick={() => {
            //     setIsDrawerOpen(true)
            //   }}
            // >
            //   <Typography sx={{ fontWeight: 'bold' }}>Bulk Offers</Typography>
            // </Link>
          }
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, paddingTop: 1, borderTop: '0.5px dashed rgb(181, 181, 181)' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="subtitle2" sx={{ fontSize: 12 }}>₹ {data.rate}</Typography>
              <EnterQuantity
                addItem={addItem}
                removeItem={removeItem}
                quantity={quantity}
                setQuantity={setQuantity}
                quantity1={quantity1}
                data={data}
                setQuantity1={setQuantity1}
                handleAddToCart1={handleAddToCart1}
                handleAddToCart={handleAddToCart}
              />
            </Box>
          </Box>
          <CartWarningDialog
            open={openWarning}
            onClose={() => setOpenWarning(false)}
            message={'Your cart already contains items from a different vendor. Please clear the cart before continuing.'}
          />
        </Box>
        <Drawer
          anchor="bottom"
          open={isDrawerOpen}
          onClose={toggleDrawer(false)}
          PaperProps={{
            sx: { borderRadius: '20px 20px 0 0', p: 2 }, // Optional: Customize drawer styling
          }}
        >
          <Box
            sx={{
              width: 'auto',
              padding: 1,
            }}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
          >
            <Typography variant="subtitle1" sx={{ color: '#E95F62', textAlign: 'center' }}>Available Bulk Offer(s)</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5, padding: 1 }}>
              {data?.itemQuantityPricesMappingDetails?.map((rate, index) => (
                <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center', alignItems: 'center' }}>
                  <Typography variant="subtitle2">
                    {"₹" + rate.itemPrice}
                  </Typography>
                  <Typography variant="subtitle2" sx={{ color: '#5A74FF' }}>
                    [{rate.itemQuantity + data.unit}]
                  </Typography>
                  <IconButton onClick={() => addItemQuantity(data, rate)}>
                    <AddBoxIcon sx={{ color: '#E95F62' }} />
                  </IconButton>
                  {/* <CustomButton sx={{ backgroundColor: '#E95F62', color:'#FFF' }} size='small' variant="contained" onClick={() => addItemQuantity(data, rate)} label={"Add"} /> */}
                </Box>
                // <MenuItem key={index} sx={{ minHeight: '24px' }} onClick={() => addItem(rate)}>
                //   <Typography variant="body2">
                //     {"₹" + rate.itemPrice} [{rate.itemQuantity + data.unit}]
                //   </Typography>
                //   {/* <IconButton> */}
                //   <Iconify icon="ic:round-add" />
                //   {/* </IconButton> */}
                // </MenuItem>
              ))}
            </Box>
          </Box>
        </Drawer>
      </Box>
    )
  }
  return (
    <CatalogCardContainer>
      <Stack alignItems={"center"} height={"120px"}>
        <GetImageComponent s3Key={data?.item_image_key} noSticker={noSticker} style={{ width: "120px", height: "120px" }} />
      </Stack>
      <Stack sx={{ height: "20px" }} />
      <Stack
        sx={{
          height: "80px",
          paddingLeft: "1.5rem",
          paddingRight: "1.5rem",
          position: "relative",
        }}
      >
        <Typography sx={{ overflow: "hidden", textOverflow: "ellipsis", WebkitLineClamp: 3 }} variant={"subtitle1"}>
          {data?.name}
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            color: "text.disabled",
            display: "inline-block",
            maxWidth: "150px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          1 {data?.unit}
        </Typography>
      </Stack>
      <Divider sx={{ borderStyle: "dashed", color: "text.disabled" }} />
      <Box display="flex" sx={{ paddingTop: 2 }}>
        <Box display="flex" flexDirection="column" pl={4} pr={2} sx={{ height: "80px", width: "100%" }} className="col-10">
          {data?.itemQuantityPricesMappingDetails?.length > 0 && (
            <ViewBulkQuantityPrices
              row={data}
              style={{ position: "absolute", right: "-60%", bottom: 0 }}
              handleAddToCart={handleAddToCart2}
              quantity1={quantity1}
              setQuantity1={setQuantity1}
            />
          )}
          <Box>
            <Box display="flex" justifyContent={"space-between"} alignItems={"center"} width={"100%"}>
              <Typography variant="subtitle2" component="span">
                {data?.rate ? `₹${data?.rate}` : <AskForPrice />}
              </Typography>
              <EnterQuantity
                addItem={addItem}
                removeItem={removeItem}
                quantity={quantity}
                setQuantity={setQuantity}
                quantity1={quantity1}
                data={data}
                setQuantity1={setQuantity1}
                handleAddToCart1={handleAddToCart1}
                handleAddToCart={handleAddToCart}
              />
            </Box>
          </Box>
        </Box>
        <CartWarningDialog
          open={openWarning}
          onClose={() => setOpenWarning(false)}
          message={'Your cart already contains items from a different vendor. Please clear the cart before continuing.'}
        />
      </Box>
    </CatalogCardContainer>
  );
};

export default ProductCard;
