import { Box, Container, Stack, Typography } from "@mui/material";
import GetImageComponent from "../../../components/common/GetImageComponent";
import { noSticker } from "../../../assets/images/landing";
import StarIcon from '@mui/icons-material/Star';
import VendorDeals from "../VendorDeals";
import { useState } from "react";
import CustomSearchbar from "../../../components/common/CustomSearchbar";
import { SelecteCategoriesComp } from "../../../layouts/common/Searchbar";
import VendorProducts from "../vendorProducts";
import CategorySection from "./CategorySection";
import SocialMediaShare from "../../../components/SocialMediaShare";

const ExploreProductNew = ({ vendorDetails, categoryDetails, cartData, avgRating }) => {
    const [selectedCategory, setSelectedCategory] = useState();
    const [searchText, setSearchText] = useState('');
    return (
        <Container sx={{ display: 'flex', justifyContent: 'center', paddingTop: 3 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}>
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', paddingBottom: 2 }}>
                    {/* <GetImageComponent
                        s3Key={vendorDetails?.org_logo_key}
                        noSticker={noSticker}
                        style={{
                            opacity: 1,
                            width: "200px", height: "150px",
                            willChange: "transform, opacity",
                            border: '1px solid #ECECED',
                            borderRadius: 10,
                            filter: "unset",
                            transition: "opacity 0.25s ease 0s, transform 0.25s ease 0s",
                        }}
                    /> */}
                    <Box sx={{ display: 'flex', width:'100%', gap: 6, alignItems:'flex-start' }}>
                        <Box sx={{ display: 'flex', alignItems:'center', gap: 2 }}>
                            <Typography variant="h3">{vendorDetails?.org_name}</Typography>
                            <SocialMediaShare title={'Check out this vendor!'}/>
                        </Box>
                        <Box sx={{ padding: 1, borderRadius: 2, background: 'linear-gradient(rgb(255, 255, 255) -6.71%, rgb(235, 235, 242) 56.19%, rgb(223, 223, 231) 106.56%)' }}>
                            <Box sx={{ backgroundColor: '#FFF', border: '1px solid rgba(2, 6, 12, 0.15)', borderRadius: 1, padding: 1.5, display: 'flex', flexDirection: 'column', gap: 1 }}>
                                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                    <Box sx={{ display: 'flex', gap: 1, backgroundColor: '#0C5120', padding: 1, paddingY: 0.2, borderRadius: 1, alignItems: 'center' }}>
                                        <Typography sx={{ color: '#FFF', fontSize: 14, fontWeight: 'bold' }}>{avgRating}</Typography>
                                        <StarIcon sx={{ color: 'white', fontSize: 16 }} />
                                    </Box>
                                    <Box sx={{ display: 'flex', gap: 0.5 }}>
                                        <Typography variant="subtitle2">(50+ ratings)</Typography>
                                    </Box>
                                </Box>
                                <Typography variant="subtitle2" sx={{ fontWeight: 'medium', color: '#FF7442' }}>
                                    <span style={{ textDecoration: 'underline', fontWeight: 'bold' }}>72</span> successful deliveries till now.
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                {/* <VendorDeals vendorDetails={vendorDetails} /> */}
                {/* <CustomSearchbar
                placeholder={"Search items..."}
                text={searchText}
                handleTextChange={(e) => setSearchText(e.currentTarget.value)}
                handleTextClear={(e) => setSearchText("")}
                sx={{ width: { xs: "100%", sm: "70%" }, mb: "1rem" }}
            /> */}
                <Stack
                    direction="row"
                    gap={2}
                    sx={{
                        flexWrap: "wrap",
                        display: "flex",
                        "&::-webkit-scrollbar": {
                            display: "none",
                        },
                    }}
                >
                    {/* {!searchText &&
                    categoryDetails?.map((category, index) => (
                            <SelecteCategoriesComp
                                cat={category}
                                handleClick1={handelSelectCategory}
                                //handleDelete={handelRemoveCategory}
                                index={`${index}category`}
                                key={index}
                                selectedCategories={[activeParentCategory]}
                            />
                        ))} */}
                    <CategorySection vendorDetails={vendorDetails} categoryDetails={categoryDetails} cartData={cartData} />
                </Stack>

            </Box>
        </Container>
    )
}

export default ExploreProductNew;