import { Box, Button, Checkbox, Container, FormControl, Grid, Icon, IconButton, InputAdornment, InputBase, InputLabel, ListItemText, Menu, MenuItem, OutlinedInput, Paper, Select, Stack, Tab, Table, TableBody, TableContainer, TablePagination, Tabs, Typography, alpha, styled } from "@mui/material";
import { useEffect, useState } from "react";
import SearchIcon from '@mui/icons-material/Search';
import { faker } from '@faker-js/faker';
import { sample } from 'lodash';
import moment from "moment";
import Iconify from "../../../components/common/Iconify";
import IosShareIcon from '@mui/icons-material/IosShare';
import CustomTableHead from "../../../views/list/table-head";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ScrollBar from "../../../components/common/scroll-bar/ScrollBar";
import TableEmptyRows from "../../../views/list/table-empty-rows";
import TableNoData from "../../../views/list/table-no-data";
import CustomTableRow from "../../../views/list/table-row";
import { emptyRows, getComparator } from "../../../views/list/utils";
import { horecaApi, useGetAllVendorItemsMutation, useUpdateBillPriceMutation, useUpdateRateMutation, useUploadInventoryFileMutation } from "../../../redux/api/api";
import { MuiFileInput } from "mui-file-input";
import { useNavigate, useParams } from "react-router-dom";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CustomBreadCrumb from "../../../components/common/CustomBreadCrumb";
import CustomButton from "../../../components/common/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { openMessage } from "../../../redux/slices/showMessageSlice";
import { base64ToArrayBuffer } from "../../../utils/base64toArray";
import CustomSearchbar from "../../../components/common/CustomSearchbar";
import PageHeader from "../../../components/common/PageHeader";
import TableLoadingData from "../../../components/common/TableLoadingData";
import { setCurrSelectedPage, setRowsPerPage } from "../../../redux/slices/appReducer";


export const downloadExcel = (excelData) => {

    var byteArray = base64ToArrayBuffer(excelData);
    var a = window.document.createElement("a");
    a.href = window.URL.createObjectURL(
        new Blob([byteArray], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
    );

    a.download = "sample.xlsx";

    document.body.appendChild(a);

    a.click();
};


export const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const InventoryList = (props) => {

    const dispatch = useDispatch();
    const { orgId } = useParams();
    const navigate = useNavigate();
    const [uploadInventoryFile] = useUploadInventoryFileMutation();
    const [getAllVendorItems, { isLoading: getAllVendorItemsLoading }] = useGetAllVendorItemsMutation();
    const [downloadSampleTemplates] = horecaApi.endpoints.downloadSampleTemplates.useLazyQuery();
    const [inventoryData, setInventoryData] = useState([])

    // const [page, setPage] = useState(0);
    const page = useSelector((state)=>state.app.currSelectedPage.inventoryPage)

    const [order, setOrder] = useState('asc');

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState('name');
    const [totalRecords, setTotalRecords] = useState(0);

    const [searchText, setSearchText] = useState('');

    const rowsPerPage = useSelector((state)=>state.app.rowsPerPageData.inventoryPage)

    const [updateRate] = useUpdateRateMutation();
    const [updateBillPrice] = useUpdateBillPriceMutation();

    const [stockStatusFiltered, setstockStatusFiltered] = useState([]);
    const [publishFiltered, setPublishFiltered] = useState([]);

    const [uploadAnchor, setUploadAnchor] = useState(null)

    const statusTabs = [
        {
            key: 'confirmed',
            lable: 'confirmed',
            textColor: '#006D9C',
            backgroundColor: '#DFF4F9',
        },
        {
            key: 'pending',
            lable: 'pending',
            textColor: '#637381',
            backgroundColor: '#EEEFF1',
        },
    ];


    useEffect(() => {
        fetchData();
    }, [page, rowsPerPage, searchText, publishFiltered]);

    const fetchData = async () => {
        const payload = {
            page: page,
            per_page: rowsPerPage,
            filter_by: publishFiltered.length == 1 ? publishFiltered.toString() : '',
            search_text: searchText
        }
        const result = await getAllVendorItems({
            payload: { ...payload },
            orgId: orgId,
        }, true)
        const _inventory = result?.data?.data?.map((_rec, index) => {
            return {
                ..._rec,
                avatarUrl: `/assets/images/avatars/avatar_${index + 1}.jpg`,
                name: _rec.name,
                type: _rec.product_type,
                date: moment(_rec.createdAt).format('YYYY-MM-DD HH:mm:ss'),
                stockDesc: _rec.quantity || 0,
                //stockDesc: sample(['out of stock', '72 in stock', '10 low stock']),
                //stockStatus: sample(['OUT_OF_STOCK', 'LOW_STOCK', 'IN_STOCK']),
                price: _rec.rate,
                publish: _rec.mapping_status,
            }
        })
        setTotalRecords(result.data.count)
        setInventoryData(_inventory);
    }



    const handleSort = (event, id) => {
        const isAsc = orderBy === id && order === 'asc';
        if (id !== '') {
            setOrder(isAsc ? 'desc' : 'asc');
            setOrderBy(id);
        }
    };
    function applyFilter({ inputData, comparator, filterName }) {
        const stabilizedThis = inputData.map((el, index) => [el, index]);

        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });

        inputData = stabilizedThis.map((el) => el[0]);

        if (filterName) {
            inputData = inputData.filter(
                (user) => user.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
            );
        }

        return inputData;
    }
    const handleChangePage = (event, newPage) => {
        // setPage(newPage);
        dispatch(setCurrSelectedPage({name: 'inventoryPage', value: newPage}))
    };

    const handleChangeRowsPerPage = (event) => {
        // setPage(0);
        dispatch(setCurrSelectedPage({name: 'inventoryPage', value: 0}))
        // setRowsPerPage(parseInt(event.target.value, 10));
        dispatch(setRowsPerPage({name: 'inventoryPage', value: parseInt(event.target.value, 10)}))
    };


    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = inventoryData.map((n) => n.order);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    };
    const handleFilterByName = (event) => {
        // setPage(0);
        dispatch(setCurrSelectedPage({name: 'inventoryPage', value: 0}))
        setSearchText(event.target.value);
    };

    const onStockStatusChange = (event) => {
        const {
            target: { value },
        } = event;
        setstockStatusFiltered(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const onPublishChange = (event) => {
        const {
            target: { value },
        } = event;
        setPublishFiltered(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const uploadInventory = async (newFile) => {
        if (newFile) {
            const formData = new FormData();
            formData.append("file", newFile.target.files[0]);
            const result = await uploadInventoryFile({
                payload: formData,
                orgId: orgId,
            });
            if (result) {
                dispatch(openMessage({ message: 'File uploaded successfully!', messageSeverity: 'success' }))
                setTimeout(() => {
                    fetchData();
                }, 2000)
            }
        }
    }



    const uploadUpdatedPrice = async (newFile) => {
        if (newFile) {
            const formData = new FormData();
            formData.append("file", newFile.target.files[0]);
            const result = await updateBillPrice({ orgId, payload: formData });
            if (result?.data) {
                dispatch(openMessage({ message: 'Price upldated successfully!', messageSeverity: 'success' }))
                setTimeout(() => {
                    fetchData();
                }, 2000)
            }
        }
    }


    const handleEdit = (recId) => {
        navigate(`${recId}/details`);
    }

    const notFound = !inventoryData.length && !!searchText;



    const sampleDownload = async () => {
        const result = await downloadSampleTemplates({ orgType: 'vendor', templateType: 'sample_items' });
        if (result?.data) {
            downloadExcel(result?.data?.img)
            dispatch(openMessage({ message: 'Sample downloaded successfully!', messageSeverity: 'success' }))
        }
    }

    const onPriceChange = (value, itemId) => {
        updateRate({ itemId: itemId, orgId, payload: { rate: value } })
    }

    return (<>
        <Container maxWidth="xl">
            <PageHeader
                title={"Inventory"}
                // linkArray={[
                //     { title: "Dashboard", path: `/` },
                //     { title: "Inventory", },
                // ]}
                label1={"Sample Download"}
                variant1="contained"
                onClick1={sampleDownload}
                startIcon1={<Iconify icon="mingcute:file-download-line" />}
                props1={{ component: "label", role: undefined, tabIndex: -1 }}
                label2={"Upload"}
                variant2={"outlined"}
                startIcon2={<UploadFileIcon />}
                onClick2={(e) => setUploadAnchor(e.currentTarget)}
                endIcon2={<Iconify icon="iconamoon:arrow-down-2-duotone" />}
                sx2={{
                    '& .MuiButton-endIcon': {
                        transform: Boolean(uploadAnchor) && 'rotate(-180deg)',
                        transition: 'transform 0.3s ease-in-out',
                    },
                }}
            />

            <Box sx={{ width: '100%' }} component={Paper}>
                <Box sx={{ padding: 2, paddingY: 2 }}>
                    <Grid container>
                        <Grid item sm={2.5}>
                            <Box sx={{ width: '95%' }}>
                                <FormControl fullWidth>
                                    <InputLabel id="publish-status-label">Status</InputLabel>
                                    <Select
                                        labelId="publish-status-label"
                                        id="publish-status"
                                        multiple
                                        value={publishFiltered || []}
                                        IconComponent={KeyboardArrowDownIcon}
                                        // sx={{
                                        //     height: 50,
                                        //     borderRadius: 1,
                                        //     backgroundColor: '#FFF',
                                        //     '& .MuiOutlinedInput-notchedOutline': {
                                        //         borderColor: '#ABAAAE',
                                        //     },
                                        //     '& .MuiInputLabel-root': {
                                        //         color: '#9EA0A3',
                                        //         fontSize: 14
                                        //     },
                                        //     '& .MuiInputBase-input': {
                                        //         color: '#212B36',
                                        //         fontSize: 15
                                        //     },
                                        // }}
                                        onChange={(e) => onPublishChange(e)}
                                        label="Status"
                                        renderValue={(selected) => selected.join(', ')}
                                        MenuProps={MenuProps}
                                    >
                                        {
                                            ['confirmed', 'pending'].map((name) => (
                                                <MenuItem key={name} value={name}>
                                                    <Checkbox checked={publishFiltered.indexOf(name) > -1} />
                                                    <ListItemText primary={name} />
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item sm={4.5}>
                            <CustomSearchbar
                                placeholder={"Search..."}
                                text={searchText}
                                handleTextChange={handleFilterByName}
                                handleTextClear={(e) => setSearchText("")}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <TableContainer >
                    <Table sx={{ minWidth: 750 }} size={'large'} stickyHeader>
                        <CustomTableHead
                            order={order}
                            rowSelctionRequired={false}
                            orderBy={orderBy}
                            rowCount={inventoryData.length}
                            numSelected={selected.length}
                            onRequestSort={handleSort}
                            onSelectAllClick={handleSelectAllClick}
                            headLabel={[
                                { id: 'Product', label: 'Product' },
                                { id: 'CreateAt', label: 'Create At' },
                                { id: 'Stock', label: 'Stock' },
                                { id: 'Price', label: 'Price' },
                                { id: 'Publish', label: 'Publish' },
                                { id: '' },
                            ]}
                        />
                        <TableBody>
                            {getAllVendorItemsLoading ?
                                <TableLoadingData colSpan={6} /> :
                                inventoryData?.length > 0 ?
                                    inventoryData
                                        //.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row) => (
                                            <CustomTableRow
                                                key={row.id}
                                                rowSelctionRequired={false}
                                                handleEditRecord={handleEdit}
                                                selectedId={row.id}
                                                rowData={[
                                                    { headerKey: 'product', valueObj: { value: row.name, productType: row.cf_parent_category, imageS3Key: row.item_image_key } },
                                                    { headerKey: 'date', valueObj: { value: row.date } },
                                                    { headerKey: 'stock', valueObj: { value: row.stockDesc, status: row.stockStatus } },
                                                    { headerKey: 'price', valueObj: { value: row.price, isTextField: true } },
                                                    { headerKey: 'publish', valueObj: { value: row.publish, statusTabs: [...statusTabs] } }
                                                ]}
                                                selected={selected.indexOf(row.order) !== -1}
                                                handleClick={(event) => handleClick(event, row.order)}
                                                onPriceChange={onPriceChange}
                                            />
                                        )) :
                                    <TableNoData query={searchText} colSpan={6} />
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    page={page}
                    component="div"
                    count={totalRecords}
                    rowsPerPage={rowsPerPage}
                    onPageChange={handleChangePage}
                    rowsPerPageOptions={[5, 10, 25]}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <Menu
                    id="simple-menu"
                    anchorEl={uploadAnchor}
                    keepMounted
                    open={Boolean(uploadAnchor)}
                    onClose={(e) => setUploadAnchor(null)}
                    sx={{
                        '& .MuiList-root': {
                            width: "130px",
                            padding: "0.5rem",
                        },
                    }}
                >
                    <CustomButton
                        label={"Inventory"}
                        props={{ component: "label", role: undefined, tabIndex: -1 }}
                        variant="text"
                        visuallyHiddenInput={true}
                        onClick={() => setUploadAnchor(null)}
                        handleVisuallyHiddenInput={(e) => uploadInventory(e)}
                        width="100%"
                        sx={{ justifyContent: "flex-start" }}
                    />
                    <CustomButton
                        label={"Update Price"}
                        props={{ component: "label", role: undefined, tabIndex: -1 }}
                        variant="text"
                        visuallyHiddenInput={true}
                        onClick={() => setUploadAnchor(null)}
                        handleVisuallyHiddenInput={(e) => uploadUpdatedPrice(e)}
                        width="100%"
                        sx={{ justifyContent: "flex-start" }}
                    />
                </Menu>
            </Box>
        </Container>
    </>);
}

export default InventoryList;