// import banner1 from "../../assets/images/banner1.png";
// import banner2 from "../../assets/images/banner2.png";
// import banner3 from "../../assets/images/banner3.png";

import { newBanner1 } from "../../../assets/banners";
import { newBanner2 } from "../../../assets/banners";
import { newBanner3 } from "../../../assets/banners";
import { newBanner4 } from "../../../assets/banners";

import { mobileBanner1 } from "../../../assets/banners";
import { mobileBanner2 } from "../../../assets/banners";
import { mobileBanner3 } from "../../../assets/banners";
import { mobileBanner4 } from "../../../assets/banners";
import { mobileBanner5 } from "../../../assets/banners";

//export const mImages = [mobileBanner1, mobileBanner2, mobileBanner3, mobileBanner4];
export const mImages = [mobileBanner1, mobileBanner2, mobileBanner3, mobileBanner4, mobileBanner5];

export const images = [[newBanner1, newBanner2], [newBanner3, newBanner4]];

export const imageByIndex = (index) => images[index % images.length];

export const mImageByIndex = (index) => mImages[index % mImages.length];

// export default imageByIndex
