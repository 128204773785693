import React from 'react';
import { Box, Container, Typography, styled, Link } from '@mui/material';
import { blackBg, h1Logo } from '../../assets/images/landing';
import { horecaLogo } from '../../assets/images';
import useResponsiveStyles from '../../hooks/useResponsiveStyles';
import { useNavigate } from 'react-router-dom';

const FooterWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: '#f4f6fb',
  padding: theme.spacing(4, 0),
  color: theme.palette.text.primary,
  marginTop: "auto",
}));

const FooterSection = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const FooterColumn = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    flex: '1',
  },
}));

const FooterItem = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

const FooterLink = styled(Link)(({ theme }) => ({
  color: 'black',
  display: 'flex',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

const Footer = () => {
  const navigate = useNavigate();
  const responsive = useResponsiveStyles();
  const handleClick = (_link) => {
    navigate('/company/' + _link);
  }
  return (
    <FooterWrapper>
      <Container sx={{ paddingX: responsive.isMobile ? 4 : 2 }}>
        <FooterSection>
          <FooterColumn>
            <FooterItem variant="h6">Company</FooterItem>
            <FooterItem>HCX Global Private Limited</FooterItem>
            <FooterItem className="text-cool-gray-600">
              C-003, Railway Station Complex,Sanpada, Navi Mumbai Maharashtra - 400705
            </FooterItem>
            <FooterItem className="text-cool-gray-600">CIN: U74900DL2015PTC286208
            </FooterItem>
            <FooterItem>
              <img
                alt="Phone icon"
                loading="lazy"
                width="20"
                height="20"
                decoding="async"
                src={'https://www.hyperpure.com/assets/images/phoneFooter.png'}
                style={{ color: 'transparent', marginRight: '8px' }}
              />
              +91-7710920002
            </FooterItem>
            <FooterItem>
              <img
                alt="Mail icon"
                loading="lazy"
                width="20"
                height="20"
                decoding="async"
                src={'https://www.hyperpure.com/assets/images/mailFooter.png'}
                style={{ color: 'transparent', marginRight: '8px' }}
              />
              sales@horeca1.com
            </FooterItem>
          </FooterColumn>

          <FooterColumn sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
            <FooterItem variant="h6">Quick links</FooterItem>
            <FooterLink component="button" onClick={() => handleClick('aboutUs')}>Horeca1 - All about us</FooterLink>
            <FooterLink component="button" onClick={() => handleClick('customerSupport')}>Customer Support</FooterLink>
            <FooterLink component="button" onClick={() => handleClick('vendorHub')}>Vendor Hub</FooterLink>
            <FooterLink component="button" onClick={() => handleClick('refund')}>Returns, Refund</FooterLink>
            <FooterLink component="button" onClick={() => handleClick('privacy')}>Privacy</FooterLink>
            <FooterLink component="button" onClick={() => handleClick('termsOfUse')}>Terms of Use</FooterLink>
            {/* <FooterLink href="https://www.horeca1.com/about-us" target="_blank">About Us</FooterLink>
            <FooterLink href="https://www.horeca1.com/contact" target="_blank" rel="noopener noreferrer">
            Contact Us
            </FooterLink>
            <FooterLink href="https://www.horeca1.com/customer-support" target="_blank">Customer Support</FooterLink> */}
          </FooterColumn>

          {/* <FooterColumn sx={{display:'flex', flexDirection:'column', gap:'0.5rem'}}>
            <FooterItem variant="h6">Follow us on</FooterItem>
            <FooterLink
              href="https://www.linkedin.com/company/hyperpurebyzomato/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Linkedin
            </FooterLink>
            <FooterLink
              href="https://www.youtube.com/@hyperpurebyzomato"
              target="_blank"
              rel="noopener noreferrer"
            >
              Youtube
            </FooterLink>
          </FooterColumn> */}

          <FooterColumn >
            <Box textAlign="center" sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem', alignItems: 'flex-end' }}>
              <img
                alt="HP icon"
                loading="lazy"
                width="50"
                height="50"
                decoding="async"
                src={h1Logo}
                style={{ color: 'transparent', marginBottom: '8px' }}
              />
              <img
                alt="Logo"
                loading="lazy"
                width="175"
                height="49"
                decoding="async"
                src={horecaLogo}
                style={{ color: 'transparent', marginBottom: '8px' }}
              />
              {/* <Box display="flex" justifyContent="center">
                <Box mr={2}>
                  <Link
                    href="https://play.google.com/store/apps/details?id=com.wotu.app"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      alt="Play Store"
                      loading="lazy"
                      width="135"
                      height="40"
                      decoding="async"
                      src="/assets/images/PlayStoreBadgeFooter.png"
                      style={{ color: 'transparent' }}
                    />
                  </Link>
                </Box>
                <Box>
                  <Link
                    href="https://apps.apple.com/in/app/hyperpure/id1203646221"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      alt="App Store"
                      loading="lazy"
                      width="120"
                      height="40"
                      decoding="async"
                      src="/assets/images/AppStoreBadgeFooter.png"
                      style={{ color: 'transparent' }}
                    />
                  </Link>
                </Box>
              </Box> */}
            </Box>
          </FooterColumn>
        </FooterSection>

        <Box className="line-light" mb={3}></Box>

        <FooterSection>
          <Box display="flex" alignItems="center">
            <img
              alt="fssai icon"
              loading="lazy"
              width="36"
              height="18"
              decoding="async"
              src='https://www.hyperpure.com/assets/images/fssaiFooter.png'
              style={{ color: 'transparent', marginRight: '8px' }}
            />
            <Typography variant="body2" className="text-indigo-500">
              License No. 11521016000033
            </Typography>
          </Box>
          <Typography variant="body2" className="text-indigo-500">
            Copyright © Horeca1 All Rights Reserved
          </Typography>
        </FooterSection>
      </Container>
    </FooterWrapper>
  );
};

export default Footer;
