import { Avatar, Box, Grid, Typography, useTheme } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { horecaApi } from "../../../redux/api/api";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useQueryParams from "../../../routes/hooks/useQueryParams";
import ProductCard from "../ProductCard";
import NoData from "../../order-list/NoData";
import GetImageComponent from "../../../components/common/GetImageComponent";

const CategoryProducts = ({ selectedCategory, setIsDrawerOpen, cartData, wishList, addToWishlist }) => {
    const theme = useTheme();
    const params = useQueryParams();
    const orgId = useSelector((state) => state.app.preference.selectedOrgId);
    const [subCategoris, setSubCategories] = useState([]);
    const [activeSubCategory, setActiveSubCategory] = useState();
    const [getVendorItems, { data: vendorItemData, isLoading: itemLoading }] = horecaApi.endpoints.getVendorItems.useLazyQuery();
    const searchText = '';
    const newCategory = {
        id: null,
        category_name: "All",
        category_image: "https://assets.hyperpure.com/data/images/assets/sub_category_image_all_v2.png",
    };
    useEffect(() => {
        setActiveSubCategory(null);
        setSubCategories([newCategory, ...selectedCategory?.subCategories]);
        (async () => {
            await getVendorItems({
                customerOrgId: orgId,
                vendorOrgId: params?.vendorId,
                categoryId: selectedCategory.id,
                subCategoryId: null,
                searchText: ''
            });
        })();
    }, [selectedCategory]);
    useEffect(() => {
        (async () => {
            await getVendorItems({
                customerOrgId: orgId,
                vendorOrgId: params?.vendorId,
                categoryId: selectedCategory.id,
                subCategoryId: activeSubCategory ? activeSubCategory : null,
                searchText: ''
            });
        })();
    }, [activeSubCategory]);


    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Box>
                <Typography variant="h5">
                    {selectedCategory.parent_category}
                </Typography>
                <Box onClick={() => setIsDrawerOpen(true)} sx={{
                    display: 'flex', gap: 0.5, alignItems: 'center',
                }}>
                    <Typography variant='body2' sx={{ fontWeight: 'bold', color: '#E95F62' }}>Change category</Typography>
                    <KeyboardArrowDownIcon sx={{ color: '#E23944' }} />
                </Box>
            </Box>
            <Grid container spacing={0.8}>
                <Grid item xs={3} sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                    {subCategoris?.map((sub) => (
                        <Box
                            key={sub.id}
                            className={activeSubCategory === sub.id || (!activeSubCategory && sub?.category_name == "All") ? "active" : ""}
                            onClick={() => setActiveSubCategory(sub.id)}
                            sx={{
                                display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'center',
                                "&:hover": {
                                    color: theme.background,
                                },
                                "&.active": {
                                    color: theme.background,
                                    borderRight: "3px solid #e23744",
                                    //height: "60px",
                                },
                            }}
                        >
                            <Box sx={{ backgroundColor: activeSubCategory === sub.id || (!activeSubCategory && sub?.category_name == "All") ? '#f4f4f4' : 'transparent' }}>
                                {sub?.category_name == "All" ? (
                                    <Avatar
                                        src={sub?.category_image}
                                        sx={{ bgcolor: (theme) => theme.palette.primary.lighter, color: (theme) => theme.palette.primary.main }}
                                    ></Avatar>
                                ) : (
                                    <Avatar sx={{ bgcolor: (theme) => theme.palette.primary.lighter, color: (theme) => theme.palette.primary.main }}>
                                        <GetImageComponent noSticker={sub?.category_image} style={{ width: "40px", height: "40px" }} />
                                    </Avatar>
                                )}
                            </Box>
                            <Typography
                                variant="body1"
                                fontSize={10}
                                sx={{ fontWeight: activeSubCategory === sub.id || (!activeSubCategory && sub?.category_name == "All") ? 600 : 400, textAlign: "center" }}
                            >
                                {sub.category_name}
                            </Typography>
                        </Box>
                    ))}
                </Grid>
                <Grid item xs={9}>
                    <Box sx={{ backgroundColor: '#FBFBFB' }}>
                        <Grid container>
                            {vendorItemData?.length > 0 ? (
                                vendorItemData?.map((item, index) => (
                                    <Grid key={`${index}prod`} item xs={12} sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <Box sx={{ p: 1 }}>
                                            <ProductCard wishList={wishList} addToWishlist={addToWishlist} data={item} key={item.id} customerId={orgId} vendorId={params?.vendorId} allCartItemsData={cartData} />
                                        </Box>
                                    </Grid>
                                ))
                            ) : (
                                <Grid item xs={12}>
                                    <NoData query={searchText ? searchText : "Inventory Items"} />
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default CategoryProducts;