import PropTypes from 'prop-types';

import { Box, useTheme, alpha, Typography } from '@mui/material';

import { useResponsive } from '../hooks/useResponsive';

import { NAV, HEADER } from './common/configLayout';
import { bgGradient } from '../theme/css';
import ShowMessage from '../components/common/ShowMessage';
import useResponsiveStyles from '../hooks/useResponsiveStyles';
import Footer from '../pages/landing/Footer';

// ----------------------------------------------------------------------

const SPACING = 8;

const Main = ({ children, sx, ...other }) => {
  const lgUp = useResponsive('up', 'lg');
  const theme = useTheme();
  const { isMobile } = useResponsiveStyles();

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        minHeight: 1,
        display: 'flex',
        // backgroundColor:'red',
        flexDirection: 'column',
        paddingTop: `${HEADER.H_MOBILE + SPACING}px`,
        paddingBottom: '1rem',
        ...(lgUp && {
          px: 2,
          paddingTop: `${HEADER.H_DESKTOP + SPACING}px`,
          paddingBottom: '1rem',
          width: `calc(100% - ${NAV.WIDTH}px)`,
        }),
        ...sx,
        ...bgGradient({
          color: alpha(theme.palette.background.default, 0.96),
          // imgUrl: "/assets/images/covers/cover_17.jpg",
        }),

      }}
      {...other}
    >
      {children}
      {isMobile ? <Typography
        variant="caption"
        color={"grey"}
        sx={{
          width: "100%",
          marginTop: "auto",
          display: "flex",
          justifyContent: "center",
          pr: "1.5rem",
          mb: "1rem",
          paddingTop: '2rem'
        }}
      >
        Copyright &copy; Horeca1 2023. All rights reserved.
      </Typography> :
      <Box sx={{ pt: 3 }}>
        <Footer />
      </Box>}
      <ShowMessage />
    </Box>
  );
}

Main.propTypes = {
  children: PropTypes.node,
  sx: PropTypes.object,
};


export default Main;