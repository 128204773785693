// import banner1 from "../../assets/images/banner1.png";
// import banner2 from "../../assets/images/banner2.png";
// import banner3 from "../../assets/images/banner3.png";

import { banner1 } from "../../assets/banners";
import { banner2 } from "../../assets/banners";
import { banner3 } from "../../assets/banners";

import { bannerD1 } from "../../assets/banners";
import { bannerD2 } from "../../assets/banners";
import { bannerD3 } from "../../assets/banners";

export const mImages = [banner1, banner2, banner3];

export const images = [bannerD1, bannerD2, bannerD3];

export const imageByIndex = (index) => images[index % images.length];

export const mImageByIndex = (index) => mImages[index % images.length];

// export default imageByIndex
