import * as Yup from 'yup';
import { validatePhoneNumber } from '../../pages/auth/sections/LoginView';

export const organizationProfileSchemaSystemAdmin = Yup.object().shape({
  orgName: Yup.string().required("Organization name is required"),
  phoneNumber: Yup.string().required('Phone number is required'),
  orgEmail: Yup.string().email("Invalid email address"),
  businessDetail: Yup.object().shape({
    panNumber: Yup.string().trim().matches(
      /^[A-Z]{5}\d{4}[A-Z]{1}$/,
      "PAN Card Number must be valid"
    ),
    gstUin: Yup.string().trim().matches(
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Zz][0-9A-Z]{1}$/,
      "GST Certificate Number must be valid"
    ),
    fssi: Yup.string().trim().matches(/^[0-9]{14}$/i, "Invalid FSSAI number"),
  }),
  orgAdmin: Yup.object().shape({
    firstName: Yup.string().required('First name required').matches(/^[A-Za-z\s]+$/, "Name must contain only characters"),
    lastName: Yup.string().required('Last name required').matches(/^[A-Za-z\s]+$/, "Name must contain only characters"),
    mobileNumber: Yup?.string().required('Mobile number required')
  }),
  addresses: Yup.array().of(
    Yup.object().shape({
      line1: Yup.string()
        .required("Address line 1 required"),
      landmark: Yup.string().required('Landmark is required'),
      city: Yup.string()
        .required("City is required")
        .matches(/^[a-zA-Z\s]+$/, "City name must contain only characters"),
      state: Yup.string().required("State is required").matches(/^[a-zA-Z\s]+$/, "State name must contain only characters"),
      pincode: Yup.string().required("Pincode is required").matches(
        /^\d{6}$/,
        "Pincode must be a 6-digit number"
      ),
    })
  ),
});

export const organizationProfileSchema = Yup.object().shape({
  orgName: Yup.string().required("Organization name is required"),
  phoneNumber: Yup.string().required('Required'),
  orgEmail: Yup.string().email("Invalid email address"),
  businessDetail: Yup.object().shape({
    panNumber: Yup.string().trim().matches(
      /^[A-Z]{5}\d{4}[A-Z]{1}$/,
      "PAN Card Number must be valid"
    ),
    gstUin: Yup.string().trim().matches(
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Zz][0-9A-Z]{1}$/,
      "GST Certificate Number must be valid"
    ),
    fssi: Yup.string().trim().matches(/^[0-9]{14}$/i, "Invalid FSSAI number"),
  }),
  contactPersons: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().matches(/^[A-Za-z\s]+$/, "contact person name must contain only characters"),
      mobileNumber: Yup?.string().required('Required')
    })
  ),
  addresses: Yup.array().of(
    Yup.object().shape({
      line1: Yup.string()
        .required("address line 1 required"),
      landmark: Yup.string().required('landmark is required'),
      city: Yup.string()
        .required("city is required")
        .matches(/^[a-zA-Z\s]+$/, "City name must contain only characters"),
      state: Yup.string().required("state is required").matches(/^[a-zA-Z\s]+$/, "State name must contain only characters"),
      pincode: Yup.string().required("pincode is required").matches(
        /^\d{6}$/,
        "Pincode must be a 6-digit number"
      ),
    })
  ),
});



export const onBaordPrivateVendorSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Second name is required"),
  orgName: Yup.string().required("Shop name is required"),
  phoneNumber: Yup.string().test('isValidPhoneNumber', 'Invalid phone number', function (value) {
    const { country } = this.parent;
    return !validatePhoneNumber(value, country);
  }),
  contactPersonNumber: Yup.string()
    .required('Required'),
  email: Yup.string().email("Invalid email address"),
  addresses:
    Yup.object().shape({
      line1: Yup.string()
        .required("address is required"),
      landmark: Yup.string(),
      city: Yup.string()
        .required("city is required")
        .matches(/^[a-zA-Z\s]+$/, "City name must contain only characters"),
      state: Yup.string().required("state is required").matches(/^[a-zA-Z\s]+$/, "State name must contain only characters"),
      zipcode: Yup.string().required("pincode is required").matches(
        /^\d{6}$/,
        "Zipcode must be a 6-digit number"
      ),
    })
});


export const shippedValidation = Yup.object().shape({
  // email: Yup.string().email('Invalid email address').required('Email is required'),
  // mobileNumber: Yup.string().matches(/^[0-9]+$/, 'Must be only digits').length(10, 'Mobile number must be exactly 10 digits').required('Mobile number is required'),
  type: Yup.string().required('Type is required'),
  trackingNo: Yup.string(),
  trackingUrl: Yup.string().url('Invalid URL'),
  shipBy: Yup.string().required('required'),
});

export const vendorSchema = Yup.object().shape({
  orgName: Yup.string().required("Organization name is required"),
  phoneNumber: Yup.string()
    .required('Required'),
  orgEmail: Yup.string().email("Invalid email address"),
  businessDetail: Yup.object().shape({
    panNumber: Yup.string().trim().matches(
      /^[A-Z]{5}\d{4}[A-Z]{1}$/,
      "PAN Card Number must be valid"
    ),
    gstUin: Yup.string().trim().matches(
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Zz][0-9A-Z]{1}$/,
      "GST Certificate Number must be valid"
    ),
    fssai: Yup.string().trim().matches(/^[0-9]{14}$/i, "Invalid FSSAI number"),
  }),
  contactPersons: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().matches(/^[A-Za-z\s]+$/, "contact person name must contain only characters"),
      mobileNumber: Yup?.string()
        .required('Required')
    })
  ),
  // deliversAt: Yup.array().of(
  //   Yup.object().shape({
  //     zipcode: Yup.string().matches(
  //       /^\d{6}$/,
  //       "Zipcode must be a 6-digit number"
  //     ),
  //   })
  // ),
  // addresses: Yup.array().of(
  //   Yup.object().shape({
  //     line1: Yup.string()
  //     .required("address line 1 required"),
  //     landmark: Yup.string().matches(/^[a-zA-Z0-9\s]+$/, "landmark must contain only characters and numbers").required('landmark is required'),
  //     city: Yup.string()
  //     .required("city is required")
  //     .matches(/^[a-zA-Z\s]+$/, "City name must contain only characters"),
  //     state: Yup.string().required("state is required").matches(/^[a-zA-Z\s]+$/, "State name must contain only characters"),
  //     pincode: Yup.string().required("pincode is required").matches(
  //       /^\d{6}$/,
  //       "Pincode must be a 6-digit number"
  //     ),
  //   })
  // ),  
});


export const customerShema = Yup.object().shape({
  orgName: Yup.string().required("Organization name is required"),
  phoneNumber: Yup.string()
    .required('Required'),
  orgEmail: Yup.string().email("Invalid email address"),
  businessDetail: Yup.object().shape({
    panNumber: Yup.string().trim().matches(
      /^[A-Z]{5}\d{4}[A-Z]{1}$/,
      "PAN Card Number must be valid"
    ),
    gstUin: Yup.string().trim().matches(
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Zz][0-9A-Z]{1}$/,
      "GST Certificate Number must be valid"
    ),
    fssi: Yup.string().trim().matches(/^[0-9]{14}$/i, "Invalid FSSAI number"),
  }),
  contactPersons: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().matches(/^[A-Za-z\s]+$/, "contact person name must contain only characters"),
      mobileNumber: Yup?.string()
        .required('Required')
    })
  ),
  // addresses: Yup.array().of(
  //   Yup.object().shape({
  //     line1: Yup.string()
  //     .required("address line 1 required"),
  //     landmark: Yup.string().matches(/^[a-zA-Z0-9\s]+$/, "landmark must contain only characters and numbers").required('landmark is required'),
  //     city: Yup.string()
  //     .required("city is required")
  //     .matches(/^[a-zA-Z\s]+$/, "City name must contain only characters"),
  //     state: Yup.string().required("state is required").matches(/^[a-zA-Z\s]+$/, "State name must contain only characters"),
  //     pincode: Yup.string().required("pincode is required").matches(
  //       /^\d{6}$/,
  //       "Pincode must be a 6-digit number"
  //     ),
  //   })
  // ),  
});