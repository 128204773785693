import { Box, Button, Grid, IconButton, Rating, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import GetImageComponent from "../../../../../../components/common/GetImageComponent";
import { useDispatch, useSelector } from "react-redux";
import { useGetSuitableVendorListMutation } from "../../../../../../redux/api/api";
import { formatAddress } from "../../../../../../utils/stringFormating";
import { setSelectedVendorId } from "../../../../../../redux/slices/orderListSlice";
import { useNavigate } from "react-router-dom";
import useResponsiveStyles from "../../../../../../hooks/useResponsiveStyles";
import CloseIcon from '@mui/icons-material/Close';

const VendorSection = ({ selectedCategory, clearCategorySelection }) => {
    const dispatch = useDispatch();
    const responsive = useResponsiveStyles();
    const navigate = useNavigate();
    const [showAll, setShowAll] = useState(false);
    const orgId = useSelector((state) => state.app.preference.selectedOrgId);
    const pincode = useSelector((state) => state.app.preference.selectedPinCode?.pincode);
    const [getSuitableVendorList, { data: suitableVendors }] = useGetSuitableVendorListMutation();
    const boxRef = useRef(null);

    useEffect(() => {
        if (boxRef.current) {
            boxRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        getSuitableVendorList({
            orgId,
            payload: {
                vendorOnly: "false",
                categories: selectedCategory ? [selectedCategory.id] : [],
                subCategories: [],
                singleVendor: false,
                pincode,
            },
            searchText: '',
        })
    }, [selectedCategory, pincode])
    const handleNavigate = (vendorId) => {
        dispatch(setSelectedVendorId(vendorId))
        navigate(`/org/${orgId}/explore-products?vendorId=${vendorId}`)
    }
    if (responsive?.isMobile) {
        return (
            <Box ref={boxRef} sx={{ padding: 1, display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="h4">Popular Vendors Near Me</Typography>
                    {selectedCategory &&
                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                            <Typography sx={{ color: 'grey' }} variant="h5">For </Typography>
                            <Box sx={{ border: '1px solid #DCE1E5', borderRadius: 1, padding: 0.5, px: 1, display: 'flex', gap: 0.5, alignItems: 'center' }}>
                                <Typography sx={{ color: 'grey' }} variant="h5">{selectedCategory.parent_category}</Typography>
                                <IconButton size="small" onClick={() => clearCategorySelection()}><CloseIcon fontSize="small" /></IconButton>
                            </Box>
                        </Box>
                    }
                </Box>
                <Grid container spacing={2}>
                    {suitableVendors && suitableVendors.length > 0 &&
                        suitableVendors.map((vendor, index) => {
                            const addr = vendor.vendorDetails?.addresses[0];
                            const vendorCategories = vendor.categoryDetails.map((_obj) => _obj.parent_category).join(', ');
                            return (((!showAll && index < 4) || showAll) && <Grid key={`${index}vendor`} item xs={6} sm={3} sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Box onClick={() => handleNavigate(vendor.vendorDetails?.id)} sx={{
                                    display: 'flex', flexGrow: 1, paddingY: 2, flexDirection: 'column', gap: 2,
                                    transition: 'transform 0.3s ease-in-out',
                                    boxShadow: 'rgba(0, 0, 0, 0.08) 0px 1px 5px, rgba(0, 0, 0, 0.07) 0px -1px 5px 0px',
                                    '&:hover': {
                                        cursor: 'pointer',
                                        transform: 'scale(0.95)',
                                    },
                                    borderRadius: 2
                                }}>
                                    {/* <Box sx={{ borderRadius: 2, boxShadow: 'rgba(0, 0, 0, 0.08) 0px 1px 5px, rgba(0, 0, 0, 0.07) 0px -1px 5px 0px', background: 'linear-gradient(to bottom, #e0e0e0, #757575)' }}>
                                        <GetImageComponent s3Key={vendor.vendorDetails?.org_logo_key} style={{ borderRadius: 16, height: 100, width: '100%' }} />
                                    </Box> */}
                                    <Box sx={{ paddingX: 2 }}>
                                        <Typography variant="h5">
                                            {vendor.vendorDetails?.org_name}
                                        </Typography>
                                        <Box sx={{ display: 'flex', gap: 1, my: 0.5, alignItems:'center' }}>
                                            <Typography variant="subtitle2">{vendor.avgRating}</Typography>
                                            <Rating size="small" value={vendor.avgRating || null} readOnly />
                                        </Box>
                                        <Typography noWrap sx={{ color: 'grey' }}>{vendorCategories}</Typography>
                                    </Box>
                                </Box>
                            </Grid>)
                        })
                    }
                </Grid>
                {!showAll && suitableVendors && suitableVendors.length > 4 && <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button onClick={() => setShowAll(true)} size="large">Show All</Button>
                </Box>}
                {
                    (!suitableVendors || suitableVendors.length == 0) &&
                    <Box sx={{ paddingY: 3 }}>
                        <Typography variant="h5">No vendors found</Typography>
                        <Typography variant="h6" sx={{ color: 'grey' }}>Please try for something else</Typography>
                    </Box>
                }
            </Box>
        )
    }
    return (
        <Box ref={boxRef} sx={{ padding: 3, display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                <Typography variant="h4">Popular Vendors Near Me</Typography>
                {selectedCategory &&
                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                        <Typography sx={{ color: 'grey' }} variant="h4">For </Typography>
                        <Box sx={{ border: '1px solid #DCE1E5', borderRadius: 2, padding: 1, px: 1.5, display: 'flex', gap: 1 }}>
                            <Typography sx={{ color: 'grey' }} variant="h4">{selectedCategory.parent_category}</Typography>
                            <IconButton onClick={() => clearCategorySelection()}><CloseIcon /></IconButton>
                        </Box>
                    </Box>
                }
            </Box>
            <Grid container spacing={5}>
                {suitableVendors && suitableVendors.length > 0 &&
                    suitableVendors.map((vendor, index) => {
                        const addr = vendor.vendorDetails?.addresses[0];
                        const vendorCategories = vendor.categoryDetails.map((_obj) => _obj.parent_category).join(', ');
                        return (((!showAll && index < 4) || showAll) && <Grid key={`${index}vendor`} item sm={3} sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Box onClick={() => handleNavigate(vendor.vendorDetails?.id)} sx={{
                                display: 'flex', flexGrow: 1, paddingY: 2, flexDirection: 'column', gap: 2,
                                transition: 'transform 0.3s ease-in-out',
                                boxShadow: 'rgba(0, 0, 0, 0.08) 0px 1px 5px, rgba(0, 0, 0, 0.07) 0px -1px 5px 0px',
                                '&:hover': {
                                    cursor: 'pointer',
                                    transform: 'scale(0.95)',
                                },
                                borderRadius: 2
                            }}>
                                {/* <Box sx={{ borderRadius: 2, boxShadow: 'rgba(0, 0, 0, 0.08) 0px 1px 5px, rgba(0, 0, 0, 0.07) 0px -1px 5px 0px', background: 'linear-gradient(to bottom, #e0e0e0, #757575)' }}>
                                    <GetImageComponent s3Key={vendor.vendorDetails?.org_logo_key} style={{ borderRadius: 16, height: 180, width: '100%' }} />
                                </Box> */}
                                <Box sx={{ paddingX: 2 }}>
                                    <Typography variant="h5">
                                        {vendor.vendorDetails?.org_name}
                                    </Typography>
                                    <Box sx={{ display: 'flex', gap: 1, my: 0.5, alignItems: 'center' }}>
                                        <Typography variant="subtitle2">{vendor.avgRating}</Typography>
                                        <Rating size="small" value={vendor.avgRating || null} readOnly />
                                    </Box>
                                    <Typography noWrap sx={{ color: 'grey' }}>{vendorCategories}</Typography>
                                </Box>
                            </Box>
                        </Grid>)
                    })
                }
            </Grid>
            {!showAll && suitableVendors && suitableVendors.length > 4 && <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button onClick={() => setShowAll(true)} size="large">Show All</Button>
            </Box>}
            {
                (!suitableVendors || suitableVendors.length == 0) &&
                <Box sx={{ paddingY: 3 }}>
                    <Typography variant="h5">No vendors found</Typography>
                    <Typography variant="h6" sx={{ color: 'grey' }}>Please try for something else</Typography>
                </Box>
            }
        </Box>
    )
}

export default VendorSection;