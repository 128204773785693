import React from "react";
import EmblaCarousel from "./EmblaCarousel";
// import "../css/base.css";
import "../../css/sandbox.css";
import "../../css/embla.css";
import { useResponsive } from "../../../hooks/useResponsive";

const CaraousalTwo = ({ isHome = false }) => {
  const mdDown = useResponsive("down", "md");
  const OPTIONS = { slidesToScroll: 1, loop: true };
  const SLIDE_COUNT = mdDown ? 5 : 2;
  const SLIDES = Array.from(Array(SLIDE_COUNT).keys());

  return (
    <>
      <main style={{ width: mdDown ? '100%' : '90%' }}>
        <section className="sandbox__carousel">
          <EmblaCarousel slides={SLIDES} options={OPTIONS} isHome={isHome}/>
        </section>
      </main>
    </>
  );
};

export default CaraousalTwo;
