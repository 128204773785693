import React from "react";
import { horecaApi, useHandelApprovedMutation, useSaveOrganizationDataMutation } from "../../../../../redux/api/api";
import { useParams } from "react-router-dom";
import { customerFunction, vendorFunction } from "../OrganizationProfile";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { openMessage } from "../../../../../redux/slices/showMessageSlice";
import { Box, Grid, Paper, Stack, Typography } from "@mui/material";
import ProfilePic from "../../../../../components/common/ProfilePic";
import CustomTextField from "../../../../../components/common/CustomTextField";
import AddresDetail from "../AddressDetail";
import { useResponsive } from "../../../../../hooks/useResponsive";
import Iconify from "../../../../../components/common/Iconify";
import CustomButton from "../../../../../components/common/CustomButton";
import { useEffect } from "react";
import CustomMuiTelInput from "../../../../../components/common/CustomMuiTelInput";
import { getOrgType } from "../../../../../utils/org";

const zip = { zipcode: "" };

const add = [
  {
    billing: false,
    line1: "",
    line2: "",
    landmark: "",
    city: "",
    state: "",
    pincode: "",
  },
  {
    billing: true,
    line1: "",
    line2: "",
    landmark: "",
    city: "",
    state: "",
    pincode: "",
  },
];

const newPerson = { name: "", mobileNumber: "" };

const CreateNewOutlet = ({ outletId, handelClose, disabled, setEnabled }) => {
  const { orgId } = useParams();
  const user = useSelector((state) => state.auth.user);

  const dispatch = useDispatch();
  const smDown = useResponsive("down", "sm");

  const [getOrganizationById, { data: orgData }] = horecaApi.endpoints.getOrganizationById.useLazyQuery();
  const [saveOrganizationData, { isLoading: savingOrganizationData }] = useSaveOrganizationDataMutation();
  const [handelApproved] = useHandelApprovedMutation();

  const orgType = getOrgType(user, orgId);

  useEffect(() => {
    (async () => {
      outletId && (await getOrganizationById(outletId));
    })();
  }, []);

  const initialValues = {
    id: outletId ?? "",
    primaryOrgId: orgId,
    type: orgData?.type ?? orgType,
    orgName: orgData?.orgName ?? "",
    phoneNumber: orgData?.phoneNumber && outletId ? orgData?.phoneNumber : "",
    orgEmail: orgData?.orgEmail ?? "",
    websiteUrl: orgData?.websiteUrl ?? "",
    businessDetail: {
      id: outletId && orgData?.businessDetail?.id ? orgData?.businessDetail?.id : "",
      gstUin: orgData?.businessDetail?.gstUin ?? "",
      panNumber: orgData?.businessDetail?.panNumber ?? "",
      fssi: orgData?.businessDetail?.fssi ?? "",
    },
    addresses: orgType === "customer" ? customerFunction(orgData) : vendorFunction(orgData),
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const result = await saveOrganizationData({ body: values });
      if (result.data) {
        dispatch(
          openMessage({
            message: true ? "Outlet Data Updated successfully!" : "Outlet Added successfully!",
            messageSevirty: "success",
          })
        );
        await handelApproved({
          orgId: result?.data?.id,
          body: { status: "approved" },
        });
        handelClose();
      } else if (result.error) {
        dispatch(
          openMessage({
            message: result.error.data.message,
            messageSevirty: "error",
          })
        );
      } else {
        dispatch(
          openMessage({
            message: false ? "Not Able to Update Outlet Details" : "Not Able to Add Outlet at this moment",
            messageSevirty: "error",
          })
        );
      }
    },
  });

  return (
    <Box width={"100%"}>
      <Grid container spacing={5}>
        {outletId && (
          <Grid item xs={12} md={4}>
            <Paper elevation={3} sx={{ padding: 2 }}>
              <Stack direction={"row"} justifyContent={"flex-end"}></Stack>
              <Stack direction={"column"} justifyContent={"center"} alignItems={"center"} gap={"0rem"}>
                <ProfilePic s3key={orgData?.orgLogoKey} orgId={outletId} disabled={disabled} name={orgData?.orgName} />
                <Typography variant="title" sx={{ color: "text.disabled" }}>
                  {orgData?.orgName}
                </Typography>
                <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>{orgData?.type}</Typography>
              </Stack>
            </Paper>
          </Grid>
        )}
        <Grid item xs={12} md={outletId ? 8 : 12}>
          <Paper
            elevation={smDown ? 0 : 3}
            sx={{
              // padding: smDown ? 0 : 2,
              p: 2,
            }}
          >
            <Typography variant="title" mb={"1rem"}>
              Basic Details
            </Typography>
            <Grid container spacing={3} pt={"0.5rem"}>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  name={"orgName"}
                  label={orgType === "customer" ? "Restaurant  name" : "Shop Name"}
                  formik={formik}
                  required={true}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  name={"orgEmail"}
                  label={orgType === "customer" ? "Restaurant email" : "Shop Email"}
                  formik={formik}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomMuiTelInput
                  name={"phoneNumber"}
                  label={orgType === "customer" ? "Restaurant phone number" : "Shop Phone Number"}
                  formik={formik}
                  disabled={disabled}
                  fullWidth
                />
              </Grid>
            </Grid>
            <AddresDetail formik={formik} isShipping={orgType === "customer" ? true : false} disabled={disabled} />
            <Stack mt={"1rem"}>
              <Typography variant="title" pb={2}>
                Business Details
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <CustomTextField
                    name={"businessDetail.panNumber"}
                    label={"Pan number"}
                    formik={formik}
                    required={!formik.values.businessDetail.gstUin}
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomTextField name={"businessDetail.fssi"} label={"FSSAI"} formik={formik} disabled={disabled} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomTextField
                    name={"businessDetail.gstUin"}
                    label={"GST/UIN"}
                    formik={formik}
                    required={!formik.values.businessDetail.panNumber}
                    disabled={disabled}
                  />
                </Grid>
              </Grid>
              <Box sx={{ display: "flex", justifyContent: "flex-end", mt: "2rem" }}>
                <CustomButton
                  onClick={!disabled ? formik.handleSubmit : setEnabled}
                  endIcon={!disabled ? <Iconify icon="ph:arrow-right-bold" /> : <Iconify width={14} icon="fluent:edit-28-filled" />}
                  variant={disabled ? "contained" : "outlined"}
                  label={disabled ? "Edit" : Boolean(savingOrganizationData) ? "Saving..." : "Save"}
                  loading={savingOrganizationData}
                />
              </Box>
            </Stack>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CreateNewOutlet;
