import { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { set, sub } from "date-fns";
import { Box, Drawer, Slide, Stack } from "@mui/material";
import List from "@mui/material/List";
import Badge from "@mui/material/Badge";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import Iconify from "../../components/common/Iconify";
import Scrollbar from "../../components/common/scroll-bar/ScrollBar";
import { useWebSocket } from "../../hooks/WebsocketProvider";
import { useSelector } from "react-redux";
import { formatDate } from "../../utils/formatNumbers";
import { useRouter } from "../../routes/hooks/useRouter";

// ----------------------------------------------------------------------

const NOTIFICATIONS = [
  {
    // id: faker.string.uuid(),
    title: "Your order is placed",
    description: "waiting for shipping",
    avatar: null,
    type: "order_placed",
    createdAt: set(new Date(), { hours: 10, minutes: 30 }),
    isUnRead: true,
  },
  {
    // id: faker.string.uuid(),
    // title: faker.person.fullName(),
    description: "answered to your comment on the Minimal",
    avatar: "/assets/images/avatars/avatar_2.jpg",
    type: "friend_interactive",
    createdAt: sub(new Date(), { hours: 3, minutes: 30 }),
    isUnRead: true,
  },
  {
    // id: faker.string.uuid(),
    title: "You have new message",
    description: "5 unread messages",
    avatar: null,
    type: "chat_message",
    createdAt: sub(new Date(), { days: 1, hours: 3, minutes: 30 }),
    isUnRead: false,
  },
  {
    // id: faker.string.uuid(),
    title: "You have new mail",
    description: "sent from Guido Padberg",
    avatar: null,
    type: "mail",
    createdAt: sub(new Date(), { days: 2, hours: 3, minutes: 30 }),
    isUnRead: false,
  },
  {
    // id: faker.string.uuid(),
    title: "Delivery processing",
    description: "Your order is being shipped",
    avatar: null,
    type: "order_shipped",
    createdAt: sub(new Date(), { days: 3, hours: 3, minutes: 30 }),
    isUnRead: false,
  },
];

const NotificationsPopover = () => {
  // const [notifications, setNotifications] = useState(NOTIFICATIONS);

  const router = useRouter();
  const user = useSelector((state) => state.auth.user);
  const orgId = useSelector((state) => state.app.preference.selectedOrgId);
  const socket = useWebSocket();

  const notifications1 = useSelector((state) => state.notifications);

  // const totalUnRead = notifications.filter((item) => item.isUnRead === true).length;

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleMarkAllAsRead = async () => {
    await socket.emit("read-all-message", { orgId, userId: user?.userId });
  };

  const handleNavigate = (path) => {
    router.push(path);
  };

  const handleMessageClick = async (message) => {
    const content = JSON.parse(message?.content);

    if (!message.isRead) await socket.emit("read-message", { messageId: message.id, status: true });

    let path;
    switch (content.type) {
      case "order.failed":
      case "order.scheduled":
      case "order.edited":
      case "order.placed":
      case "order.confirmed":
      case "order.verification":
      case "order.approval":
      case "order.delivered":
      case "order.cancled": // Corrected spelling from 'cancled'
      case "order.shipped": // Corrected spelling from 'cancled'
      case "order.fullfilled": // Corrected spelling from 'fullfilled'
        path = `/org/${orgId}/order-history/${content?.action?.orderId}`;
        handleNavigate(path);
        break;
      case "vendor.onborded":
        path = `/org/${orgId}/vendors?vendorId=${content?.action?.orgId}`;
        handleNavigate(path);
        break;
      case "customer.onborded":
        path = `/org/${orgId}/customers?customerId=${content?.action?.orgId}`;
        handleNavigate(path);
        break;
      case "vendor.onborded":
        path = `/org/${orgId}/vendros?vendorId=${content?.action?.orgId}`;
        handleNavigate(path);
        break;
      default:
        console.log("message clicked", message);
        break;
    }
    setOpen(false);
  };

  const notificationBadgeCount = useMemo(() => {
    const _data = notifications1.messages.filter((message) => message.isRead === false);
    return _data?.length;
  }, [notifications1]);

  return (
    <>
      <IconButton color={open ? "primary" : "default"} onClick={handleOpen}>
        <Badge badgeContent={notificationBadgeCount} color="primary">
          <Iconify width={24} icon="solar:bell-bing-bold-duotone" />
        </Badge>
      </IconButton>

      <Drawer
        open={!!open}
        anchorEl={open}
        anchor={"right"}
        onClose={handleClose}
        PaperProps={{
          sx: {
            width: 300,
            borderRadius: 0,
          },
        }}
        transitionDuration={{ enter: 600, exit: 600 }}
      >
        <Box sx={{ display: "flex", alignItems: "center", py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              You have {notificationBadgeCount} unread messages
            </Typography>
          </Box>

          {notificationBadgeCount > 0 && (
            <Tooltip title="Mark all as read">
              <IconButton color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Stack
          sx={{
            overflowY: "auto",
            height: "100%",
            "-webkit-overflow-scrolling": "touch",
            scrollBehavior: "smooth",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: "overline" }}>
                New
              </ListSubheader>
            }
          >
            {notifications1?.messages?.map((notification, index) => (
              <Slide
                key={notification.id}
                direction="left"
                in={open}
                mountOnEnter
                unmountOnExit
                timeout={{ enter: 400 + index * 50, exit: 400 + index * 100 }} // Adjust delay between each item
              >
                <div>
                  <NotificationItem notification={notification} handleMessageClick={handleMessageClick} />
                </div>
              </Slide>
            ))}
          </List>
        </Stack>

        <Divider sx={{ borderStyle: "dashed" }} />
      </Drawer>
    </>
  );
};

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    createdAt: PropTypes.instanceOf(Date),
    id: PropTypes.string,
    isUnRead: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    avatar: PropTypes.any,
  }),
};

function NotificationItem({ notification, handleMessageClick }) {
  const prasedData = JSON.parse(notification?.content);

  const { avatar, title } = renderContent(prasedData);

  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: "1px",
        ...(!notification.isRead && {
          bgcolor: "action.selected",
        }),
      }}
      onClick={() => handleMessageClick(notification)}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: "background.neutral" }}>{avatar}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        sx={{ fontSize: "10px" }}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: "flex",
              alignItems: "center",
              color: "text.disabled",
            }}
          >
            {formatDate(notification?.createdAt)}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification) {
  const title = (
    <Typography variant="subtitle2">
      {notification.title}
      <Typography component="span" variant="body2" sx={{ color: "text.secondary" }}>
        &nbsp; {notification.message}
      </Typography>
    </Typography>
  );

  if (notification.type === "order.placed") {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/ic_notification_package.svg" />,
      title,
    };
  }
  if (notification.type === "order.shipped") {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/ic_notification_shipping.svg" />,
      title,
    };
  }
  if (notification.type === "order.edited") {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/ic_notification_shipping.svg" />,
      title,
    };
  }
  if (notification.type === "order.scheduled") {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/ic_notification_sheduled.svg" />,
      title,
    };
  }
  if (notification.type === "mail") {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/ic_notification_mail.svg" />,
      title,
    };
  }
  if (notification.type === "chat_message") {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/ic_notification_chat.svg" />,
      title,
    };
  }
  return {
    avatar: notification.avatar ? <img alt={notification.title} src={notification.avatar} /> : null,
    title,
  };
}

export default NotificationsPopover;
