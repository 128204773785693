import { Avatar, Box, Grid, Typography, useTheme } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { horecaApi } from "../../../redux/api/api";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useQueryParams from "../../../routes/hooks/useQueryParams";
import NoData from "../../order-list/NoData";
import GetImageComponent from "../../../components/common/GetImageComponent";
import ProductDetails from "./ProductDetails";
import { FixedSizeGrid } from 'react-window';
import AutoSizer from "react-virtualized-auto-sizer";


const ProductList = ({ selectedCategory, cartData, addToWishlist, wishList }) => {
    const theme = useTheme();
    const params = useQueryParams();
    const orgId = useSelector((state) => state.app.preference.selectedOrgId);
    const [subCategoris, setSubCategories] = useState([]);
    const [activeSubCategory, setActiveSubCategory] = useState();
    const [getVendorItems, { data: vendorItemData, isLoading: itemLoading }] = horecaApi.endpoints.getVendorItems.useLazyQuery();
    const searchText = '';
    const newCategory = {
        id: -1,
        category_name: "All",
        category_image: "https://assets.hyperpure.com/data/images/assets/sub_category_image_all_v2.png",
    };
    useEffect(() => {
        setActiveSubCategory(null);
        setSubCategories(selectedCategory ? [newCategory, ...selectedCategory?.subCategories] : [newCategory]);
        (async () => {
            await getVendorItems({
                customerOrgId: orgId,
                vendorOrgId: params?.vendorId,
                categoryId: selectedCategory ? selectedCategory.id : null,
                subCategoryId: null,
                searchText: ''
            }, true);
        })();
    }, [selectedCategory]);
    useEffect(() => {
        (async () => {
            if (activeSubCategory) {
                await getVendorItems({
                    customerOrgId: orgId,
                    vendorOrgId: params?.vendorId,
                    categoryId: selectedCategory.id,
                    subCategoryId: activeSubCategory == -1 ? null : activeSubCategory,
                    searchText: ''
                });
            }
        })();
    }, [activeSubCategory]);
    const Cell = ({ columnIndex, rowIndex, style }) => {
        const item = vendorItemData[rowIndex * 3 + columnIndex]; // Assuming 5 columns rowIndex * columnCount + columnIndex + 1

        return (
            item && <Box
                style={style}
                display="flex"
                key={item.id}
                alignItems="center"
                justifyContent="center"
                sx={{ padding: 1, height: '100%' }}
                //border={1}
                flexDirection={'column'}
                borderColor="grey.300"
            >
                {item && <ProductDetails wishList={wishList} addToWishlist={addToWishlist} data={item}  customerId={orgId} vendorId={params?.vendorId} allCartItemsData={cartData} />}
            </Box>
        );
    };


    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
            {selectedCategory && <Box sx={{ padding: 1 }}>
                <Typography variant="h5">
                    {selectedCategory.parent_category}
                </Typography>
            </Box>}
            <Grid container spacing={2}>
                <Grid item xs={2.5} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    {subCategoris?.map((sub) => (
                        sub.category_name && <Box
                            key={sub.id}
                            className={activeSubCategory === sub.id || (!activeSubCategory && sub?.category_name == "All") ? "active" : ""}
                            onClick={() => setActiveSubCategory(sub.id)}
                            sx={{
                                display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center', padding: 1,
                                "&:hover": {
                                    backgroundColor: '#F5F6FD',
                                    cursor: 'pointer'
                                },
                                "&.active": {
                                    color: theme.background,
                                    borderRight: "3px solid #e23744",
                                    //height: "60px",
                                },
                            }}
                        >
                            <Box sx={{ backgroundColor: activeSubCategory === sub.id || (!activeSubCategory && sub?.category_name == "All") ? '#f4f4f4' : 'transparent' }}>
                                {sub?.category_name == "All" ? (
                                    <Avatar
                                        src={sub?.category_image}
                                        sx={{ bgcolor: (theme) => theme.palette.primary.lighter, color: (theme) => theme.palette.primary.main }}
                                    ></Avatar>
                                ) : (
                                    <Avatar sx={{ bgcolor: (theme) => theme.palette.primary.lighter, color: (theme) => theme.palette.primary.main }}>
                                        <GetImageComponent noSticker={sub?.category_image} style={{ width: "40px", height: "40px" }} />
                                    </Avatar>
                                )}
                            </Box>
                            <Typography
                                variant="subtitle2"
                                noWrap
                                sx={{ fontWeight: activeSubCategory === sub.id || (!activeSubCategory && sub?.category_name == "All") ? 600 : 400, textAlign: "center" }}
                            >
                                {sub.category_name}
                            </Typography>
                        </Box>
                    ))}
                </Grid>
                <Grid item xs={9.5}>
                    <Box sx={{ backgroundColor: '#FBFBFB', height: 600, width:'100%' }}>
                        {/* <Grid container spacing={2} >
                            {vendorItemData?.length > 0 ? (
                                vendorItemData?.map((item, index) => (
                                    <Grid key={`${index}prod`} item sm={4} md={3} sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <ProductDetails wishList={wishList} addToWishlist={addToWishlist} data={item} key={item.id} customerId={orgId} vendorId={params?.vendorId} allCartItemsData={cartData} />
                                    </Grid>
                                ))
                            ) : (
                                <Grid item xs={12}>
                                    <NoData query={searchText ? searchText : ""} />
                                </Grid>
                            )}
                        </Grid> */}
                        {vendorItemData?.length > 0 &&
                            <AutoSizer>
                                {({ height, width }) => (
                                    <FixedSizeGrid
                                        columnCount={3}
                                        columnWidth={width/3}
                                        height={height}
                                        rowCount={Math.ceil(vendorItemData.length / 3)}
                                        rowHeight={330} 
                                        width={width}
                                    >
                                        {Cell}
                                    </FixedSizeGrid>
                                )}
                            </AutoSizer>
                        }
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default ProductList;